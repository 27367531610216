import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  editorWrapperContainer: {
    overflow: 'auto',
  },
  toggle:{
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10px',
    '& p':{
      fontSize: '14px',
      fontWeight: 600
    }
  },
  flexRow: {
    display: 'flex',
    flex: 1,
    width: '100%',
    overflowY: 'auto',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
  },
  flexWrap: {
    flexWrap: 'wrap',
    display: 'flex',
    overflow: 'auto',
  },
  title:{
    paddingBottom: theme.spacing(3),
    fontWeight: 600
  },
  inputControl: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    flexBasis: '25%',
  },
  heading:{
    fontWeight: 600,
    fontSize: '14px'
  },
  imageIcon: {
    height: '16px',
    width: '16px',
    cursor: 'pointer',
    color: '#1976D2',
    pointerEvents: 'all',
    marginLeft: theme.spacing(1),
  },
  customTooltip: {
    backgroundColor: ' #5F5F5F', 
    color: '#FFFFFF',
    fontSize: '14px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.text.secondary,
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
}));

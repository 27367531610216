import { CoreModel, modelProtection } from '@wings-shared/core';
import { SettingBaseModel } from '../SettingBase.model';
import { ScheduleModel } from '../Schedule.model';
import { Airports } from '../Airports.model';

@modelProtection
export class RefDataAiportHoursModel extends CoreModel {
  id: number = 0;
  airportHourId: number = 0;
  sequence: number;
  airportReferenceId: number;
  hoursType: SettingBaseModel = new SettingBaseModel();
  airportHoursType: SettingBaseModel = new SettingBaseModel();
  hoursScheduleType: SettingBaseModel = new SettingBaseModel();
  airportHoursScheduleType: SettingBaseModel = new SettingBaseModel();
  airportHoursSubType: SettingBaseModel = new SettingBaseModel();
  status: SettingBaseModel = new SettingBaseModel();
  accessLevel: SettingBaseModel = new SettingBaseModel();
  schedule: ScheduleModel = new ScheduleModel();
  airportReference: Airports = new Airports();
  airport: Airports = new Airports();
  constructor(data?: Partial<RefDataAiportHoursModel>) {
    super(data);
    Object.assign(this, data);
    this.schedule = data?.schedule || new ScheduleModel();
  }

  static deserialize(apiData: RefDataAiportHoursModel): RefDataAiportHoursModel {
    if (!apiData) {
      return new RefDataAiportHoursModel();
    }
    const data: Partial<RefDataAiportHoursModel> = {
      ...apiData,
      id: 0,
      airportHourId: apiData?.airportHourId,
      sequence: apiData.sequence || apiData?.cappsSequenceId,
      hoursType: SettingBaseModel.deserialize({
        ...apiData.airportHoursType,
        id: apiData.airportHoursType?.airportHoursTypeId,
        name: apiData.airportHoursType.name,
      }),
      airportHoursSubType: SettingBaseModel.deserialize({
        ...apiData?.airportHoursSubType,
        id: apiData?.airportHoursSubType?.airportHoursSubTypeId,
        name: apiData?.airportHoursSubType?.name,
      }),
      hoursScheduleType: SettingBaseModel.deserialize({
        ...apiData.schedule?.scheduleType,
        id: apiData.schedule?.scheduleType?.scheduleTypeId,
        name: apiData.schedule?.scheduleType?.name,
      }),
      status: SettingBaseModel.deserialize({
        ...apiData.status,
        id: apiData.status?.statusId,
        name: apiData.status?.name,
      }),
      accessLevel: SettingBaseModel.deserialize({
        ...apiData.accessLevel,
        id: apiData.accessLevel?.accessLevelId,
        name: apiData.accessLevel?.name,
      }),
      schedule: ScheduleModel.deserialize(apiData.schedule),
      airportReference:
        apiData?.airportReference || apiData?.airport
          ? Airports.deserializeAirportReference(apiData?.airportReference || apiData?.airport)
          : null,
    };
    return new RefDataAiportHoursModel(data);
  }

  static deserializeList(apiDataList: RefDataAiportHoursModel[]): RefDataAiportHoursModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => RefDataAiportHoursModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return `${this.id}`;
  }

  public get value(): string | number {
    return this.id;
  }
}

import { DialogContent, IconButton, Typography, withStyles, Dialog, Box, DialogActions, Button } 
  from '@material-ui/core';
import React, { FC, useRef, useState } from 'react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { IClasses, Loader } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { styles } from './AircraftPhotoUpload.style';
import { AlertStore } from '@uvgo-shared/alert';
import { VendorDocumentStore, VendorLocationPhotoStore } from '../../../../Stores';
import { CloseIcon } from '@uvgo-shared/icons';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DownloadIcon from '@material-ui/icons/GetApp';

type Props = {
  classes?: IClasses;
  vendorLocationPhotoStore: VendorLocationPhotoStore;
  fileType: string;
  title: string;
  uploadDocumentFile: () => void;
  loader: Loader;
};

const AircraftPhotoUpload: FC<Props> = ({
  classes,
  vendorLocationPhotoStore,
  fileType,
  title,
  uploadDocumentFile,
  loader,
}) => {
  const [ dragActive, setDragActive ] = useState(false);
  const formRef = useRef(null);
  const inputRef = useRef(null);

  const handleClose = () => {
    vendorLocationPhotoStore.documentRemove = true;
    vendorLocationPhotoStore.file = [];
    ModalStore.close();
  };

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const invalidFile = (name: string, file: File) => {
    const parts = name.split('.');
    const extension = parts[parts.length - 1];
    const allowedExtensions = [ '.png', '.jpg' ];

    if (!allowedExtensions.includes(`.${extension}`)) {
      AlertStore.info('Application only supports .png, .jpg types');
    } else {
      vendorLocationPhotoStore.file.push(file);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    loader.setLoadingState(false);
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      Array.from(e.dataTransfer.files).forEach((file, index) => {
        invalidFile(file?.name, file);
      });
    }
  };

  const handleChange = e => {
    e.preventDefault();
    loader.setLoadingState(false);
    if (e.target.files && e.target.files[0]) {
      invalidFile(e.target.files[0]?.name, e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };
  const downloadImage = (image: string, name: string) => {
    const link = document.createElement('a');
    link.href = image;
    link.target = '_blank';
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={true}
      disableBackdropClick={true}
      fullWidth={true}
      className={classes.muiDialog}
    >
      <IconButton className={classes.closeIcon} style={{ justifyContent: 'end' }} onClick={() => handleClose()}>
        <CloseIcon />
      </IconButton>
      <strong className={classes.uploadingguide}>Uploading Image Guidelines</strong>
      <Typography
        className={classes.iconText}
        style={{ display: 'flex', flexDirection: 'row', gap: '20%', paddingLeft: '38px' }}
      >
        <div className={classes.tick}>
          <CheckCircleIcon className={classes.checkCircleIcon} />
          <span className={classes.impText}>Please offer any images of available facilities.</span>
        </div>
        <div className={classes.tick}>
          <CheckCircleIcon className={classes.checkCircleIcon} />
          <span className={classes.impText}>Please don't include any aircraft in the pictures.</span>
        </div>
      </Typography>
      <DialogContent>
        <Typography className={classes.attachmentText} gutterBottom>
          ATTACHMENTS (ONLY .PNG AND .JPG FILES)
        </Typography>
        <form
          ref={formRef}
          onDragEnter={handleDrag}
          onSubmit={e => e.preventDefault()}
          className={classes.formFileUpload}
        >
          {loader.spinner}
          <input
            ref={inputRef}
            type="file"
            accept={fileType}
            className={classes.inputFileUpload}
            onChange={handleChange}
          />
          <label
            htmlFor={classes.inputFileUpload}
            className={dragActive ? `${classes.labelFileUpload} ${classes.dragActive}` : `${classes.labelFileUpload}`}
            onClick={onButtonClick}
          >
            <CloudUpload fontSize="large" />
            <div className={classes.innerText}>
              <Typography>Drag & Drop to Upload file</Typography>
              <Typography>or</Typography>
              <Typography>Add Attachment</Typography>
            </div>
          </label>
          {dragActive && (
            <div
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
              className={classes.dragFileElement}
            ></div>
          )}
        </form>
        {vendorLocationPhotoStore.file.length > 0 &&
          vendorLocationPhotoStore.file.map((item, index) => (
            <Box className={classes.boxFile}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <div className={classes.imageContainer}>
                  <img src={URL.createObjectURL(item)} alt="file" className={classes.image} />
                </div>
                <DownloadIcon
                  size="medium"
                  className={classes.downloadIcon}
                  onClick={() => {
                    downloadImage(URL.createObjectURL(item), item?.name);
                  }}
                />
                <Typography style={{ fontSize: '14px' , fontWeight: '400' }}>{item && `${item?.name}`}</Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography style={{ fontSize: '14px' , fontWeight: '400' }}>
                  {item && `${(item?.size / 1024).toFixed(2)}K`}
                </Typography>
                <IconButton
                  onClick={() => {
                    if (index !== -1) {
                      vendorLocationPhotoStore.file.splice(index, 1);
                    }
                    formRef.current.reset();
                    vendorLocationPhotoStore.documentUpdated = false;
                  }}
                  className={classes.crossIcon}
                  style={{
                    color: '##000000 !important',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Box>
          ))}
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'end' }}>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.primaryButton} ${classes.button}`}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>{
                ModalStore.close();
                uploadDocumentFile()
              }}
              size="large"
              disabled={vendorLocationPhotoStore.file.length === 0}
            >
              Save
            </Button>
          </div>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default inject('vendorLocationPhotoStore')(withStyles(styles)(observer(AircraftPhotoUpload)));

import { HttpClient, baseApiPath } from '@uplink/shared';
import { observable, action } from 'mobx';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { Logger } from '@wings-shared/security';
import { IAPIGridRequest, IAPIPageResponse, Utilities, tapWithAction } from '@wings-shared/core';
import { vendorManagementHeaders } from './Base.store';
import { apiUrls } from './API.url';
import { COLLECTION_NAMES } from '../Modules/Shared/Enums/CollectionName.enum';
import { VendorDocumentModel } from '../Modules/Shared/Models/VendorDocument.model';
import { IAPIDocumentFile } from '../Modules/Shared/Interfaces/Request/API-Request-VendorDocument';
import { IAPIResponseVendorDocument } from '../Modules/Shared/Interfaces/Response/API-Response-VendorDocument';

export class VendorDocumentStore {
  @observable public vendorDocuments: VendorDocumentModel[] = [];
  @observable public vendorLocationDocuments: VendorDocumentModel[] = [];
  @observable public documentUri: string = null;
  @observable public isOtherNameRequired: boolean = false;
  @observable public file: File | null = null;
  @observable public index: number;
  @observable public documentUpdated: boolean = false;
  @observable public hasDataLoaded: boolean = true;
  @observable public documentRemove: boolean = false;

  @action
  public getVendorDocument(
    collectionName: COLLECTION_NAMES,
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<VendorDocumentModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: collectionName,
      ...pageRequest,
    });
    return http.get<IAPIPageResponse<IAPIVMSComparison>>(`/${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => {
        const results = this.getDeserializeList(response.results, collectionName);
        return { ...response, results: results };
      })
    );
  }

  @action
  public getVendorDocumentById(id: number): Observable<IAPIResponseVendorDocument> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    return http.get<IAPIPageResponse<IAPIResponseVendorDocument>>(`/${apiUrls.vendorDocument}/${id}`).pipe(
      Logger.observableCatchError,
      map(response => VendorDocumentModel.deserialize(response))
    );
  }

  @action
  public getVendorLocationDocumentById(id: number): Observable<IAPIResponseVendorDocument> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    return http.get<IAPIPageResponse<IAPIResponseVendorDocument>>(`/${apiUrls.vendorLocationDocument}/${id}`).pipe(
      Logger.observableCatchError,
      map(response => VendorDocumentModel.deserialize(response))
    );
  }

  @action
  public importDocumentFile(collectionName: COLLECTION_NAMES, file: File, id: string): Observable<IAPIDocumentFile> {
    const data: FormData = new FormData();
    data.append(collectionName === COLLECTION_NAMES.VENDOR_DOCUMENT ? 'vendorId' : 'vendorLocationId', id);
    data.append('document', file);
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http
      .post<IAPIDocumentFile>(
        collectionName === COLLECTION_NAMES.VENDOR_DOCUMENT
          ? `${apiUrls.vendorDocument}/upload`
          : `${apiUrls.vendorLocationDocument}/upload`,
        data
      )
      .pipe(
        Logger.observableCatchError,
        map(response => ({ ...response, results: response })),
        tapWithAction(response => (this.documentUri = response))
      );
  }

  @action
  public upsertDocument(payload: VendorDocumentModel): Observable<VendorDocumentModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<VendorDocumentModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorDocument}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorDocument}/${payload.id}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() =>
        payload.statusId === 4
          ? AlertStore.info('Document deleted successfully!')
          : AlertStore.info('Document data saved successfully!')
      ),
      map(response => VendorDocumentModel.deserialize(response))
    );
  }

  @action
  public upsertLocationDocument(payload: VendorDocumentModel): Observable<VendorDocumentModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<VendorDocumentModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocationDocument}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocationDocument}/${payload.id}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() =>
        payload.statusId === 4
          ? AlertStore.info('Document deleted successfully!')
          : AlertStore.info('Document data saved successfully!')
      ),
      map(response => VendorDocumentModel.deserialize(response))
    );
  }

  @action
  public downloadDocumentFile(documentUri: string, documentId: number, id: number): Observable<IAPIDocumentFile> {
    const data = {
      vendorId: id,
      documentUri,
      documentId,
    };
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http.post<IAPIDocumentFile>(`${apiUrls.vendorDocument}/download`, data).pipe(
      Logger.observableCatchError,
      map(response => ({ ...response, results: response.results }))
    );
  }


  public downloadUBOForm(): Observable<File> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
      responseType: 'blob',
    });
    return http.get<IAPIDocumentFile>(`${apiUrls.vendorLocationDocument}/downloaduboform`).pipe(
      Logger.observableCatchError);
  }

  @action
  public downloadLocationDocumentFile(
    documentUri: string,
    documentId: number,
    id: number
  ): Observable<IAPIDocumentFile> {
    const data = {
      vendorLocationId: id,
      documentUri,
      documentId,
    };
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http.post<IAPIDocumentFile>(`${apiUrls.vendorLocationDocument}/download`, data).pipe(
      Logger.observableCatchError,
      map(response => ({ ...response, results: response.results }))
    );
  }

  public getDeserializeList(results: any[], collectionName?: COLLECTION_NAMES): any {
    switch (collectionName) {
      case COLLECTION_NAMES.VENDOR_DOCUMENT:
        this.vendorDocuments = VendorDocumentModel.deserializeList(results);
        return this.vendorDocuments;
      case COLLECTION_NAMES.VENDOR_LOCATION_DOCUMENT:
        this.vendorDocuments = VendorDocumentModel.deserializeList(results);
        return this.vendorDocuments;
    }
  }
  @action
  public removeVendorDocument(documentId: number): Observable<string> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .delete<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorDocument}?documentId=${documentId}`)
      .pipe(
        Logger.observableCatchError,
        map((response: any) => response),
        tap(() => AlertStore.info('Vendor Document deleted successfully!'))
      );
  }

  @action
  public uploadNewVendorDocument(id: number, file: File): Observable<IAPIDocumentFile> {
    const data: FormData = new FormData();
    data.append('vendorId', id);
    data.append('document', file);
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http.post<IAPIDocumentFile>(`${apiUrls.vendorOnBoardSlideThreeDocument}/upload`, data).pipe(
      Logger.observableCatchError,
      map(response => ({ ...response, results: response })),
      tapWithAction(response => (this.documentUri = response))
    );
  }
}

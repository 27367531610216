import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  editorWrapperContainerPPRNotes: {
    padding: '24px'
  },
  drawer:{
    backgroundColor: 'rgba(25, 27, 28, 0.2)',
  }
}));

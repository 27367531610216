import React, { FC, useEffect, useRef, useState } from 'react';
import { IAPISearchFiltersDictionary, IClasses } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Box, Tab, Tabs, styled, withStyles } from '@material-ui/core';
import { SidebarMenuOperationalInfo } from '../../../../../Shared';
import { ConfirmNavigate, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { styles } from './PPR.styles';
import { VendorLocationStore } from '../../../../../../Stores';
import PPRNotes from './PPRNotes/PPRNotes';

interface Props {
  classes?: IClasses;
  searchFilters: IAPISearchFiltersDictionary;
  vendorLocationStore: VendorLocationStore;
}

const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  width: '100%',
});

const CustomTab = styled(props => <Tab disableRipple {...props} />)(() => ({
  flex: 1,
  textTransform: 'none',
  padding: 0,
  fontWeight: 600,
  fontSize: '14px',
  color: '#004BA0',
  borderBottom: '1px solid #1976D2',
  '& .Mui-selected': {
    color: '#004BA0',
  },
}));

interface Tab {
  label: string;
  value: string;
  component: React.ComponentType;
  desc: string;
  onNextButtonDisable: (state: boolean) => void;
  registerSaveData: (func: () => void) => void;
}

const PPR: FC<Props> = ({ classes, vendorLocationStore }) => {
  const navigate = useNavigate();
  const [ activeTab, setActiveTab ] = useState('2');
  const [ checkedTabs, setCheckedTabs ] = useState([ false, true ]);
  const [ isNextDisabled, setIsNextDisabled ] = useState(true);
  const saveDataFuncRef = useRef({});

  useEffect(() => {
    if (!RootDataStore.locationData.airportDataManagement) {
      navigate('/vendor/locations');
    }
  }, [ RootDataStore.locationData.airportDataManagement ]);

  useEffect(() => {
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
  }, []);

  const registerSaveData = (key, saveDataFunc) => {
    if (saveDataFunc) {
      saveDataFuncRef.current[key] = saveDataFunc;
    } else {
      delete saveDataFuncRef.current[key];
    }
  };

  const handleSaveClick = () => {
    const saveDataFunc = saveDataFuncRef.current[activeTab];
    if (saveDataFunc) {
      saveDataFunc();
    } else {
      console.warn('Save function not available for', activeTab);
    }
  };

  const handleNextButtonDisableState = (disabled: boolean) => {
    setIsNextDisabled(disabled);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    setIsNextDisabled(true);
  };

  const TABS: Tab[] = [
    {
      label: 'PPR Logistics',
      value: '1',
      component: null,
      disabled: true,
      desc: 'PPR Logistics',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('1', func),
    },
    {
      label: 'PPR Notes',
      value: '2',
      component: PPRNotes,
      disabled: false,
      desc: 'PPR Notes',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('2', func),
    },
  ];

  return (
    <ConfirmNavigate isBlocker={false}>
      <div className={classes?.editorWrapperContainerPPR}>
        <TabContext value={activeTab}>
          <CustomTabs value={activeTab} onChange={handleChange} variant="fullWidth">
            {TABS.map((tab, index) => (
              <CustomTab key={tab.value} disabled={tab.disabled} label={tab.label} value={tab.value} />
            ))}
          </CustomTabs>
          {TABS.map((tab, index) => (
            <TabPanel key={tab.value} value={tab.value}>
              {(index === 0 || checkedTabs[index]) && tab.component && (
                <tab.component
                  onNextButtonDisable={handleNextButtonDisableState}
                  registerSaveData={func => registerSaveData(activeTab, func)}
                />
              )}
            </TabPanel>
          ))}
        </TabContext>
      </div>
    </ConfirmNavigate>
  );
};

export default inject('vendorLocationStore')(withStyles(styles)(observer(PPR)));

import { HttpClient, baseApiPath } from '@uplink/shared';
import { observable, action } from 'mobx';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  IAPIGridRequest,
  IAPIPageResponse,
  Utilities,
  tapWithAction,
} from '@wings-shared/core';
import { Logger } from '@uplink-shared/security';
import {
  Airports,
  SettingBaseModel,
  VendorLocationModel,
  VendorManagmentModel,
} from '../Modules/Shared/Models';
import { IAPIAirport, IAPIVMSComparison } from '../Modules/Shared/Interfaces';
import { AlertStore } from '@uvgo-shared/alert';
import { vendorManagementHeaders, refDataHeaders } from './Base.store';
import { apiUrls } from './API.url';
import { IAPIResponseVendorAddress } from '../Modules/Shared/Interfaces/Response/API-Response-VendorAddress';
import { VendorAddressModel } from '../Modules/Shared/Models/VendorAddress.model';
import { COLLECTION_NAMES } from '../Modules/Shared/Enums/CollectionName.enum';
import { VendorLocationAddressModel } from '../Modules/Shared/Models/VendorLocationAddress.model';
import { VendorLocationContactModel } from '../Modules/Shared/Models/VendorLocationContact.model';
import { OperationInfoSettingOptionModel } from '../Modules/Shared/Models/OperationInfoSettingOptionModel.model';
import { OperationalEssentialsModel } from '../Modules/Shared/Models/OperationalEssentials.model';
import { CustomersModel } from '../Modules/Shared/Models/CustomersModel.model';

export class VendorLocationStore {
  @observable public airportList: Airports[] = [];
  @observable public vendorLocationList: VendorLocationModel[] = [];
  @observable public vendorLocationContactList: VendorLocationContactModel[] =
    [];
  @observable public vendorAddressesList: VendorAddressModel[] = [];
  @observable public customersList: CustomersModel[] = [];
  @observable public hasDataLoaded: boolean = true;
  @observable public operationalEssentialDataChanged: boolean = false;
  @observable public isCreditFieldValid: boolean = true;
  @observable public isOperationTypeFieldExist: boolean = true;
  @observable public isTimeChanged: boolean = false;
  @observable public operationTypeId: number = 0;
  @observable public operationTypeQuestions: {
    question: string;
    answer: string;
  }[] = [];
  @observable public isDuplicateTime: boolean = false;
  @observable public feeValue: number = 0;
  @observable public priceValue: number = 0;
  @observable public overTimeValue: number = null;
  @observable public leadTimeValue: number = null;
  @observable public isHoursValueChange: boolean = false;
  @observable public isCostYes: number = 4;
  @observable public currencyValue: SettingBaseModel = new SettingBaseModel();

  @action
  public getVMSComparison(
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<VendorLocationModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'VendorLocation',
      ...pageRequest,
    });

    return http
      .get<IAPIPageResponse<IAPIVMSComparison>>(
        `${apiUrls.vendorManagement}?${params}`
      )
      .pipe(
        Logger.observableCatchError,

        map((response) => {
          this.vendorLocationList = VendorLocationModel.deserializeList(
            response.results
          );
          this.vendorLocationList = this.vendorLocationList.filter((item) => {
            return (
              item.vendorLocationStatus.name !== 'Out of Business' &&
              item.vendorLocationStatus.name !== 'UWA Deactivated'
            );
          });
          return { ...response, results: this.vendorLocationList };
        })
      );
  }

  @action
  public getVMSLocationContact(
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<VendorLocationContactModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'VendorLocationContact',
      ...pageRequest,
    });

    return http
      .get<IAPIPageResponse<IAPIVMSComparison>>(
        `${apiUrls.vendorManagement}?${params}`
      )
      .pipe(
        Logger.observableCatchError,

        map((response) => {
          this.vendorLocationContactList =
            VendorLocationContactModel.deserializeList(response.results);
          return { ...response, results: this.vendorLocationContactList };
        })
      );
  }
  @action
  public getOperationalEssentialSettingOptions<T>(
    models: T[],
    settingName: string
  ) {
    const settingOptions: OperationInfoSettingOptionModel[] = [];
    models.forEach((model) => {
      const settingOption: OperationInfoSettingOptionModel =
        new OperationInfoSettingOptionModel();
      settingOption.id = 0;
      settingOption.operationalEssentialId = 0;
      settingOption[settingName] = model;
      settingOptions.push(settingOption);
    });
    return settingOptions;
  }

  @action
  public getVendorLocationContactById(
    id?: number
  ): Observable<IAPIVMSComparison> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http
      .get<IAPIPageResponse<IAPIVMSComparison>>(
        `/${apiUrls.vendorLocationContact}/${id}`
      )
      .pipe(
        Logger.observableCatchError,
        map((response) => VendorLocationContactModel.deserialize(response))
      );
  }
  @action
  public getLocationOperationalEssentialById(
    id?: number
  ): Observable<IAPIVMSComparison> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    return http
      .get<IAPIPageResponse<IAPIVMSComparison>>(
        `/${apiUrls.operationalEssential}/${id}`
      )
      .pipe(
        Logger.observableCatchError,
        map((response) => OperationalEssentialsModel.deserialize(response))
      );
  }
  @action
  public getCustomers(
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<CustomersModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.noSqlData,
      headers: refDataHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'Customer',
      ...pageRequest,
    });
    return http
      .get<IAPIPageResponse<CustomersModel>>(`/${apiUrls.refData}?${params}`)
      .pipe(
        tapWithAction(
          (response) =>
            (this.customersList = response.results.map((item, index) =>
              CustomersModel.deserialize(item)
            ))
        ),
        map((response) => CustomersModel.deserialize(response))
      );
  }

  @action
  public getVmsIcaoCode(
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<IAPIAirport>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.noSqlData,
      headers: refDataHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'Airport',
      ...pageRequest,
    });
    return http
      .get<IAPIPageResponse<IAPIAirport>>(`/${apiUrls.refData}?${params}`)
      .pipe(
        tapWithAction(
          (response) =>
            (this.airportList = response.results.map((item, index) =>
              Airports.deserialize(item)
            ))
        ),
        map((response) => VendorLocationModel.deserialize(response))
      );
  }

  @action
  public getVendorLocationById(id?: number): Observable<IAPIVMSComparison> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'VendorLocation',
      FilterCollection: JSON.stringify([
        {
          PropertyName: 'Id',
          PropertyValue: id,
        },
      ]),
    });
    return http
      .get<IAPIPageResponse<IAPIVMSComparison>>(
        `/${apiUrls.vendorManagement}?${params}`
      )
      .pipe(
        Logger.observableCatchError,
        map((response) =>
          VendorLocationModel.deserialize(
            response.results
              ? response.results.length > 0
                ? response.results[0]
                : []
              : []
          )
        )
      );
  }

  @action
  public getVendorLocationAddressById(
    id?: number
  ): Observable<IAPIVMSComparison> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http
      .get<IAPIPageResponse<IAPIVMSComparison>>(
        `/${apiUrls.vendorLocationAddress}/${id}`
      )
      .pipe(
        Logger.observableCatchError,
        map((response) => VendorLocationAddressModel.deserialize(response))
      );
  }

  @action
  public upsertVendorLocation(
    payload: VendorLocationModel
  ): Observable<VendorLocationModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<VendorLocationModel> = isNewRequest
      ? http.post<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocation}`,
        payload
      )
      : http.put<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocation}/${payload.id}`,
        payload
      );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() =>
         payload.vendorLocationStatus?.name === 'Out of Business' ||
         payload.vendorLocationStatus?.name === 'UWA Deactivated'
           ? AlertStore.info('Location deleted successfully!')
           : AlertStore.info('Vendor Location data saved successfully!')
      ),
      map((response) => VendorLocationModel.deserialize(response))
    );
  }

  public searchAirport = (searchKey: string, CountryId?: number): void => {
    const request = {
      searchCollection: JSON.stringify([
        { propertyName: 'Name', propertyValue: searchKey },
        {
          propertyName: 'ICAOCode.Code',
          propertyValue: searchKey,
          operator: 'or',
        },
        { propertyName: 'UWACode', propertyValue: searchKey, operator: 'or' },
        { propertyName: 'FAACode', propertyValue: searchKey, operator: 'or' },
        { propertyName: 'IATACode', propertyValue: searchKey, operator: 'or' },
        {
          propertyName: 'RegionalCode',
          propertyValue: searchKey,
          operator: 'or',
        },
        {
          propertyName: 'DisplayCode',
          propertyValue: searchKey,
          operator: 'or',
        },
      ]),
    };
    const pageRequest: IAPIGridRequest = {
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportLocation.Country.CountryId',
          propertyValue: CountryId,
        },
      ]),
      searchCollection: JSON.stringify([
        { propertyName: 'Name', propertyValue: searchKey },
        {
          propertyName: 'ICAOCode.Code',
          propertyValue: searchKey,
          operator: 'or',
        },
        { propertyName: 'UWACode', propertyValue: searchKey, operator: 'or' },
        { propertyName: 'FAACode', propertyValue: searchKey, operator: 'or' },
        { propertyName: 'IATACode', propertyValue: searchKey, operator: 'or' },
        {
          propertyName: 'RegionalCode',
          propertyValue: searchKey,
          operator: 'or',
        },
        {
          propertyName: 'DisplayCode',
          propertyValue: searchKey,
          operator: 'or',
        },
      ]),
    };
    this.getVmsIcaoCode(CountryId ? pageRequest : request).subscribe();
  };

  @action
  public getVMSLocationAddresses(
    pageRequest?: IAPIGridRequest,
    collectionName?: COLLECTION_NAMES
  ): Observable<IAPIPageResponse<VendorAddressModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: collectionName,
      ...pageRequest,
    });

    return http
      .get<IAPIPageResponse<IAPIResponseVendorAddress>>(
        `${apiUrls.vendorManagement}?${params}`
      )
      .pipe(
        Logger.observableCatchError,

        map((response) => {
          this.vendorAddressesList = VendorAddressModel.deserializeList(
            response.results
          );
          return { ...response, results: this.vendorAddressesList };
        })
      );
  }

  @action
  public upsertVendorLocationAddress(
    payload: VendorLocationAddressModel
  ): Observable<VendorLocationAddressModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<VendorLocationAddressModel> = isNewRequest
      ? http.post<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocationAddress}`,
        payload
      )
      : http.put<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocationAddress}/${payload.id}`,
        payload
      );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() =>
        AlertStore.info('Vendor Location Address data saved successfully!')
      ),
      map((response) => VendorLocationAddressModel.deserialize(response))
    );
  }

  @action
  public removVendorLocationAddress(
    vendorLocationAddressId: number
  ): Observable<string> {
    const payload = {
      userId: 'string',
      vendorLocationAddressId,
    };
    const http = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .delete<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocationAddress}`,
        payload
      )
      .pipe(
        Logger.observableCatchError,
        map((response: any) => response),
        tap(() => AlertStore.info('Vendor Address data deleted successfully!'))
      );
  }

  @action
  public upsertVendorLocationOperationalEssential(
    payload: OperationalEssentialsModel
  ): Observable<OperationalEssentialsModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<OperationalEssentialsModel> = isNewRequest
      ? http.post<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.operationalEssential}`,
        payload
      )
      : http.put<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.operationalEssential}/${payload.id}`,
        payload
      );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() =>
        AlertStore.info('Operational Essentials data saved successfully!')
      ),
      map((response) => OperationalEssentialsModel.deserialize(response))
    );
  }
}

import React from 'react';
import { INavigationLink, RootDataStore } from '@uplink-shared/layout';
import {
  AddressBookIcon,
  PhoneIcon,
  LocationIcon,
  TfoSidebarGeneralInfoIcon,
  MenuListIcon,
  UserIconDarkIcon,
  PriceTagIcon,
  VendorIcon,
  AdditionalInfoIcon,
  InfoFilledIcon,
} from '@uvgo-shared/icons';
import DescriptionIcon from '@material-ui/icons/Description';
import { AuthStore } from '@uplink-shared/security';

const SidebarMenus = (): INavigationLink[] => [
  {
    to: RootDataStore.locationData.hasMultipleVendor ? '/vendor/vendors' : '/vendor/general-info',
    title: 'Vendor',
    icon: <VendorIcon />,
    expandedMenuBlock: 'panel1',
    subMenuItem: [
      ...(RootDataStore.locationData.hasMultipleVendor
        ? [
          {
            to: '/vendor/vendors',
            title: 'Vendor List',
            icon: <MenuListIcon />,
          },
        ]
        : []),
      {
        to: '/vendor/general-info',
        title: 'General Information',
        icon: <InfoFilledIcon />,
        isDisabled: !AuthStore.vendorProfile.isVendorSelected,
      },
      {
        to: '/vendor/contact',
        title: 'Contacts',
        icon: <PhoneIcon />,
        isDisabled: !AuthStore.vendorProfile.id,
      },
      {
        to: '/vendor/addresses',
        title: 'Addresses',
        icon: <AddressBookIcon />,
        isDisabled: !AuthStore.vendorProfile.id,
      },
      {
        to: '/vendor/documents',
        title: 'Documents',
        icon: <DescriptionIcon />,
        isDisabled: !AuthStore.vendorProfile.id,
      },
      {
        to: '/vendor/user',
        title: 'User',
        icon: <UserIconDarkIcon />,
        isDisabled: !AuthStore.vendorProfile.id,
      },
    ],
  },
  {
    to: '/vendor/locations',
    title: 'Location',
    expandedMenuBlock: 'panel2',
    icon: <LocationIcon />,
    isDisabled: !AuthStore.vendorProfile.isVendorSelected,
    subMenuItem: [
      {
        to: '/vendor/locations',
        title: 'Location List',
        icon: <MenuListIcon />,
        isDisabled: !AuthStore.vendorProfile.isVendorSelected,
      },
      {
        to: '/vendor/locations/general-information',
        title: 'General Information',
        icon: <TfoSidebarGeneralInfoIcon />,
        isDisabled: !RootDataStore.locationData.isLocationSet,
      },
      {
        to: '/vendor/location/addresses',
        title: 'Addresses',
        icon: <AddressBookIcon />,
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/contact',
        title: 'Contacts',
        icon: <PhoneIcon />,
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/documents',
        title: 'Documents',
        icon: <DescriptionIcon />,
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/operational-essential',
        title: 'Operational Essentials',
        icon: <TfoSidebarGeneralInfoIcon />,
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/pricing',
        title: 'Pricing',
        icon: <PriceTagIcon />,
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/operational-information/operational-hours',
        title: 'Operational Information',
        icon: <AdditionalInfoIcon />,
        isDisabled: !RootDataStore.locationData.locationId,
      },
    ],
  },
];

export default SidebarMenus;

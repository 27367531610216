import React, { FC, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, FormControl, OutlinedInput, withStyles, FormHelperText, Typography, TextField } from '@material-ui/core';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import MainOperationalHours from '../MainTerminalOnly/MainOperationalHours/MainOperationalHours';
import {
  CustomAccordion,
  AccordionRadioGroups,
  ServiceItemPricingModel,
  SETTING_ID,
  SettingBaseModel,
  HoursCost,
  PricingFeeCost,
  LocationHoursModel,
} from '../../../../../Shared';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AlertStore } from '@uvgo-shared/alert';
import { useUnsubscribe } from '@wings-shared/hooks';
import { IAPIGridRequest, IClasses, UIStore, MODEL_STATUS } from '@wings-shared/core';
import {
  ServiceItemPricingStore,
  SettingsStore,
  VendorLocationHoursStore,
  VendorLocationStore,
} from '../../../../../../Stores';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { RootDataStore } from '@uplink-shared/layout';
import { styles } from '../../AirportHours.styles';
import { Autocomplete } from '@material-ui/lab';
import AirportMainOperationalHours from '../MainTerminalOnly/MainOperationalHours/AirportMainOperationalHours';
import moment from 'moment';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  vendorLocationHoursStore: VendorLocationHoursStore;
  serviceItemPricingStore: ServiceItemPricingStore;
  settingsStore: SettingsStore;
  onNextButtonDisable?: (boolean) => void;
  registerSaveData: (saveData: () => void) => void;
}

const GatBuilding: FC<Props> = ({
  classes,
  vendorLocationStore,
  vendorLocationHoursStore,
  serviceItemPricingStore,
  onNextButtonDisable,
  registerSaveData,
  settingsStore,
}) => {
  const gridState = useGridState();
  const [ error, setError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ airportList, setAirportList ] = useState([]);

  const loadSettingHoursId = () => {
    settingsStore?.getSettings(SETTING_ID.SETTING_AIRPORT_HOURS_TYPE).subscribe();
    settingsStore?.getSettings(SETTING_ID.SETTING_AIRPORT_HOURS_SUB_TYPE).subscribe();
    settingsStore?.getSettings(SETTING_ID.SETTINGS_CURRENCY).subscribe();
  };

  const handleInputValue = event => {
    const value = event.target.value;
    vendorLocationStore.isHoursValueChange = true;
    const validFloat = /^([0-9]{1,5})(\.[0-9]{1,2})?$/;
    let errorMessage = '';

    if (!value) {
      errorMessage = 'This value is required';
    } else if (!/^\d*(\.\d*)?$/.test(value)) {
      errorMessage = 'The value must be a number';
    } else if (parseFloat(value) < 0 || parseFloat(value) > 99999.99) {
      errorMessage = 'The value must be between 0 and 99999.99';
    } else if (!validFloat.test(value)) {
      errorMessage = 'This value format is invalid';
    }

    vendorLocationStore.priceValue = value;

    if (errorMessage) {
      setErrorMessage(errorMessage);
      setError(true);
    } else {
      setError(false);
    }
  };

  const loadPricingData = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'ServiceItem.Id',
          propertyValue: '745',
        },
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: [ RootDataStore.locationData.locationId ],
          filterType: 'in',
          operator: 'and',
        },
      ]),
    };
    serviceItemPricingStore.getVMSComparison(request).subscribe(response => {
      UIStore.setPageLoader(false);
      if (response.results.length > 0) {
        const result = ServiceItemPricingModel.deserialize(response.results[0]);
        serviceItemPricingStore.pricingId = result.id;
        if (result.status.id === MODEL_STATUS.ACTIVE) {
          vendorLocationStore.priceValue = result.price;
          vendorLocationStore.feeValue = result.uom.id;
          vendorLocationStore.isCostYes = HoursCost.YES;
          vendorLocationStore.currencyValue = SettingBaseModel.deserialize(result.currency);
        }
      }
    });
  };

  const pricingErrorHandler = (errors: object): void => {
    Object.values(errors)?.forEach(errorMessage => AlertStore.info(errorMessage[0]));
  };

  const saveData = () => {
    if (vendorLocationStore.isCostYes === HoursCost.NO) {
      if (serviceItemPricingStore.pricingId) {
        upsertPricing(MODEL_STATUS.IN_ACTIVE);
      }
    } else if (
      vendorLocationStore.priceValue &&
      vendorLocationStore.feeValue &&
      vendorLocationStore.currencyValue.id !== undefined
    ) {
      upsertPricing(MODEL_STATUS.ACTIVE);
    }
  };

  const upsertPricing = (statusId: number) => {
    UIStore.setPageLoader(true);
    const model = new ServiceItemPricingModel();
    serviceItemPricingStore
      ?.upsertServiceItemPricingLocations([
        model.serializeHoursPricing(
          serviceItemPricingStore.pricingId,
          745,
          vendorLocationStore.priceValue,
          vendorLocationStore.feeValue,
          statusId,
          vendorLocationStore.currencyValue?.id
        ),
      ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: ServiceItemPricingModel) => {
          loadPricingData();
          vendorLocationStore.isHoursValueChange = false;
        },
        error: error => {
          if (error.response.data.errors) {
            pricingErrorHandler(error.response.data.errors);
            return;
          }
          AlertStore.info(error.message);
        },
      });
  };

  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    vendorLocationStore.isCostYes = HoursCost.NO;
    serviceItemPricingStore.pricingId = 0;
    vendorLocationStore.isHoursValueChange = false;
    vendorLocationStore.priceValue = null;
    vendorLocationStore.feeValue = 0;
    vendorLocationStore.currencyValue = null;
    setError(false);
    loadInitialData();
    loadPricingData();
    loadSettingHoursId();
  }, []);

  const validateForm = () => {
    if (vendorLocationStore.isHoursValueChange && vendorLocationStore.isCostYes === HoursCost.NO) {
      if (!serviceItemPricingStore.pricingId) {
        return true;
      }
      return false;
    }

    if (vendorLocationStore.isHoursValueChange && vendorLocationStore.isCostYes === HoursCost.YES) {
      return (
        vendorLocationStore.priceValue === null ||
        vendorLocationStore.feeValue === 0 ||
        vendorLocationStore.currencyValue?.id === undefined ||
        error
      );
    }
    return true;
  };

  useEffect(() => {
    onNextButtonDisable(validateForm() || UIStore.pageLoading);
  }, [
    vendorLocationStore.priceValue,
    vendorLocationStore.feeValue,
    vendorLocationStore.isHoursValueChange,
    vendorLocationStore.isCostYes,
    vendorLocationStore.currencyValue,
  ]);

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  const loadInitialData = () => {
    vendorLocationStore.isTimeChanged = false;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReference.id,
        },
        {
          propertyName: 'AirportHoursType.Name',
          propertyValue: 'Operational',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursScheduleType.Name',
          propertyValue: 'Recurrence',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursSubType.Name',
          propertyValue: 'GA Terminal',
          operator: 'and',
        },
        {
          propertyName: 'ApprovalStatus.Name',
          propertyValue: 'Pending',
          operator: 'and',
        },
      ]),
    };
    const refRequestIAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Airport.AirportId',
          propertyValue: RootDataStore.locationData.airportReference.airportId,
        },
        {
          propertyName: 'AirportHoursType.Name',
          propertyValue: 'Operational',
          operator: 'and',
        },
        {
          propertyName: 'Schedule.ScheduleType.Name',
          propertyValue: 'Recurrence',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursSubType.Name',
          propertyValue: 'GA Terminal',
          operator: 'and',
        },
      ]),
    };
    forkJoin([
      vendorLocationHoursStore.getAirportHours(request),
      vendorLocationHoursStore.getrefDataAirportHours(refRequestIAPIGridRequest),
    ]).subscribe(response => {
      const filteredRefResult = response[1].results.filter(
        item2 =>
          !response[0].results.some(item1 => {
            const startTime1 = moment(item1.schedule?.startTime)
              .utc()
              .format('HH:mm');
            const startTime2 = moment(item2?.schedule?.startTime)
              .utc()
              .format('HH:mm');
            const endTime1 = moment(item1.schedule?.endTime)
              .utc()
              .format('HH:mm');
            const endTime2 = moment(item2?.schedule?.endTime)
              .utc()
              .format('HH:mm');
            return item1.airportHourId === item2.airportHourId || (startTime1 === startTime2 && endTime1 === endTime2);
          })
      );
      setAirportList([ ...response[0].results, ...LocationHoursModel.deserializeList(filteredRefResult) ]);
      UIStore.setPageLoader(false);
      ModalStore.close();
    });
  };

  const isVIPAreaData = [
    { id: HoursCost.YES, value: 'yes', label: 'Yes' },
    { id: HoursCost.NO, value: 'no', label: 'No' },
  ];

  const gatFeeApplicationData = [
    { id: PricingFeeCost.PerPerson, value: 'Per Person', label: 'Per person, per use (arrival or departure)' },
    { id: PricingFeeCost.PerUse, value: 'Per Use', label: 'Per facility use (regardless of pax count)' },
  ];

  const errorHandler = (errors: { [key: string]: string[] }): void => {
    Object.keys(errors).forEach(key => {
      const errorMessages = errors[key];
      errorMessages.forEach(message => {
        AlertStore.info(message);
      });
    });
  };

  const handleErrorResponse = error => {
    if (error?.response?.data?.errors) {
      errorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      AlertStore.info(error?.message);
    }
  };

  const deleteAllRecords = () => {
    const ids =
      vendorLocationHoursStore.timeDataHoursData && vendorLocationHoursStore.timeDataHoursData.map(item => item.id);
    if (ids.length === 0) {
      ModalStore.close();
      return;
    }
    UIStore.setPageLoader(true);
    vendorLocationHoursStore
      ?.deleteAirportHours(ids)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel[]) => {
          AlertStore.info('Airport Hours saved successfully!');
          loadInitialData();
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  const extractTime = isoString => {
    return new Date(isoString)
      .toISOString()
      .split('T')[1]
      .slice(0, 5);
  };

  const handleSave = () => {
    const hoursTypeId = settingsStore.airportHoursType.filter(item => {
      return item.name === 'Operational';
    });

    const hoursSubTypeId = settingsStore.airportHoursSubType.filter(item => {
      return item.name === 'GA Terminal';
    });

    const filteredAirportList = airportList.filter(airportItem => {
      const airportStartTime = extractTime(airportItem.schedule.startTime);
      const airportEndTime = extractTime(airportItem.schedule.endTime);

      const existsInUpdatedHoursData = vendorLocationHoursStore.updatedHoursData.some(updatedItem => {
        const updatedStartTime = extractTime(updatedItem.scheduleRequest.startTime);
        const updatedEndTime = extractTime(updatedItem.scheduleRequest.endTime);

        return updatedStartTime === airportStartTime && updatedEndTime === airportEndTime;
      });

      return !existsInUpdatedHoursData;
    });

    const updatedFilteredAirportList = LocationHoursModel.deserializeList(
      filteredAirportList.map(item => ({
        ...item,
        status: SettingBaseModel.deserialize({ id: 2, name: 'Inactive' }),
        statusId: 2,
      }))
    );
    vendorLocationHoursStore.updatedHoursData.push(...updatedFilteredAirportList);

    UIStore.setPageLoader(true);
    vendorLocationHoursStore
      ?.upsertAirportHour(
        LocationHoursModel.airportHoursSerializeList(
          vendorLocationHoursStore.updatedHoursData,
          hoursTypeId[0].id || 1,
          hoursSubTypeId[0].id || 21
        )
      )
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel) => {
          AlertStore.info('Airport Hours saved successfully!');
          loadInitialData();
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  const onRadioChange = (value: number) => {
    vendorLocationStore.isHoursValueChange = true;
    vendorLocationStore.isCostYes = value;
    if (value === HoursCost.YES) {
      vendorLocationStore.priceValue = null;
      vendorLocationStore.feeValue = 0;
      vendorLocationStore.currencyValue = null;
      setError(false);
      loadPricingData();
    }
  };

  const onFeeApplicationValueChange = (value: number) => {
    vendorLocationStore.feeValue = value;
    vendorLocationStore.isHoursValueChange = true;
  };

  return (
    <div className={classes.mainTerminalOnlyWrapper}>
      <Box>
        <CustomAccordion
          panelName="gatOperationalHours"
          panelHeading="General Aviation Terminal (GAT) - Operational Hours (in local time)"
          panelContent={<MainOperationalHours handleSave={handleSave} locationHoursList={airportList} />}
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="isGatCostApplicable"
          panelHeading="GAT Cost"
          panelBodyHeading="Is there a fee for using the General Aviation Terminal?"
          panelContent={
            <Box>
              <AccordionRadioGroups
                radioValue={vendorLocationStore.isCostYes}
                onRadioChange={onRadioChange}
                radioGroupData={isVIPAreaData}
              />
              {vendorLocationStore.isCostYes === HoursCost.YES && (
                <Box>
                  <FormControl variant="outlined">
                    <Typography className={classes.heading}>
                      What is the cost for using the General Aviation Terminal? (In your local currency)
                    </Typography>
                    <Box className={classes.costFields}>
                      <OutlinedInput
                        className="inputTextField"
                        id="outlined-adornment-gat-cost"
                        value={vendorLocationStore.priceValue}
                        onChange={handleInputValue}
                        aria-describedby="outlined-terminal-cost-text"
                        inputProps={{
                          'aria-label': 'gat cost',
                        }}
                        labelWidth={0}
                        style={{
                          borderColor: error ? 'red' : 'initial',
                          borderWidth: '1px',
                          borderStyle: error ? 'solid' : 'none',
                        }}
                        placeholder="Enter Cost"
                      />
                      <Autocomplete
                        autoHighlight
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={vendorLocationStore.currencyValue}
                        options={settingsStore.vendorSettingsCurrency}
                        renderInput={params => <TextField {...params} placeholder="Select Currency" />}
                        getOptionLabel={option => option.label}
                        onChange={(event: any, newValue) => {
                          vendorLocationStore.isHoursValueChange = true;
                          vendorLocationStore.currencyValue = SettingBaseModel.deserialize(newValue);
                        }}
                        className={classes.inputDropdown}
                      />
                    </Box>
                    {error && <FormHelperText style={{ color: 'red' }}>{errorMessage}</FormHelperText>}
                  </FormControl>
                  <AccordionRadioGroups
                    radioValue={vendorLocationStore.feeValue}
                    onRadioChange={onFeeApplicationValueChange}
                    radioGroupData={gatFeeApplicationData}
                    label="Is this fee person, or per facility use?"
                  />
                </Box>
              )}
            </Box>
          }
        />
      </Box>
    </div>
  );
};

export default inject(
  'vendorLocationStore',
  'serviceItemPricingStore',
  'vendorLocationHoursStore',
  'settingsStore'
)(withStyles(styles)(observer(GatBuilding)));

import React, { FC, useCallback, useEffect, useState } from 'react';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useBaseUpsertComponent } from '@uplink/shared';
import {
  IClasses,
  Utilities,
  IOptionValue,
  UIStore,
  IAPISearchFiltersDictionary,
  IAPIGridRequest,
  IAPIPageResponse,
} from '@wings-shared/core';
import { ConfirmNavigate, SidebarStore } from '@uplink-shared/layout';
import { TextField, Typography, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { BaseStore, SlideEightStore, SlideOneAndTwoStore, VendorLocationStore } from '../../../../Stores';
import {
  newVendorOnboarding,
  OperationalEssentialsModel,
  VendorLocationContactModel,
  VendorLocationModel,
  VendorOnBoardSlideNineModel,
  VendorOnBoardSlideOneNTwoModel,
  ViewInputControls,
} from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { fields } from './Fields';
import { styles } from './Slide9.styles';
import { debounce } from 'lodash-es';
import { Autocomplete } from '@material-ui/lab';
import { AuthStore } from '@uplink-shared/security';

interface Props {
  classes?: IClasses;
  slideEightStore: SlideEightStore;
  slideOneAndTwoStore: SlideOneAndTwoStore;
  vendorLocationStore: VendorLocationStore;
  searchFilters: IAPISearchFiltersDictionary;
  registerSaveData: (saveData: () => void) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<number>;
  onNextButtonDisable: (boolean) => void;
}

const Slide9: FC<Props> = ({
  classes,
  searchFilters,
  vendorLocationStore,
  registerSaveData,
  activeStep,
  setActiveStep,
  slideEightStore,
  onNextButtonDisable,
  slideOneAndTwoStore,
}) => {
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<VendorOnBoardSlideNineModel>({}, fields, searchFilters);
  const [ vendorLocationList, setVendorLocationList ] = useState<VendorLocationModel[]>([]);
  const formRef = useUpsert.form;

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  const saveData = () => {
    upsertSlideNine();
  };

  const isOtherFieldExist = () => {
    return Boolean(useUpsert.getField('groundServiceProviderAppliedVendorLocation').value?.id === 99999999);
  };

  const validateForm = () => {
    return !formRef.isValid || formRef.hasError || !formRef.changed;
  };

  useEffect(() => {
    onNextButtonDisable(validateForm());
  }, [ formRef.changed, formRef.isValid, formRef.hasError ]);

  useEffect(() => {
    SidebarStore.setNavLinks(newVendorOnboarding(), 'vendor', 'Vendor Locations', '/vendor/locations');
    loadInitialData();
    loadAirportCode();
  }, [ activeStep ]);

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'groundServiceProviderAppliedVendorLocation',
            type: EDITOR_TYPES.DROPDOWN,
            options: vendorLocationList || [ VendorLocationModel.deserialize({ id: 99999999, name: 'Other' }) ],
            isFullFlex: true,
          },
          {
            fieldKey: 'legalBusinessName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isHidden: !isOtherFieldExist(),
          },
          {
            fieldKey: 'managerName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: !isOtherFieldExist(),
          },
          {
            fieldKey: 'assitManagerName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: !isOtherFieldExist(),
          },
          {
            fieldKey: 'primaryPhoneNo',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: !isOtherFieldExist(),
          },
          {
            fieldKey: 'secondaryPhoneNo',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: !isOtherFieldExist(),
          },
          {
            fieldKey: 'fax',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: !isOtherFieldExist(),
          },
          {
            fieldKey: 'email',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: !isOtherFieldExist(),
          },
          {
            fieldKey: 'secondaryEmail',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: !isOtherFieldExist(),
          },
        ],
      },
    ];
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const upsertSlideNine = () => {
    UIStore.setPageLoader(true);
    const request = new VendorOnBoardSlideNineModel({
      ...useUpsert.form.values(),
      tempLocationId: slideOneAndTwoStore.tempLocationId,
    });
    slideEightStore
      .upsertSlideNine(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorOnBoardSlideNineModel) => {
          useUpsert.form.reset();
          loadInitialData();
        },
        error: error => {
          if (error?.response?.data?.errors) {
            errorHandler(error?.response?.data?.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error?.message, request.id.toString());
        },
      });
  };

  const loadLocationContactGridData = (locationId: number) => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: 500,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: locationId,
        },
        {
          propertyName: 'Status.Id',
          propertyValue: 3,
          filterType: 'ne',
        },
        {
          propertyName: 'Contact.ContactMethod.Id',
          propertyValue: [ 1, 2, 3 ],
          filterType: 'in',
        },
      ]),
    };
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVMSLocationContact(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: IAPIPageResponse<VendorLocationContactModel>) => {
        const results = VendorLocationContactModel.deserializeList(response.results);
        results &&
          results.forEach(({ contact, contactUsegeType }) => {
            const { contactMethod, contact: contactValue } = contact || {};
            switch (contactUsegeType?.id) {
              case 1:
                switch (contactMethod?.id) {
                  case 1:
                    useUpsert.getField('primaryPhoneNo').set(contactValue);
                    break;
                  case 2:
                    useUpsert.getField('fax').set(contactValue);
                    break;
                  case 3:
                    useUpsert.getField('email').set(contactValue);
                    break;
                }
                break;

              case 2:
                switch (contactMethod?.id) {
                  case 1:
                    useUpsert.getField('secondaryPhoneNo').set(contactValue);
                    break;
                  case 3:
                    useUpsert.getField('secondaryEmail').set(contactValue);
                    break;
                }
                break;
            }
          });
      });
  };

  const loadLocationOperationalEssentialData = (locationId: number) => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      .getLocationOperationalEssentialById(locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: OperationalEssentialsModel) => {
          useUpsert.getField('managerName').set(response.managerName);
          useUpsert.getField('assitManagerName').set(response.asstManagerName);
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, '0');
            return;
          }
          BaseStore.showAlert(error.message, 0);
        },
      });
  };

  const loadAirportCode = () => {
    UIStore.setPageLoader(true);
    if (AuthStore.vendorProfile?.id) {
      slideOneAndTwoStore
        .getByVendorId(AuthStore.vendorProfile?.id)
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => {})
        )
        .subscribe((response: VendorOnBoardSlideOneNTwoModel) => {
          if (response[0]) {
            slideOneAndTwoStore.tempLocationId = response[0]?.tempLocationId;
            slideOneAndTwoStore.airportCode =
              response[0]?.airportReference?.displayCode || response[0]?.airportReference?.icaoCode;
            loadLocationFieldData(slideOneAndTwoStore.airportCode);
          }
        });
    }
  };

  const loadLocationFieldData = (searchValue?: string) => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...(searchValue && {
        searchCollection: JSON.stringify([
          {
            propertyName: 'AirportReference.DisplayCode',
            propertyValue: searchValue,
          },
        ]),
      }),
    };
    vendorLocationStore
      .getVMSComparison(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorLocationModel[]) => {
          response.results.push(VendorLocationModel.deserialize({ id: 99999999, name: 'Other' }));
          setVendorLocationList(response.results);
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, '0');
            return;
          }
          BaseStore.showAlert(error.message, 0);
        },
      });
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'groundServiceProviderAppliedVendorLocation':
        if (value?.id) {
          if (value?.id === 99999999) {
            resetFields();
            if (slideEightStore.groundServiceLocation?.vendorLocationId === 0) {
              loadInitialData();
            }
          } else {
            resetFields();
            loadLocationContactGridData(value?.id);
            loadLocationOperationalEssentialData(value?.id);
          }
        }
        break;
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'groundServiceProviderAppliedVendorLocation':
        let filteredTypes;
        if (slideOneAndTwoStore.airportCode) {
          filteredTypes = vendorLocationStore.vendorLocationList?.filter(data => {
            return data.label?.toLowerCase().includes(searchValue.toLowerCase());
          });
        } else {
          loadLocationFieldData(searchValue);
        }
        setVendorLocationList(filteredTypes);
        break;
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      default:
        break;
    }
  };

  const resetFields = () => {
    useUpsert.getField('primaryPhoneNo').set(null);
    useUpsert.getField('email').set(null);
    useUpsert.getField('secondaryPhoneNo').set(null);
    useUpsert.getField('secondaryEmail').set(null);
    useUpsert.getField('fax').set(null);
    useUpsert.getField('managerName').set(null);
    useUpsert.getField('assitManagerName').set(null);
    useUpsert.getField('legalBusinessName').set(null);
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    slideEightStore
      .getByVendorIdSlideNine(slideOneAndTwoStore.tempLocationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: VendorOnBoardSlideNineModel) => {
        const data = VendorOnBoardSlideNineModel.deserialize(response);
        useUpsert.setFormValues(data);
        if (!data.groundServiceProviderAppliedVendorLocation) {
          useUpsert.getField('groundServiceProviderAppliedVendorLocation').set(
            VendorLocationModel.deserialize({
              id: 99999999,
              name: 'Other',
            })
          );
        } else {
          slideEightStore.groundServiceLocation = VendorLocationModel.deserialize(
            data.groundServiceProviderAppliedVendorLocation
          );
        }
      });
  };

  return (
    <ConfirmNavigate isBlocker={false}>
      <Typography className={classes.heading}>
        If you are coordinating with a local handler, please provide their information as follows:
      </Typography>
      <div className={classes.editorWrapperContainer}>
        <ViewInputControls
          isEditable={true}
          groupInputControls={groupInputControls()}
          onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
          onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
          field={fieldKey => useUpsert.getField(fieldKey)}
          onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
          onFocus={fieldKey => onFocus(fieldKey)}
        />
      </div>
    </ConfirmNavigate>
  );
};

export default inject(
  'vendorLocationStore',
  'slideOneAndTwoStore',
  'slideEightStore'
)(withStyles(styles)(observer(Slide9)));

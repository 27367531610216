import {
  IAPIGridRequest,
  IAPISearchFiltersDictionary,
  IClasses,
  IOptionValue,
  UIStore,
  MODEL_STATUS,
  Utilities,
} from '@wings-shared/core';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { forkJoin } from 'rxjs';
import { Box, Typography, withStyles, IconButton, Button } from '@material-ui/core';
import { MixPanelTrackingEvents, NO_SQL_COLLECTIONS, useBaseUpsertComponent, VIEW_MODE } from '@uplink/shared';
import { useGridState } from '@wings-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import {
  DetailsEditorWrapper,
  ConfirmNavigate,
  SidebarStore,
  RootDataStore,
  SidebarOptions,
} from '@uplink-shared/layout';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { fields } from './Fields';
import {
  HandlerBulletinsModel,
  SETTING_ID,
  CustomTooltip,
  SidebarMenuOperationalInfo,
  ViewInputControls,
  Airports,
  SettingBaseModel,
  VendorLocationModel,
  CustomDialog,
  BULLETIN_LEVEL_SETTINGS,
  COLLECTION_NAMES,
  RefDataBulletins,
} from '../../../../../../../Shared';
import { styles } from './UpsertPPRNotes.styles';
import { SettingsStore, BulletinStore, VendorLocationStore } from '../../../../../../../../Stores';
import { finalize, takeUntil } from 'rxjs/operators';
import { useNavigate } from 'react-router';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { ChevronRightIcon } from '@uvgo-shared/icons';

interface Props {
  classes?: IClasses;
  bulletinStore: BulletinStore;
  vendorLocationStore: VendorLocationStore;
  searchFilters?: IAPISearchFiltersDictionary;
  settingsStore: SettingsStore;
  item?: HandlerBulletinsModel | RefDataBulletins;
  viewMode: VIEW_MODE;
  isRefData: boolean;
  loadPPRNotesData: (pageRequest?: IAPIGridRequest) => void;
  setOpen: React.Dispatch<boolean>;
}

const UpsertPPRNotes: FC<Props> = ({
  classes,
  bulletinStore,
  vendorLocationStore,
  settingsStore,
  searchFilters,
  item,
  viewMode,
  isRefData,
  setOpen,
  loadPPRNotesData,
}) => {
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<HandlerBulletinsModel | RefDataBulletins>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const navigate = useNavigate();
  const [ noteTypes, setNoteTypes ] = useState([]);
  const [ airportLevels, setAirportLevels ] = useState([]);
  const [ noteLevels, setNoteLevels ] = useState([]);
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  useEffect(() => {
    if (!RootDataStore.locationData.airportDataManagement) {
      navigate('/vendor/locations');
    }
  }, [ RootDataStore.locationData.airportDataManagement ]);

  useEffect(() => {
    if (settingsStore.bulletinLevels) {
      setNoteLevels([
        ...settingsStore.bulletinLevels,
        ...SettingBaseModel.deserializeList([
          { id: 3, name: 'City' },
          { id: 4, name: 'Country' },
          { id: 5, name: 'State' },
        ]),
      ]);
    }
  }, [ settingsStore.bulletinLevels ]);

  useEffect(() => {
    setIsSubmitting(false);
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
    loadLocationAddress();
    settingsStore
      .getHandlerBulletinSettings(SETTING_ID.SETTING_BULLETIN_TYPES, BULLETIN_LEVEL_SETTINGS.AIRPORT)
      .subscribe(response => setNoteTypes(response.results));
    forkJoin([
      settingsStore.getHandlerBulletinSettings(SETTING_ID.SETTING_BULLETIN_LEVELS, BULLETIN_LEVEL_SETTINGS.AIRPORT),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(response => {
        setAirportLevels(response[0].results);
        setAirportLevels(prevLevels => [
          ...prevLevels,
          ...SettingBaseModel.deserializeList([
            { id: 3, name: 'City' },
            { id: 4, name: 'Country' },
            { id: 5, name: 'State' },
          ]),
        ]);
      });
    useUpsert.getField('startDate').set(new Date());
    useUpsert.getField('bulletinType').set(
      SettingBaseModel.deserialize({
        id: 38,
        name: 'Prior Permission (PPR)',
      })
    );
    useUpsert.getField('bulletinLevel').set(
      SettingBaseModel.deserialize({
        id: 1,
        name: 'Airport',
      })
    );
    bulletinStore.isEndDateRequired = true;
    bulletinStore.isNotesTextExist = false;
    if (item?.id && !isRefData) {
      loadInitialData();
    }
    if (item?.id && isRefData) {
      loadRefBulletinData();
    }
    loadAirportData();
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_GENERALINFORMATION, {
      VendorLocationId: RootDataStore?.locationData?.locationId,
    });
    if (!useUpsert.getField('isUFN').value) {
      useUpsert.getField('endDate').set('label', 'End Date*');
    }
  }, []);

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'bulletinId',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'bulletinType',
            type: EDITOR_TYPES.DROPDOWN,
            options: noteTypes,
            isFullFlex: true,
            isDisabled: true,
          },
          {
            fieldKey: 'bulletinLevel',
            type: EDITOR_TYPES.DROPDOWN,
            options: airportLevels.filter(item => item.id === 1 || item.id === 3),
            isFullFlex: true,
            isDisabled: true,
          },
          {
            fieldKey: 'startDate',
            type: EDITOR_TYPES.DATE,
            maxDate: useUpsert.getField('endDate').value,
            isFullFlex: true,
          },
          {
            fieldKey: 'endDate',
            type: EDITOR_TYPES.DATE,
            minDate: useUpsert.getField('startDate').value,
            customErrorMessage: bulletinStore.isEndDateRequired ? 'The End Date* field is required.' : '',
            isDisabled: Boolean(useUpsert.getField('isUFN').value),
            isHalfFlex: true,
          },
          {
            fieldKey: 'isUFN',
            type: EDITOR_TYPES.CHECKBOX,
            isHalfFlex: true,
          },
          {
            fieldKey: 'notamNumber',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
          },
          {
            fieldKey: 'bulletinText',
            type: EDITOR_TYPES.TEXT_FIELD,
            multiline: true,
            isFullFlex: true,
            rows: 8,
            showExpandButton: false,
            customErrorMessage: !bulletinStore.isNotesTextExist ? 'The Notes Text* field is required.' : '',
          },
        ],
      },
    ];
  };

  const htmltoText = (html: string) => {
    let text = html;
    text = text?.replace(/\n/gi, '');
    text = text?.replace(/<style([\s\S]*?)<\/style>/gi, '');
    text = text?.replace(/<script([\s\S]*?)<\/script>/gi, '');
    text = text?.replace(/<a.*?href='(.*?)[\\?\\'].*?>(.*?)<\/a.*?>/gi, ' $2 $1 ');
    text = text?.replace(/<\/div>/gi, '\n\n');
    text = text?.replace(/<\/li>/gi, '\n');
    text = text?.replace(/<li.*?>/gi, '  *  ');
    text = text?.replace(/<\/ul>/gi, '\n\n');
    text = text?.replace(/<\/p>/gi, '\n\n');
    text = text?.replace(/<br\s*[\\/]?>/gi, '\n');
    text = text?.replace(/<[^>]+>/gi, '');
    text = text?.replace(/^\s*/gim, '');
    text = text?.replace(/ ,/gi, ',');
    text = text?.replace(/ +/gi, ' ');
    text = text?.replace(/\n+/gi, '\n\n');
    return text;
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'startDate':
        if (useUpsert.getField('isUFN').value) {
          bulletinStore.isEndDateRequired = false;
        } else if (!useUpsert.getField('isUFN').value) {
          if (useUpsert.getField('endDate').value) {
            bulletinStore.isEndDateRequired = false;
          } else {
            bulletinStore.isEndDateRequired = true;
          }
        }
        if (htmltoText(useUpsert.getField('bulletinText').value.toString())) {
          bulletinStore.isNotesTextExist = true;
        } else {
          bulletinStore.isNotesTextExist = false;
        }
        break;
      case 'isUFN':
        if (value) {
          useUpsert.getField('endDate').set('label', 'End Date');
          useUpsert.getField('endDate').set(null);
        } else {
          useUpsert.getField('endDate').set('label', 'End Date*');
        }
        if (!useUpsert.getField('endDate').value && !value) {
          bulletinStore.isEndDateRequired = true;
        } else {
          bulletinStore.isEndDateRequired = false;
        }
        break;
      case 'endDate':
        if (value) {
          if (!useUpsert.getField('isUFN').value) bulletinStore.isEndDateRequired = false;
        } else if (!useUpsert.getField('isUFN').value) {
          bulletinStore.isEndDateRequired = true;
        }
        break;
      case 'bulletinText':
        if (htmltoText(value.toString())) {
          bulletinStore.isNotesTextExist = true;
        } else {
          bulletinStore.isNotesTextExist = false;
        }
        break;
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'bulletinType':
        const filteredTypes = settingsStore.bulletinTypes?.filter(data => {
          return data.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setNoteTypes(filteredTypes);
        break;
      case 'bulletinLevel':
        const filteredLevels = noteLevels?.filter(data => {
          return data.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setAirportLevels(filteredLevels);
        break;
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'startDate':
        if (useUpsert.getField('isUFN').value) {
          if (!useUpsert.getField('isUFN').value) bulletinStore.isEndDateRequired = false;
        } else if (!useUpsert.getField('isUFN').value) {
          const endDateValue = useUpsert.getField('endDate').value;
          if (!endDateValue) {
            bulletinStore.isEndDateRequired = true;
          } else {
            bulletinStore.isEndDateRequired = false;
          }
        }
        if (htmltoText(useUpsert.getField('bulletinText').value.toString())) {
          bulletinStore.isNotesTextExist = true;
        } else {
          bulletinStore.isNotesTextExist = false;
        }
        break;
      case 'isUFN':
        if (useUpsert.getField(fieldKey).value) {
          useUpsert.getField('endDate').set('label', 'End Date');
        } else {
          useUpsert.getField('endDate').set('label', 'End Date*');
        }
        if (!useUpsert.getField('endDate').value && !useUpsert.getField(fieldKey).value) {
          bulletinStore.isEndDateRequired = true;
        } else {
          bulletinStore.isEndDateRequired = false;
        }
        break;
      case 'endDate':
        if (useUpsert.getField(fieldKey).value) {
          if (!useUpsert.getField('isUFN').value) bulletinStore.isEndDateRequired = false;
        } else if (!useUpsert.getField('isUFN').value) {
          bulletinStore.isEndDateRequired = true;
        }
        break;
      case 'bulletinText':
        if (htmltoText(useUpsert.getField(fieldKey).value?.toString())) {
          bulletinStore.isNotesTextExist = true;
        } else {
          bulletinStore.isNotesTextExist = false;
        }
        break;
      default:
        break;
    }
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    bulletinStore
      .getLocationBulletin(item?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: HandlerBulletinsModel) => {
        useUpsert.setFormValues(HandlerBulletinsModel.deserialize(response));
        useUpsert.getField('bulletinType').set(response.bulletinType);
        if (response.isUFN) {
          bulletinStore.isEndDateRequired = false;
          useUpsert.getField('endDate').set('label', 'End Date');
        } else {
          if (!useUpsert.getField('endDate').value) {
            bulletinStore.isEndDateRequired = true;
          } else {
            bulletinStore.isEndDateRequired = false;
          }
          useUpsert.getField('endDate').set('label', 'End Date*');
        }
        if (response.bulletinText) {
          bulletinStore.isNotesTextExist = true;
        } else {
          bulletinStore.isNotesTextExist = false;
        }
      });
  };

  const loadRefBulletinData = () => {
    UIStore.setPageLoader(true);
    const refDataRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([{ propertyName: 'BulletinId', propertyValue: item?.id }]),
    };
    bulletinStore
      .getrefDataAllBulletins(NO_SQL_COLLECTIONS.AIRPORT_BULLETIN, refDataRequest)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe(response => {
        const refResults = RefDataBulletins.deserialize(response.results[0]);
        useUpsert.setFormValues(refResults);
        useUpsert.getField('bulletinType').set(refResults?.bulletinType);
        if (refResults?.isUFN) {
          bulletinStore.isEndDateRequired = false;
          useUpsert.getField('endDate').set('label', 'End Date');
        } else {
          if (!useUpsert.getField('endDate').value) {
            bulletinStore.isEndDateRequired = true;
          } else {
            bulletinStore.isEndDateRequired = false;
          }
          useUpsert.getField('endDate').set('label', 'End Date*');
        }
        if (refResults?.bulletinText) {
          bulletinStore.isNotesTextExist = true;
        } else {
          bulletinStore.isNotesTextExist = false;
        }
      });
  };

  const loadAirportData = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportId',
          propertyValue: RootDataStore.locationData.airportReference.id,
        },
      ]),
    };
    vendorLocationStore
      .getVmsIcaoCode(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe();
  };

  const loadLocationAddress = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
        },
      ]),
    };
    bulletinStore
      .getVMSComparison(COLLECTION_NAMES.VENDOR_LOCATION_ADDRESS, request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe();
  };
  const headerTitle = () => {
    if (viewMode.toUpperCase() === VIEW_MODE.NEW) return 'Add New Note';
    else if (viewMode.toUpperCase() === VIEW_MODE.EDIT) return 'Edit Note';
    return 'Note Details';
  };

  const updateViewMode = () => {
    useUpsert.setViewMode(VIEW_MODE.EDIT);
    navigate(`/vendor/location/airport/notes/edit/${item?.id}`);
  };

  const isButtonDisable = () => {
    return (
      !formRef.changed ||
      !formRef.isValid ||
      formRef.hasError ||
      bulletinStore.isEndDateRequired ||
      !bulletinStore.isNotesTextExist ||
      isSubmitting
    );
  };

  const headerActions = (): ReactNode => {
    return (
      <Box className={classes.subHeader}>
        <IconButton aria-label="Close" onClick={() => setOpen(false)}>
          <ChevronRightIcon size="large" />
        </IconButton>
        <Typography variant="h5" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {headerTitle()}
        </Typography>
      </Box>
    );
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const upsertPPRBulletin = (): void => {
    setIsSubmitting(true);
    UIStore.setPageLoader(true);
    const request = new HandlerBulletinsModel({ ...useUpsert.form.values() });
    const locationAddress = bulletinStore.locationAddress && bulletinStore.locationAddress[0];
    const airportData = RootDataStore.locationData.airportReference;
    const entityValue = VendorLocationModel.deserialize({
      id: HandlerBulletinsModel.getBulletinEntityId(true, false, request.bulletinLevel.id, locationAddress),

      code: HandlerBulletinsModel.getBulletinEntityCode(
        true,
        false,
        request.bulletinLevel.id,
        locationAddress,
        airportData
      ),

      name: HandlerBulletinsModel.getBulletinEntityName(
        true,
        false,
        request.bulletinLevel.id,
        locationAddress,
        airportData
      ),
    });
    bulletinStore
      ?.upsertVendorLocationHanlderBulletin(request.serialize(entityValue, MODEL_STATUS.ACTIVE))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          setIsSubmitting(false);
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: HandlerBulletinsModel) => {
          useUpsert.form.reset();
          setOpen(false);
          loadPPRNotesData({ pageNumber: 1 });
        },
        error: error => {
          errorHandler(error?.response?.data?.errors, request.id.toString());
        },
      });
  };

  return (
    <DetailsEditorWrapper
      headerActions={headerActions()}
      isEditMode={true}
      classes={{ headerActions: classes.headerActions }}
    >
      <div className={classes.editorWrapperContainer}>
        <ViewInputControls
          isEditable={true}
          groupInputControls={groupInputControls()}
          onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
          onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
          field={fieldKey => useUpsert.getField(fieldKey)}
          onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
          onFocus={fieldKey => onFocus(fieldKey)}
        />
      </div>
      <Box className={classes.footerActions}>
        <div className={`${classes.defaultButton}`}>
          <Button color="primary" variant="text" onClick={() => setOpen(false)} size="large">
            Close
          </Button>
        </div>
        <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            disabled={isButtonDisable()}
            onClick={upsertPPRBulletin}
          >
            Save
          </Button>
        </div>
      </Box>
    </DetailsEditorWrapper>
  );
};

export default inject(
  'bulletinStore',
  'settingsStore',
  'vendorLocationStore'
)(withStyles(styles)(observer(UpsertPPRNotes)));

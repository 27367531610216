import { observable, action } from 'mobx';
import {
  COLLECTION_NAMES,
  HandlerBulletinsModel,
  IAPIVMSVendorLocationComparison,
  RefDataWorldEvents,
  SettingBaseModel,
  VendorLocationAddressModel,
} from '../Modules/Shared';
import { IAPIGridRequest, IAPIPageResponse, tapWithAction, Utilities } from '@wings-shared/core';
import { Observable } from 'rxjs';
import { baseApiPath, HttpClient, NO_SQL_COLLECTIONS } from '@uplink/shared';
import { refDataHeaders, vendorManagementHeaders } from './Base.store';
import { RootDataStore } from '@uplink-shared/layout';
import { apiUrls } from './API.url';
import { Logger } from '@uplink-shared/security';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { WorldEvents } from '../Modules/Shared/Models/WorldEvents.model';
import { WorldEventSpecialConsiderationModel } from '../Modules/Shared/Models/WorldEventSpecialConsideration.model';

export class EventsStore {
  @observable public handlerEventsList: WorldEvents[] = [];
  @observable public eventTypeList: WorldEvents[] = [];
  @observable public eventCategoryList: SettingBaseModel[] = [];
  @observable public eventSpecialConsiderationList: WorldEventSpecialConsiderationModel[] = [];
  @observable public locationAddress: VendorLocationAddressModel[] = [];
  @observable public isEndDateRequired: boolean = false;
  @observable public isNotesTextExist: boolean = true;
  @observable public refDataWorldEventsList: RefDataWorldEvents[] = [];

  @action
  public getWorldEvents(
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<WorldEvents>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'WorldEvent',
      ...pageRequest,
    });

    return http.get<IAPIPageResponse<WorldEvents>>(`/${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,

      map(response => {
        this.handlerEventsList = WorldEvents.deserializeList(response.results);
        
        return { ...response, results: this.handlerEventsList };
      })
    );
  }

  @action
  public getVMSComparison(
    collectionName: COLLECTION_NAMES,
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<VendorLocationAddressModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: collectionName,
      ...pageRequest,
    });

    return http.get<IAPIPageResponse<IAPIVMSVendorLocationComparison>>(`${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,

      map(response => {
        this.locationAddress = VendorLocationAddressModel.deserializeList(response.results);
        this.locationAddress =
          this.locationAddress &&
          this.locationAddress.filter(item => {
            return item.addressType.id === 5;
          });
        return { ...response, results: this.locationAddress };
      })
    );
  }

  @action
  public getRefDataAllWorldEvents(
    COLLECTION_NAMES: NO_SQL_COLLECTIONS,
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<RefDataWorldEvents>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.noSqlData,
      headers: refDataHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: COLLECTION_NAMES,
      ...pageRequest,
    });
  
    return http.get<IAPIPageResponse<RefDataWorldEvents>>(`/${apiUrls.refData}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => {
        this.refDataWorldEventsList = RefDataWorldEvents.deserializeListToVMSModel(response.results);
        return { ...response, results: this.refDataWorldEventsList };
      })
    );
  }

  @action
  public getVendorLocationEvent(id: number): Observable<IAPIPageResponse<WorldEvents>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http.get<IAPIPageResponse<WorldEvents>>(`/${apiUrls.worldEventReference}/${id}`).pipe(
      Logger.observableCatchError,
      map(response => WorldEvents.deserialize(response))
    );
  }

  @action
  public upsertVendorLocationHanlderEvents(payload: WorldEvents): Observable<WorldEvents> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const upsertRequest: Observable<WorldEvents> = http.post<any>(
      `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.worldEventReference}/upsert`,
      payload
    );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => {
        if (payload.statusId === 1) {
          AlertStore.info('Event saved successfully!');
        } else {
          AlertStore.info('Event deleted successfully!');
        }
      }),
      map(response => WorldEvents.deserialize(response))
    );
  }

  @action
  public getReffDataEventType(
    collectionName: string,
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<WorldEvents>> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.noSqlData, headers: refDataHeaders });
    const params: string = Utilities.buildParamString({
      CollectionName: collectionName,
      ...pageRequest,
    });
    return http.get<IAPIPageResponse<WorldEvents>>(`/${apiUrls.refData}?${params}`).pipe(
      tapWithAction(response => {
        if (collectionName === 'WorldEventType') {
          this.eventTypeList = response.results.map((item, index) =>
            SettingBaseModel.deserialize({
              id: item.worldEventTypeId,
              name: item.name,
            })
          );
        } else if (collectionName === 'WorldEventCategory') {
          this.eventCategoryList = response.results.map((item, index) =>
            SettingBaseModel.deserialize({
              ...item,
              id: item.worldEventCategoryId,
            })
          );
        } else {
          this.eventSpecialConsiderationList = response.results.map((item, index) =>
            WorldEventSpecialConsiderationModel.deserialize({
              ...item,
              id: item.worldEventSpecialConsiderationId,
            })
          );
        }
      }),
      map(response => WorldEvents.deserialize(response))
    );
  }

  
}

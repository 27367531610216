import React, { FC, useEffect, useState } from 'react';
import { useUnsubscribe } from '@wings-shared/hooks';
import { CityModel, CountryModel, useBaseUpsertComponent } from '@uplink/shared';
import { IAPISearchFiltersDictionary, IClasses, UIStore } from '@wings-shared/core';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { SettingsStore, SlideOneAndTwoStore, VendorLocationStore } from '../../../../Stores';
import { SETTING_ID, VendorOnBoardSlideOneNTwoModel, newVendorOnboarding } from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthStore } from '@uplink-shared/security';
import { fields } from './Fields';
import { styles } from './Slide4.styles';

interface Props {
  classes?: IClasses;
  settingsStore: SettingsStore;
  slideOneAndTwoStore: SlideOneAndTwoStore;
  searchFilters: IAPISearchFiltersDictionary;
  registerSaveData: (saveData: () => void) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<number>;
  vendorLocationStore: VendorLocationStore;
  onNextButtonDisable: (boolean) => void;
}

const Slide4: FC<Props> = ({
  classes,
  registerSaveData,
  settingsStore,
  searchFilters,
  slideOneAndTwoStore,
  activeStep,
  setActiveStep,
  vendorLocationStore,
  onNextButtonDisable,
}) => {
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<VendorOnBoardSlideOneNTwoModel>({}, fields, searchFilters);
  const [ selectedAnswer, setSelectedAnswer ] = useState([]);
  const [ answersByStep, setAnswersByStep ] = useState({});

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, [ slideOneAndTwoStore.activeStep, answersByStep ]);

  const saveData = () => {
    upsertSlideFour();
  };

  useEffect(() => {
    const isButtonDisable = () => {
      if (slideOneAndTwoStore.activeStep === 3) {
        return !useUpsert.getField('slide4Answer').value;
      } else if (slideOneAndTwoStore.activeStep === 4) {
        return answersByStep[4]?.length === 0;
      } else if (slideOneAndTwoStore.activeStep === 5) {
        return answersByStep[5]?.length === 0;
      }
      return false;
    };
    onNextButtonDisable(isButtonDisable() || UIStore.pageLoading);
  }, [ useUpsert.form.values(), slideOneAndTwoStore.activeStep, answersByStep ]);

  useEffect(() => {
    SidebarStore.setNavLinks(newVendorOnboarding(), 'vendor', 'Vendor Locations', '/vendor/locations');
    settingsStore.getSettings(SETTING_ID.SETTINGS_OPERATON_TYPE).subscribe();
    if (AuthStore.vendorProfile?.id) {
      loadInitialData();
    }
  }, [ activeStep, slideOneAndTwoStore.activeStep ]);

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };
  const upsertSlideFour = () => {
    UIStore.setPageLoader(true);
    const currentSelectedAnswers = answersByStep[4] || [];
    const currentSelectedSlide6Answer = answersByStep[5] || [];

    const request = new VendorOnBoardSlideOneNTwoModel({
      ...useUpsert.form.values(),
      slide5Answer: currentSelectedAnswers,
      slide6Answer: currentSelectedSlide6Answer,
    });
    const operationTypeId = vendorLocationStore.operationTypeId;

    slideOneAndTwoStore
      .upsertSlideFouFiveSixQuestion(request.serialize(operationTypeId), slideOneAndTwoStore.activeStep + 1)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorOnBoardSlideOneNTwoModel) => {
          RootDataStore.setLocation(
            RootDataStore.locationData.locationId ? true : false,
            0,
            response.locationName,
            AuthStore.vendorProfile.code,
            response.airportReference || RootDataStore.locationData.airportReference,
            response.locationUniqueCode,
            RootDataStore.locationData.airportDataManagement,
            RootDataStore.locationData.countryDataManagement,
            RootDataStore.locationData.permitDataManagement,
            RootDataStore.locationData.hasMultipleVendor
          );
          if (slideOneAndTwoStore.activeStep === 3) {
            slideOneAndTwoStore.activeStep = 4;
            setActiveStep(4);
          } else if (slideOneAndTwoStore.activeStep === 4) {
            slideOneAndTwoStore.activeStep = 5;
            setActiveStep(5);
          } else if (slideOneAndTwoStore.activeStep === 5) {
            slideOneAndTwoStore.activeStep = 6;
            setActiveStep(6);
          }
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error.message, request.id.toString());
        },
      });
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    slideOneAndTwoStore
      .getByVendorId(AuthStore.vendorProfile.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: VendorOnBoardSlideOneNTwoModel) => {
        if (response[0]) {
          const {
            slide4Answer,
            slide5Answer,
            slide6Answer,
            vendorLocationCityReference,
            locationName,
            airportReference,
            locationUniqueCode,
            operationType,
          } = response[0];
          useUpsert.setFormValues(VendorOnBoardSlideOneNTwoModel.deserialize(response[0]));
          if (vendorLocationCityReference) setFormValues(vendorLocationCityReference);
          RootDataStore.setLocation(
            !!RootDataStore.locationData.locationId,
            0,
            locationName,
            AuthStore.vendorProfile.code,
            airportReference || RootDataStore.locationData.airportReference,
            locationUniqueCode,
            RootDataStore.locationData.airportDataManagement,
            RootDataStore.locationData.countryDataManagement,
            RootDataStore.locationData.permitDataManagement,
            RootDataStore.locationData.hasMultipleVendor
          );
          vendorLocationStore.operationTypeId = operationType?.id || 0;

          const activeStep = slideOneAndTwoStore.activeStep;
          if (activeStep === 3) setSelectedAnswer(slide4Answer);
          else if (activeStep === 4 || activeStep === 5) {
            const slideAnswer = activeStep === 4 ? slide5Answer : slide6Answer;
            const processedAnswer = slideAnswer
              ?.split(',')
              .map(item => item.trim())
              .filter(Boolean);
            setAnswersByStep(prev => ({
              ...prev,
              [activeStep]: processedAnswer,
            }));
          }
        }
      });
  };

  const setFormValues = vendorAddress => {
    const countryModel = new CountryModel({
      id: vendorAddress?.countryReference.countryId,
      commonName: vendorAddress?.countryReference?.name,
      isO2Code: vendorAddress?.countryReference?.code,
    });
    const cityModel = new CityModel({
      id: vendorAddress?.cityReference?.cityId,
      cappsCode: vendorAddress?.cityReference?.code,
      commonName: vendorAddress?.cityReference?.name,
    });
    useUpsert.getField('hqAddressCountry').set(countryModel);
    useUpsert.getField('hqAddressCity').set(cityModel);
  };

  const handleChange = e => {
    const value = e.target.value;
    setSelectedAnswer(value);
    activeStep === 3 ? useUpsert.getField('slide4Answer').set(value) : null;
  };

  const handleCheckboxChange = (value: string) => {
    setAnswersByStep(prevAnswers => ({
      ...prevAnswers,
      [activeStep]: prevAnswers[activeStep]?.includes(value)
        ? prevAnswers[activeStep].filter(item => item !== value)
        : [ ...(prevAnswers[activeStep] || []), value ],
    }));
  };

  const selectedAnswers = answersByStep[activeStep] || [];
  const isNoneSelected = selectedAnswers.includes('None');

  return (
    <>
      <div className={classes.editorWrapperContainer}>
        <Box className={classes.container}>
          <Typography gutterBottom className={classes.headerText}>
            {activeStep === 3
              ? `If an employee is dispatched to supervise/handle aircraft operations, do you charge a fee for the employee's
            travel expenses?`
              : activeStep === 4
                ? 'Can your company Provide Permit Services for any of the following?'
                : 'Can you extend credit (Direct Bill/Invoice) for any of the following services?'}
          </Typography>
          {activeStep === 3 ? (
            <RadioGroup  className={classes.answerText} value={selectedAnswer} onChange={handleChange}>
              <FormControlLabel
                value="Yes"
                control={<Radio className={classes.radio} />}
                label="Yes"
                name="slide4Answer"
              />
              <FormControlLabel
                value="No"
                control={<Radio className={classes.radio} />}
                label="No"
                name="slide4Answer"
              />
              <FormControlLabel
                value="Not Applicable"
                control={<Radio className={classes.radio} />}
                label="Not Applicable"
                name="slide4Answer"
              />
            </RadioGroup>
          ) : (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedAnswers.includes(activeStep === 4 ? 'Overflight Permits' : 'Handling Fees')}
                    onChange={() => handleCheckboxChange(activeStep === 4 ? 'Overflight Permits' : 'Handling Fees')}
                    value={activeStep === 4 ? 'Overflight Permits' : 'Handling Fees'}
                    disabled={isNoneSelected}
                  />
                }
                label={activeStep === 4 ? 'Overflight Permits' : 'Handling Fees'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedAnswers.includes(activeStep === 4 ? 'Landing Permits' : 'Airport Fees')}
                    onChange={() => handleCheckboxChange(activeStep === 4 ? 'Landing Permits' : 'Airport Fees')}
                    value={activeStep === 4 ? 'Landing Permits' : 'Airport Fees'}
                    disabled={isNoneSelected}
                  />
                }
                label={activeStep === 4 ? 'Landing Permits' : 'Airport Fees'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedAnswers.includes('None')}
                    onChange={() => handleCheckboxChange('None')}
                    value="None"
                    disabled={selectedAnswers.length > 0 && !selectedAnswers.includes('None')}
                  />
                }
                label="None"
              />
            </FormGroup>
          )}
        </Box>
      </div>
    </>
  );
};

export default inject(
  'settingsStore',
  'slideOneAndTwoStore',
  'vendorLocationStore'
)(withStyles(styles)(observer(Slide4)));

import { createStyles, lighten, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  editorWrapperContainer: {
    '& .MuiFormControlLabel-root':{
      paddingBottom:'8px',
    },
    '& .checkbox-wrapper-header': {
      display: 'flex',
      alignItems: 'center'
    },
    '& .no-text, & .yes-text': {
      fontSize: '14px',
      margin: '0 10px',
      color: '#000'
    },
    '& [class*="MuiFormLabel-root"]': {
      fontSize: '12px'
    },
    '& [class*="MuiRadio-root"]':{
      marginTop:'6px',
      marginBottom:'6px',
    },
    
    '& .checkbox-wrapper-header .MuiFormControlLabel-label':{
      fontSize:'18px',
      fontWeight:'600',
      lineHeight:'1.2'
    },
  },
  headerActions: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    '& button': {
      width: '150px',
      marginRight: theme.spacing(1),
    },
    '& h5': {
      fontSize: '1.625rem',
    },
    '& .MuiTypography-h6': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      whiteSpace: 'nowrap',
      fontSize: '1.625rem',
    },
  },
  customButton: {
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      width: '100%',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  defaultButton: {
    marginLeft: '10px',
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      width: '140px',
      textTransform: 'capitalize',
      marginRight:'0'
    },
  },
  primaryButton: {
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
    },
    '& :hover':{
      boxShadow:'none',
      backgroundColor:'#63A4FF',
    }
  },
  heading:{
    fontWeight: 600,
    fontSize: '14px'
  },
  toggle: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10px',
    '& p': {
      fontSize: '14px',
      fontWeight: 'regular',
    },
  },
}));

import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  customHeight: {
    height: 'calc(100vh - 290px)',
  },
  editorWrapperContainer: {
    boxShadow:'0px 2px 3px rgba(0,0,0,.2)',
    borderRadius:'4px',
    '& .MuiTabPanel-root':{
      padding: '0px !important'
    }
  },
  headerActions: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    '& button': {
      width: '150px',
      marginRight: theme.spacing(1),
    },
    '& h5': {
      fontSize: '1.625rem',
    },
    '& .MuiTypography-h6': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      whiteSpace: 'nowrap',
      fontSize: '1.625rem',
    },
  },
  defaultButton: {
    marginLeft: '10px',
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      width: '140px',
      textTransform: 'capitalize',
      color: '#1976D2',
      marginRight:'0'
    },
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
    },
    '& button:hover': {
      background: '#63A4FF'
    }
  },
  innerAccordian:{
    marginTop:'16px'
  },
}));

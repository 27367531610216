import { createStyles, Theme } from '@material-ui/core';
import { spacing } from 'material-ui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    customHeight: {
      height: 'calc(100vh - 250px)',
    },
    buttonContainer:{
      '& button:first-child':{
        marginRight: theme.spacing(2)
      },
    },
    root:{
      color: '#1976D2'
    },
    vendorUserConfirmationModal:{
      '& [class*="MuiDialogActions-root"]':{
        justifyContent:'center'
      },
      '& .yesButton:hover':{
        backgroundColor:'#63A4FF'
      }
    },
    userRoleDialogModal:{
      padding:theme.spacing(3),
      '& [class*="MuiDialogContent-root"]':{
        textAlign:'left',
        padding:'0',
        fontSize:'14px',
        lineHeight:'28px',
        fontWeight:'400',
        color:'#202020'
      },

      '& [class*="MuiTypography-root"]':{
        justifyContent:'space-between',
        display:'flex',
        alignItems:'center',
        fontWeight:'700',
        fontSize:'1rem',
        lineHeight:'2.25rem',
        '& [class*="MuiIconButton-root"]':{
          color:'#7C7C7C',
          width: '15px',
          height: '15px',
          '& span:hover':{ 
            color:'#7C7C7C',
          }
        }
      },
    },
    
  });
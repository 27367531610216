import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { CountryModel } from '@uplink/shared';
import { SettingBaseModel } from './SettingBase.model';
import { VendorLocationModel } from './VendorLocation.model';

@modelProtection
export class AoeDesignationOptionModel extends CoreModel implements ISelectOption {
  id: number = 0;
  customsLocationInformation: SettingBaseModel = new SettingBaseModel();
  vendorLocation: VendorLocationModel = new VendorLocationModel();
  maxPOBOption: SettingBaseModel = new SettingBaseModel();

  constructor(data?: Partial<AoeDesignationOptionModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: AoeDesignationOptionModel): AoeDesignationOptionModel {
    if (!apiData) {
      return new AoeDesignationOptionModel();
    }
    const data: Partial<AoeDesignationOptionModel> = {
      ...apiData,
      customsLocationInformation: SettingBaseModel.deserialize({
        id: apiData.customsLocationInformationId,
        name: apiData.customsLocationInformationName
      }),
      vendorLocation: VendorLocationModel.deserialize({
        vendorLocationId: apiData.vendorLocationId,
        name: apiData.vendorLocationName,
        code: apiData.vendorLocationCode
      }),
      maxPOBOption: SettingBaseModel.deserialize({
        id: apiData.maxPOBOptionId || apiData.id,
        name: apiData.maxPOBOptionName || apiData.name
      })
    };
    return new AoeDesignationOptionModel(data);
  }

  static deserializeList(apiDataList: AoeDesignationOptionModel[]): AoeDesignationOptionModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => AoeDesignationOptionModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    if (this.maxPOBOption.label) return this.maxPOBOption.label;
    if (this.customsLocationInformation?.label) return this.customsLocationInformation.label;
    if (this.vendorLocation.label) return this.vendorLocation.label;
    return '';
  }

  public get value(): string | number {
    if (this.maxPOBOption?.value) return this.maxPOBOption.value;
    if (this.customsLocationInformation?.value) return this.customsLocationInformation.value;
    if (this.vendorLocation?.value) return this.vendorLocation.value;
    return '';
  }
}

import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    customHeight: {
      height: 'calc(100vh - 250px)',
    },
    buttonContainer:{
      display: 'flex',
      // '& button:first-child':{
      //   marginRight: '10px'

      // },
    },
    defaultButton: {
      marginLeft:'13px',
      '& button':{
        border: '1px solid #1976D2',
        padding:'4px 10px',
        height: '40px',
        borderRadius: '4px',
        fontSize:'14px',
        fontWeight: '400',
        minWidth:'120px',
        textTransform:'capitalize',
        color:'#1976D2'
      }
    },
    root:{
      color: '#1976D2'
    }
  });
import React, { FC, useEffect, ReactNode, useState } from 'react';
import { ColDef, GridOptions, ICellEditor, RowNode, CellClickedEvent, ColGroupDef } from 'ag-grid-community';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  HandlingFeeModel,
  SETTING_ID,
  ServiceItemPricingModel,
  SettingBaseModel,
  VendorLocationModel,
  VendorManagmentModel,
  VENDOR_PRICING_COMPARISON_FILTERS,
  COLLECTION_NAMES,
  CustomDialog,
  CustomInputDialog,
  SidebarMenuOperationalInfo,
  CustomButton,
  GridColumnManager,
} from '../../../Shared';
import {
  UIStore,
  Utilities,
  regex,
  GridPagination,
  IClasses,
  IAPIGridRequest,
  GRID_ACTIONS,
  cellStyle,
  IAPIPageResponse,
} from '@wings-shared/core';
import { BaseStore, ServiceItemPricingStore, SettingsStore, VendorLocationStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { forkJoin } from 'rxjs';
import { ConfirmNavigate, DetailsEditorWrapper, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import {
  AgGridCellEditor,
  CustomAgGridReact,
  useGridFilters,
  useAgGrid,
  AgGridActions,
  AgGridGroupHeader,
  AgGridAutoComplete,
  AgGridCheckBox,
  AgGridDateTimePicker,
  useGridState,
  AgGridViewRenderer,
} from '@uplink-shared/custom-ag-grid';
import { styles } from './GroundServiceEquipment.styles';
import { gridFilters } from './Fields';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { MixPanelTrackingEvents } from '@uplink/shared';
import { Box, Button, Tooltip, Typography, withStyles } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

interface Props {
  serviceItemPricingStore: ServiceItemPricingStore;
  settingsStore: SettingsStore;
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
}

const GroundServiceEquipment: FC<Props> = ({
  serviceItemPricingStore,
  settingsStore,
  classes,
  vendorLocationStore,
}) => {
  const gridState = useGridState();
  const agGrid = useAgGrid<VENDOR_PRICING_COMPARISON_FILTERS, ServiceItemPricingModel>(gridFilters, gridState);
  const unsubscribe = useUnsubscribe();
  const filtersApi = useGridFilters<VENDOR_PRICING_COMPARISON_FILTERS>(gridFilters, gridState);
  const [ tableColumns, setTableColumns ] = useState<any[]>([]);
  const [ selectedRowIndex, setSelectedRowIndex ] = useState<number>(null);

  useEffect(() => {
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_LOCATION_PRICING,
      { VendorLocationId:RootDataStore?.locationData?.locationId });
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
    loadInitialData();
    loadVendorLocationData();
    serviceItemPricingStore.commentValue = '';
    serviceItemPricingStore.isAnyColHidden = false;
    gridState.setIsRowEditing(false);
  }, []);

  useEffect(() => {
    if (!gridState.columnApi) return;

    setVisibleTableColumns();
  }, [ gridState.columnApi ]);

  const onInputChange = (colDef: ICellEditorParams, value): void => {
    gridState.setIsAllRowsSelected(true);
    gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
    const colId = colDef?.column?.getColId() || colDef;
    switch (colId) {
      case 'validFrom':
        const vtCol = agGrid.fetchCellInstance('validTo');
        const vfToDate = new Date(vtCol.getValue());
        const vfFromDate = new Date(value);
        if (vfFromDate > vfToDate) {
          vtCol.setValue(value);
        }
        break;
      case 'validTo':
        const vfCol = agGrid.fetchCellInstance('validFrom');
        const vtFromDate = new Date(vfCol.getValue());
        const vtToDate = new Date(value);
        if (vtFromDate >= vtToDate) {
          BaseStore.showAlert('Valid to date cannot be less than valid from date', 0);
          agGrid.fetchCellInstance('validTo')?.setValue('');
          gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
        }
        break;
      case 'comment':
        formulaComment();
        const handlingFee = fetchCellValue([ 'handlingFee' ]);
        if (handlingFee[0]?.name === 'Conditional') {
          if (value) {
            serviceItemPricingStore.isCommentFieldRequired = false;
          } else {
            serviceItemPricingStore.isCommentFieldRequired = true;
            agGrid.fetchCellInstance('comment')?.setRules('required|string|between:0,300');
          }
        }
        break;
      case 'lowerLimit':
      case 'upperLimit':
      case 'price':
        setRequiredRules();
        break;
    }
  };

  const onCheckboxChange = (key: string, value): void => {
    gridState.setIsAllRowsSelected(true);
    setRequiredRules();
    gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
  };

  const onDropDownChange = (colDef: ICellEditorParams, value): void => {
    gridState.setIsAllRowsSelected(true);
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
    if (!value) {
      return;
    }
    const colId = colDef.column.getColId();
    switch (colId) {
      case 'vendor':
        filterLocationByVendor(value);
        break;
      case 'handlingFee':
        setRequiredRules();
        break;
    }
  };

  const filterLocationByVendor = (vendor?: VendorManagmentModel) => {
    const filter = vendor?.id
      ? JSON.stringify([
        {
          propertyName: 'Vendor.Id',
          propertyValue: vendor?.id,
        },
      ])
      : '';

    const request: IAPIGridRequest = {
      filterCollection: filter,
    };
    vendorLocationStore.getVMSComparison(request).subscribe();
  };

  const formulaComment = () => {
    if (gridState.isRowEditing) {
      const formulaComment = agGrid?.fetchCellInstance('comment')?.getValue();
      serviceItemPricingStore.commentValue = formulaComment;
      ModalStore.open(
        <CustomInputDialog
          title="Enter here any Formula/comments:"
          yesButton="Save"
          noButton="Cancel"
          onNoClick={() => {
            agGrid.fetchCellInstance('comment')?.setValue(formulaComment);
            ModalStore.close();
          }}
          onYesClick={() => {
            agGrid.fetchCellInstance('comment')?.setValue(serviceItemPricingStore.commentValue);
            const handlingFee = fetchCellValue([ 'handlingFee' ]);
            if (handlingFee[0]?.name === 'Conditional') {
              if (serviceItemPricingStore.commentValue) {
                serviceItemPricingStore.isCommentFieldRequired = false;
              } else {
                serviceItemPricingStore.isCommentFieldRequired = true;
                agGrid.fetchCellInstance('comment')?.setRules('required|string|between:0,300');
              }
            }
            gridState.setHasError(false);
            setRequiredRules();
          }}
          serviceItemPricingStore={serviceItemPricingStore}
        />
      );
    }
  };

  const onSelectionChanged = $event => {
    const selectedNodes = gridState.gridApi.getSelectedNodes();
    gridState.gridApi.forEachNode(node => {
      if (node.data.id !== 0) {
        node.setSelected(true);
      } else {
        node.setSelected(false);
      }
    });
    if (selectedNodes[0]?.data?.id == 0) {
      gridState.columnApi.setColumnGroupOpened('serviceItemGroup', true);
      gridState.gridApi.startEditingCell({ rowIndex: selectedNodes[0]?.rowIndex, colKey: 'actionRenderer' });
      setSelectedRowIndex(selectedNodes[0]?.rowIndex);
      return;
    }
    if (selectedNodes.length > 0 && selectedNodes[0]?.data?.id !== 0) {
      getDeleteConfirmation();
    }
  };

  const columnDefs: ColDef[] | ColGroupDef[] = [
    {
      groupId: 'serviceItemGroup',
      hide: true,
      children: [
        {
          headerName: 'Service Name',
          minWidth: 200,
          field: 'serviceItem',
          cellEditor: 'customAutoComplete',
          headerComponent: 'customHeader',
          checkboxSelection: true,
          valueFormatter: ({ value }) => value?.name || '',
          comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
          headerTooltip: 'Service Name',
          cellEditorParams: {
            isRequired: true,
            placeHolder: 'Service Name',
            getAutoCompleteOptions: () => settingsStore.vendorSettingsServiceItemName,
          },
          hide: false,
          unSortIcon: true,
        },
        {
          headerName: 'Commissionable',
          minWidth: 200,
          groupId: 'isCommissionable',
          field: 'isCommissionable',
          columnGroupShow: 'open',
          cellRenderer: 'checkBoxRenderer',
          cellEditor: 'checkBoxRenderer',
          headerTooltip: 'Commissionable',
          cellRendererParams: { readOnly: true },
          hide: false,
          unSortIcon: true,
          suppressMenu: true,
        },
        {
          headerName: 'Direct Service',
          minWidth: 200,
          columnGroupShow: 'open',
          field: 'isDirectService',
          cellRenderer: 'checkBoxRenderer',
          cellEditor: 'checkBoxRenderer',
          headerTooltip: 'Direct Service',
          cellRendererParams: {
            readOnly: true,
          },
          cellEditorParams: {
            onChange: (a: any, b: any) => {
              serviceItemPricingStore.isDirectService = b;
              onCheckboxChange('isDirectService', b);
            },
          },
          hide: false,
          unSortIcon: true,
          suppressMenu: true,
        },
        {
          headerName: '3rd Party Vendor',
          minWidth: 200,
          field: 'thirdPartyVendorComment',
          headerTooltip: '3rd Party Vendor',
          columnGroupShow: 'open',
          cellEditorParams: {
            placeHolder: 'thirdPartyVendorComment',
            ignoreNumber: true,
            getDisableState: (node: RowNode) => serviceItemPricingStore.isDirectService,
            rules: 'string|between:0,100',
          },
          hide: false,
          unSortIcon: true,
          suppressMenu: true,
        },
        {
          headerName: 'Variable Price',
          minWidth: 200,
          field: 'isVariablePricing',
          cellRenderer: 'checkBoxRenderer',
          cellEditor: 'checkBoxRenderer',
          headerTooltip: 'Variable Price',
          columnGroupShow: 'open',
          cellRendererParams: {
            readOnly: true,
          },
          cellEditorParams: {
            onChange: (a: any, b: any) => onCheckboxChange('isVariablePricing', b),
          },
          hide: false,
          unSortIcon: true,
          suppressMenu: true,
        },
        {
          headerName: 'Included In Handling Fees',
          minWidth: 250,
          field: 'handlingFee',
          cellEditor: 'customAutoComplete',
          columnGroupShow: 'open',
          valueFormatter: ({ value }) => value?.name || '',
          comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
          headerTooltip: 'Included In Handling Fees',
          cellEditorParams: {
            isRequired: true,
            placeHolder: 'handlingFee',
            getAutoCompleteOptions: () => settingsStore.vendorSettingsHandlingFee,
          },
          hide: false,
          unSortIcon: true,
          suppressMenu: true,
        },
        {
          headerName: 'Price Unavailable',
          minWidth: 200,
          field: 'priceDataUnavailable',
          cellRenderer: 'checkBoxRenderer',
          cellEditor: 'checkBoxRenderer',
          columnGroupShow: 'open',
          headerTooltip: 'Price Unavailable',
          cellRendererParams: {
            readOnly: true,
          },
          cellEditorParams: {
            onChange: (a: any, b: any) => onCheckboxChange('priceDataUnavailable', b),
          },
          hide: false,
          unSortIcon: true,
          suppressMenu: true,
        },
      ],
    },
    {
      headerName: 'Parameter',
      minWidth: 130,
      field: 'parameter',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      headerTooltip: 'Parameter',
      cellEditorParams: {
        isRequired: (node: Node) => getValidationConditions(),
        placeHolder: 'Parameter',
        getAutoCompleteOptions: () => settingsStore.vendorSettingsParameters,
      },
      hide: false,
      unSortIcon: true,
      suppressMenu: true,
    },
    {
      headerName: 'Lower Limit',
      minWidth: 140,
      field: 'lowerLimit',
      headerTooltip: 'Lower Limit',
      cellEditorParams: {
        placeHolder: 'lowerLimit',
        ignoreNumber: true,
        rules: `numeric|between:0,9999999.99|regex:${regex.numberWithTwoDecimal}`,
        isRequired: (node: Node) => getPricingValidations(),
      },
      hide: false,
      unSortIcon: true,
      suppressMenu: true,
    },
    {
      headerName: 'Upper Limit',
      minWidth: 140,
      field: 'upperLimit',
      headerTooltip: 'Upper Limit',
      cellEditorParams: {
        placeHolder: 'upperLimit',
        ignoreNumber: true,
        rules: `numeric|between:0,9999999.99|regex:${regex.numberWithTwoDecimal}`,
        isRequired: (node: Node) => getPricingValidations(),
      },
      hide: false,
      unSortIcon: true,
      suppressMenu: true,
    },
    {
      headerName: 'Price',
      minWidth: 100,
      field: 'price',
      headerTooltip: 'Price',
      cellEditorParams: {
        placeHolder: 'price',
        ignoreNumber: true,
        rules: `numeric|between:0,99999.99|regex:${regex.numberWithTwoDecimal}`,
        isRequired: (node: Node) => getPricingValidations(),
      },
      hide: false,
      unSortIcon: true,
      suppressMenu: true,
    },
    {
      headerName: 'Currency',
      minWidth: 120,
      field: 'currency',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      headerTooltip: 'Currency',
      cellEditorParams: {
        placeHolder: 'Currency',
        getAutoCompleteOptions: () => settingsStore.vendorSettingsCurrency,
        isRequired: (node: Node) => getValidationConditions(),
      },
      hide: false,
      unSortIcon: true,
      suppressMenu: true,
    },
    {
      headerName: 'Units',
      minWidth: 110,
      field: 'uom',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      headerTooltip: 'Units',
      cellEditorParams: {
        placeHolder: 'Units',
        getAutoCompleteOptions: () => settingsStore.vendorSettingsUnits,
        isRequired: (node: Node) => getValidationConditions(),
      },
      hide: false,
      unSortIcon: true,
      suppressMenu: true,
    },
    {
      headerName: 'Formula / Comments',
      minWidth: 200,
      field: 'comment',
      headerTooltip: 'Formula / Comments',
      cellEditorParams: {
        placeHolder: 'formulaComments',
        ignoreNumber: true,
        rules: 'string|between:0,300',
        isRequired: () => serviceItemPricingStore.isCommentFieldRequired,
      },
      onCellClicked: (event: CellClickedEvent) => formulaComment(),
      hide: false,
      unSortIcon: true,
      suppressMenu: true,
    },
    {
      field: 'actionRenderer',
      suppressNavigable: true,
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      suppressMenu: true,
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressSizeToFit: true,
      minWidth: 80,
      maxWidth: 100,
      cellStyle: { ...cellStyle() },
    },
  ];

  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: { onInputChange, onDropDownChange },
      columnDefs,
      isEditable: true,
      gridActionProps: {
        isActionMenu: false,
        dotActionMenu: true,
        showDeleteButton: false,
        getEditableState: ({ data }: RowNode) => {
          return Boolean(data.id);
        },
        actionMenus: ({ data }: RowNode) => [
          {
            title: 'Edit',
            isHidden: false,
            action: GRID_ACTIONS.EDIT,
          },
        ],
        getDisabledState: () => gridState.hasError || serviceItemPricingStore.isCommentFieldRequired,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => {
          switch (action) {
            case GRID_ACTIONS.EDIT:
              setSelectedRowIndex(rowIndex);
              serviceItemPricingStore.commentValue = '';
              const model = agGrid._getTableItem(rowIndex);
              gridState.columnApi.setColumnGroupOpened('serviceItemGroup', true);
              gridState.gridApi.startEditingCell({ rowIndex: rowIndex, colKey: 'actionRenderer' });
              vendorLocationStore.vendorLocationList = [];
              if (!model.isDirectService) {
                serviceItemPricingStore.isDirectService = false;
              }
              break;
            case GRID_ACTIONS.SAVE:
              updateVendorPricing(rowIndex);
              break;
            case GRID_ACTIONS.CANCEL:
              getConfirmation(rowIndex);
              break;
          }
        },
      },
    });
    return {
      ...baseOptions,
      pagination: false,
      suppressClickEdit: true,
      enableGroupEdit: true,
      rowSelection: 'multiple',
      onSelectionChanged: event => onSelectionChanged(event),
      suppressRowClickSelection: true,
      isExternalFilterPresent: () => false,
      onFilterChanged: () => loadInitialData({ pageNumber: 1 }),
      onSortChanged: e => {
        filtersApi.onSortChanged(e);
        loadInitialData();
      },
      isRowSelectable: rowNode => {
        if (rowNode.data.id !== 0) {
          rowNode.setSelected(true);
        }
        return true;
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customAutoComplete: AgGridAutoComplete,
        customCellEditor: AgGridCellEditor,
        customHeader: AgGridGroupHeader,
        checkBoxRenderer: AgGridCheckBox,
        customTimeEditor: AgGridDateTimePicker,
        customDateEditor: AgGridDateTimePicker,
        actionViewRenderer: AgGridViewRenderer,
      },
    };
  };

  const removeUnSavedRow = (rowIndex: number) => {
    const data: ServiceItemPricingModel = agGrid._getTableItem(rowIndex);
    if (data?.id == 0) {
      const model = agGrid._getTableItem(rowIndex);
      const modelList = new Array(model);
      agGrid._removeTableItems(modelList);
    }
    gridState.setIsAllRowsSelected(false);
  };

  const fetchCellValue = (colList: string[]): any[] => {
    const editorInstance: ICellEditor[] = gridState.gridApi.getCellEditorInstances({
      columns: colList,
    });
    const values = colList.map((item, index) => {
      return editorInstance != undefined && editorInstance.length > 0 ? editorInstance[index]?.getValue() : false;
    });
    return values;
  };

  const setRequiredRules = (): void => {
    const cellValues = fetchCellValue([ 'isDirectService', 'handlingFee' ]);
    const hasDirectServiceValue = Boolean(cellValues[0]);
    const hasHandlingFeeValue = HandlingFeeModel.deserialize(cellValues[1]);
    const hasConditionMatched = getValidationConditions();

    const thirdPartyVendorCell = agGrid?.fetchCellInstance('thirdPartyVendorComment');
    thirdPartyVendorCell?.setRules(hasDirectServiceValue ? 'string|between:0,100' : 'required|string|between:0,100');
    thirdPartyVendorCell.setValue(hasDirectServiceValue ? '' : thirdPartyVendorCell?.getValue());

    agGrid
      .fetchCellInstance('lowerLimit')
      ?.setRules(hasConditionMatched ? `${getNumberValidation(7)}` : `${getNumberValidation(7)}`);

    agGrid
      .fetchCellInstance('upperLimit')
      ?.setRules(hasConditionMatched ? `${getNumberValidation(7)}` : `${getNumberValidation(7)}`);

    agGrid
      .fetchCellInstance('price')
      ?.setRules(hasConditionMatched ? `${getNumberValidation(5)}` : `${getNumberValidation(5)}`);

    agGrid
      .fetchCellInstance('comment')
      ?.setRules(hasHandlingFeeValue?.name == 'Conditional' ? 'required|string|between:0,300' : 'string|between:0,300');

    serviceItemPricingStore.isCommentFieldRequired = false;
    if (hasHandlingFeeValue?.name === 'Conditional') {
      const commentField = agGrid.fetchCellInstance('comment')?.getValue();
      if (!commentField) {
        serviceItemPricingStore.isCommentFieldRequired = true;
      }
    }
    gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
  };

  const getNumberValidation = (upperRangeDigitCount: number) => {
    const range = upperRangeDigitCount == 5 ? '0,99999.99' : '0,9999999.99';
    if (getPricingValidations()) {
      return `required|numeric|between:${range}|regex:${regex.numberWithTwoDecimal}`;
    }
    return `numeric|between:${range}|regex:${regex.numberWithTwoDecimal}`;
  };

  const getValidationConditions = () => {
    const cellValues = fetchCellValue([ 'isVariablePricing', 'handlingFee', 'priceDataUnavailable' ]);
    const hasVariablePricingValue = Boolean(cellValues[0]);
    const hasHandlingFeeValue = HandlingFeeModel.deserialize(cellValues[1]);
    const hasPriceDataUnavailable = Boolean(cellValues[2]);

    return (
      !hasVariablePricingValue &&
      !hasPriceDataUnavailable &&
      (hasHandlingFeeValue?.name == 'Yes + Ad hoc' ||
        hasHandlingFeeValue?.name == 'No' ||
        hasHandlingFeeValue?.name == 'Conditional')
    );
  };

  const getPricingValidations = () => {
    const cellValues = fetchCellValue([ 'isVariablePricing', 'handlingFee', 'priceDataUnavailable' ]);
    const hasVariablePricingValue = Boolean(cellValues[0]);
    const hasHandlingFeeValue = HandlingFeeModel.deserialize(cellValues[1]);
    const hasPriceDataUnavailable = Boolean(cellValues[2]);
    return (
      !hasVariablePricingValue &&
      !hasPriceDataUnavailable &&
      (hasHandlingFeeValue?.name == 'Yes + Ad hoc' ||
        hasHandlingFeeValue?.name == 'No' ||
        hasHandlingFeeValue?.name == 'Conditional')
    );
  };

  const addNewPrice = () => {
    setSelectedRowIndex(0);
    serviceItemPricingStore.isDirectService = true;
    serviceItemPricingStore.commentValue = '';
    gridState.columnApi.setColumnGroupOpened('serviceItemGroup', true);
    const data = [
      new ServiceItemPricingModel({
        handlingFee: new HandlingFeeModel({
          id: 1,
          name: 'Yes',
        }),
      }),
    ];
    agGrid.addNewItems(data, { startEditing: false, colKey: 'serviceItem' });
    gridState.gridApi.startEditingCell({ rowIndex: 0, colKey: 'actionRenderer' });
    vendorLocationStore.vendorLocationList = [];
    gridState.setHasError(true);
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => BaseStore.showAlert(errorMessage[0], id));
  };

  const updateVendorPricing = (rowIndex: number): void => {
    gridState.setIsProcessing(true);
    UIStore.setPageLoader(true);
    gridState.gridApi.stopEditing();
    const model = agGrid._getTableItem(rowIndex);
    model.vendorLocation?.length == 0;
    {
      model.vendorId = serviceItemPricingStore.vendorLocationData.vendor?.id;
      model.vendor = serviceItemPricingStore.vendorLocationData.vendor;
      model.vendorLocation.push({
        id: RootDataStore?.locationData?.locationId,
        serviceItemPricingId: model.id,
        airportReference: serviceItemPricingStore.vendorLocationData.airportReference,
        vendorLocationId: RootDataStore?.locationData?.locationId,
        vendorLocationName: serviceItemPricingStore.vendorLocationData.name,
      });
    }
    forkJoin([ serviceItemPricingStore?.upsertServiceItemPricingLocations([ model.serialize() ]) ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
          gridState.setIsAllRowsSelected(false);
        })
      )
      .subscribe({
        next: (response: [ServiceItemPricingModel[], boolean]) => {
          agGrid.filtersApi.resetColumnFilters();
          loadInitialData();
          setSelectedRowIndex(null);
          serviceItemPricingStore.commentValue = '';
        },
        error: error => {
          agGrid._startEditingCell(rowIndex, 'serviceItem');
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, model.id.toString());
            return;
          }
          BaseStore.showAlert(error.message, model.id);
        },
      });
  };

  const getConfirmation = (rowIndex): void => {
    if (gridState.isAllRowsSelected) {
      ModalStore.open(
        <CustomDialog
          title="Confirm changes"
          message={'Canceling will lost your changes. Are you sure you want to cancel?'}
          yesButton="Confirm"
          noButton="Cancel"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => {
            setSelectedRowIndex(null);
            removeUnSavedRow(rowIndex);
            cancelEditing(rowIndex);
          }}
        />
      );
    } else {
      gridState.columnApi.setColumnGroupOpened('serviceItemGroup', false);
      setSelectedRowIndex(null);
      removeUnSavedRow(rowIndex);
      cancelEditing(rowIndex);
    }
  };

  const getDeleteConfirmation = (): void => {
    ModalStore.open(
      <CustomDialog
        title="Save the changes"
        message={'Do you want to remove this service requirement?'}
        yesButton="Confirm"
        noButton="Cancel"
        onNoClick={() => {
          agGrid.filtersApi.resetColumnFilters();
          ModalStore.close();
        }}
        onYesClick={() => deleteVendorPricing()}
      />
    );
  };

  const deleteVendorPricing = (): void => {
    const selectedData = gridState.gridApi.getSelectedRows();
    UIStore.setPageLoader(true);
    gridState.setIsProcessing(true);
    serviceItemPricingStore
      .removePricing([ selectedData[0].id ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
        })
      )
      .subscribe({
        next: () => {
          loadInitialData();
          agGrid.filtersApi.resetColumnFilters();
        },
        error: error => {
          agGrid._startEditingCell(0, 'serviceItem');
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, selectedData[0].id.id.toString());
            return;
          }
          BaseStore.showAlert(error.message, selectedData[0].id.id);
        },
      });
  };

  const cancelEditing = (rowIndex: number) => {
    agGrid.cancelEditing(rowIndex);
    agGrid.filtersApi.resetColumnFilters();
    loadInitialData({ pageNumber: 1 });
    serviceItemPricingStore.isDirectService = true;
    gridState.columnApi.setColumnGroupOpened('serviceItemGroup', false);
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    setSelectedRowIndex(null);
    UIStore.setPageLoader(true);
    gridState.setIsProcessing(true);
    serviceItemPricingStore.selectedRows = false;
    const serviceItemRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
    };
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
        },
      ]),
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
    };
    forkJoin([
      serviceItemPricingStore.getVMSComparison(request),
      settingsStore.getVendorSettingsStatus(),
      settingsStore?.getSettings( SETTING_ID.SETTINGS_SERVICE_ITEM_NAME, COLLECTION_NAMES.SERVICE_COMM),
      settingsStore?.getSettings( SETTING_ID.SETTINGS_HANDLING_FEES, COLLECTION_NAMES.SERVICE_COMM),
      settingsStore?.getSettings( SETTING_ID.SETTINGS_PARAMETERS, COLLECTION_NAMES.SERVICE_COMM),
      settingsStore?.getSettings( SETTING_ID.SETTINGS_CURRENCY, COLLECTION_NAMES.SERVICE_COMM),
      settingsStore?.getSettings( SETTING_ID.SETTINGS_UNITS, COLLECTION_NAMES.SERVICE_COMM),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
        })
      )
      .subscribe((response: [IAPIPageResponse<ServiceItemPricingModel>, IAPIPageResponse<SettingBaseModel>]) => {
        UIStore.setPageLoader(false);
        const results = ServiceItemPricingModel.deserializeList(response[0].results);
        const filteredServiceItemName = settingsStore.vendorSettingsServiceItemName.filter(item => {
          return item.isPrepopulate === true;
        });
        const finalArray = filteredServiceItemName.map(item =>
          ServiceItemPricingModel.deserialize({
            serviceItem: item,
          })
        );

        const filteredFinalArray = finalArray.filter(item => {
          return !results.some(resultsItem => resultsItem.serviceItem.id === item.serviceItem.id);
        });
        const combinedResults = [ ...results, ...filteredFinalArray ];
        const totalRecords = response[0].totalNumberOfRecords + filteredFinalArray.length;

        gridState.setPagination(
          new GridPagination({
            totalNumberOfRecords: totalRecords,
            pageSize: response[0].pageSize,
          })
        );
        gridState.setGridData(combinedResults);

        const allowSelectAll = response[0].totalNumberOfRecords <= response[0].pageSize;
        gridState.setAllowSelectAll(allowSelectAll);
        agGrid.reloadColumnState();
        agGrid.refreshSelectionState();
        gridState.columnApi.setColumnGroupOpened('serviceItemGroup', false);
      });
  };

  const loadVendorLocationData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(parseInt(RootDataStore.locationData.locationId))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        serviceItemPricingStore.vendorLocationData = response;
      });
  };

  const setVisibleTableColumns = () => {
    const parentColumns = columnDefs[0]?.children
      .filter((column: any) => Boolean(column.headerName))
      .map((column: any) => ({
        label: column.headerName,
        field: column.field,
        hide: Boolean(column.hide),
      }));
    const columns = columnDefs
      .filter((column: any) => Boolean(column.headerName))
      .map((column: any) => ({
        label: column.headerName,
        field: column.field,
        hide: Boolean(column.hide),
      }));
    const mergedColumns = [ ...parentColumns, ...columns ];
    setTableColumns(mergedColumns);
  };

  const changeColumnsVisibility = (columns: any[]) => {
    setTableColumns(columns);

    if (!gridState.columnApi) {
      return;
    }

    columns.forEach(column => {
      (gridState as any).columnApi.setColumnVisible(column.field, !column.hide);
    });
    const filteredData = columns.filter(item => item.hide);
    serviceItemPricingStore.isAnyColHidden = Boolean(filteredData.length);
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          Ground Service Equipment
        </Typography>
        <Box className={classes.headerFlexStyle}>
          {gridState.isRowEditing && (
            <>
              <div className={`${classes.defaultButton}`}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => getConfirmation(selectedRowIndex)}
                  size="large"
                  // disabled={selectedRowIndex === null}
                >
                  Cancel
                </Button>
              </div>
              <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
                <Tooltip title={getSaveButtonTooltip()} placement="top" arrow open={gridState.hasError}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => updateVendorPricing(selectedRowIndex)}
                    size="large"
                    disabled={gridState.hasError || serviceItemPricingStore.isCommentFieldRequired}
                  >
                  Save
                  </Button>
                </Tooltip>
              </div>{' '}
            </>
          )}
          {!gridState.isRowEditing && (
            <div>
              <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
                {gridState.hasSelectedRows && (
                  <CustomButton
                    variant="contained"
                    startIcon={<AddRoundedIcon />}
                    title="Add Service"
                    disabled={gridState.isRowEditing || UIStore.pageLoading || gridState.hasSelectedRows}
                    onClick={() => {
                      if (!gridState.isRowEditing && !UIStore.pageLoading) addNewPrice();
                    }}
                  />
                )}
              </div>
              {!gridState.hasSelectedRows && (
                <CustomButton
                  variant="contained"
                  startIcon={<AddRoundedIcon />}
                  title="Add Service"
                  disabled={gridState.isRowEditing || UIStore.pageLoading}
                  onClick={() => {
                    if (!gridState.isRowEditing && !UIStore.pageLoading) addNewPrice();
                  }}
                />
              )}
            </div>
          )}
          <GridColumnManager
            columns={tableColumns}
            onChange={value => changeColumnsVisibility(value)}
            isRowEditing={gridState.isRowEditing}
          />
        </Box>
      </>
    );
  };
  const getSaveButtonTooltip = () => {
    if (!gridState.hasError) return '';
  
    const errorMessages = Utilities.getErrorMessages(gridState.gridApi);
    if (!errorMessages?.length) return '';
  
    return (
      <div className={classes.errorTooltip}>
        {errorMessages.map((error, index) => (
          <div key={index}>{error}</div>
        ))}
      </div>
    );
  };

  return (
    <ConfirmNavigate isBlocker={gridState.isRowEditing || gridState.isProcessing}>
      <div className={classes.pageWrapper}>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={true}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes.editorWrapperContainer}>
            <CustomAgGridReact
              isRowEditing={gridState.isRowEditing}
              rowData={gridState.data}
              gridOptions={gridOptions()}
              serverPagination={true}
              paginationData={gridState.pagination}
              onPaginationChange={loadInitialData}
              disablePagination={gridState.isRowEditing || gridState.isProcessing}
              classes={{ customHeight: classes.customHeight }}
            />
          </div>
        </DetailsEditorWrapper>
      </div>
    </ConfirmNavigate>
  );
};
export default inject(
  'serviceItemPricingStore',
  'settingsStore',
  'vendorLocationStore'
)(withStyles(styles)(observer(GroundServiceEquipment)));

import React, { FC, ReactNode, useEffect, useState } from 'react';
import { BaseStore, SettingsStore, VendorLocationPhotoStore, VendorLocationStore } from '../../../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useBaseUpsertComponent } from '@uplink/shared';
import { UIStore, IAPISearchFiltersDictionary, IOptionValue, IClasses, Loader } from '@wings-shared/core';
import { forkJoin } from 'rxjs';
import {
  AircraftPhotoUpload,
  CustomDialog,
  SETTING_ID,
  SettingBaseModel,
  SidebarMenuOperationalInfo,
  VendorLocationModel,
  VendorLocationPhoto,
} from '../../../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { ConfirmDialog, ConfirmNavigate, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import ViewInputControls from '../../../../../Shared/Components/ViewInputControls/ViewInputControls';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { MapBoxViewV1 } from '@uplink-shared/mapbox';
import LocationOn from '@material-ui/icons/LocationOn';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { PROGRESS_TYPES } from '@uvgo-shared/progress';
import { styles } from './AircraftFacilities.styles';
import { PassengerLogisticsModel } from '../../../../../Shared/Models/PassengerLogisticsModel.model';
import { PassengerLogisticsStore } from '../../../../../../Stores/PassengerLogistics.store';
import { useNavigate } from 'react-router';
import { fields } from './Fields';
import { CloseIcon, UploadIcon } from '@uvgo-shared/icons';
import HelpIcon from '@material-ui/icons/Help';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DownloadIcon from '@material-ui/icons/GetApp';
import { IAPIDownloadDocumentFile } from 'apps/vendor/src/Modules/Shared/Interfaces/Request/API-Request-VendorDocument';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  settingsStore: SettingsStore;
  vendorLocationPhotoStore: VendorLocationPhotoStore;
  passengerLogisticsStore: PassengerLogisticsStore;
  searchFilters: IAPISearchFiltersDictionary;
  onNextButtonDisable?: (boolean) => void;
  registerSaveData: (saveData: () => void) => void;
}

const CustomDialogMap = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '0px 24px 24px 24px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiBox-root': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px 0px 0px',
  },
  '& .MuiDialog-paperFullScreen': {
    width: '60% !important',
    height: '80% !important',
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AircraftFacilities: FC<Props> = ({
  settingsStore,
  vendorLocationStore,
  passengerLogisticsStore,
  vendorLocationPhotoStore,
  classes,
  searchFilters,
  onNextButtonDisable,
  registerSaveData,
}) => {
  const useUpsert = useBaseUpsertComponent<PassengerLogisticsModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();
  const [ disabilityAccommodationsList, setDisabilityAccommodationsList ] = useState([]);
  const [ arrivalCrewPaxPassportHandling, setArrivalCrewPaxPassportHandlingList ] = useState([]);
  const [ internationalDepartureProceduresList, setInternationalDepartureProcedures ] = useState([]);
  const [ luggageHandling, setLuggageHandlingList ] = useState([]);
  const [ appliedAmenities, setAppliedAmenitiesList ] = useState([]);
  const [ yesOrNoList, setYesOrNoListList ] = useState(PassengerLogisticsModel.getYesNo());
  const progressLoader: Loader = new Loader(false, { type: PROGRESS_TYPES.CIRCLE });
  const [ open, setOpen ] = useState(false);
  const list = [
    'https://s3-alpha-sig.figma.com/img/759a/dcfb/5dfc79fdf80e50ae1142440a7ae34ee7?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=VghsltJ8drMZHz5UWpj9mpKBUV8AOUfRfFNWB--uAOknthP6d2TRknFIRBa5yNtkRduCOxzxhjZ79GvoMgscq-pdlr05O~VcFTLc4C1CBa7ODTW~OZ2VSvpR8WDe0ciKjp0J1tBrtUVEI79SJgTlrFdzNTN8Cbq1hZB6323jewPs1jBrmxxp6WYdvRHNIJTpnSjU6ZZXWtGyn-MZEFoT2an-u~j7WjhGvsq-1oEh4by4AMmdn9HMtYChihbCByrzKpLuF4-~awuyKNQH4DA1KTL1Zw8SyoGJ2sbB22DHf0aXk2zT~9No7bi9oZRGyYKDL02ziNXO7Vw0KbkDth7PIA__',
    'https://s3-alpha-sig.figma.com/img/e3da/932a/371d76e6693111716ac00552e47748b0?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=ObhLrVDbp9E5s5b99PJgbUReuyajPG2Y4UKil-gtHtPKVby5zW~Gb27h6NUiVjVA60c2U6vZ9wnRM8aa0l0Fr0Tov6mOahpGwe6BHAfSlldYaTRDn0I2Khj6zHffYgmK9eXA930gaXTs4Wz3xhxNNRudPsKRl6V1wpwFgAv0fiOP237VmF9ML0ICyRPq7ET4Zhb5gQr2003~UzL2k4EzKnmvP-O~01fgyxeIlmIKWxdG46cb8ksafAABY4iTAiXwGb-ekIEQxGb6GjwLSAO5ZRU2qDoe3gZl-GLTuwTLSoQvppnG9CByy6EXgqXhoTiJuAB3CpY7n~nasnHUJo1pMA__',
    'https://s3-alpha-sig.figma.com/img/cf87/ca0e/7a0e4df6cb63fefb3b5c57739cf7b869?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=VYK4RgRS1KedrBg5SBL16l~wz4PBp4qYSosPJhnNzVu745JrRfJ321S06oGbSuVQd4p65ngNfiJVpd5aWzdHI-uuDVNMoJr~q3VAu0ufldcE0OfRwA7w7rKtBFPDml4B7EzWyggp2~oeBIITza4ExC0nmT4o4IL2uhnONR98D0k6ycsW8W2oVXsZmjxTP3GgoTwXV4ozf0m6v87IHL7eqhhyA-P5RFOcjN3K1uY1m4VN1FGlduC-N1-PXvxsDibkIPvu9hU5jrffcIxp8WjFGrZhxeK3WsJUBURBT68qaFaEGfqcTyI2zxct9-hPOSxcRXACTTvO2dVA4YM7SCInqw__',
    'https://s3-alpha-sig.figma.com/img/1691/ade0/10ec91292575dc06bcc4f2816938f589?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=WbS-SxKXT76iql6O-clzHL~XA4cg-bbof8h-3JMREavd89OjxHEFITi1fY21OELCMMrZczovMmNTv552NddqpD5wJmYasVYmKE8QOvG5u8XYoz9AnpMzZXnpY0twRkrYwwEPdwnQF7sPjTo4wcaAF0z9vXQA6DjdhLY2XZPPniOV5vrxMeT1kBItis82SlqZlFVKoI99R8dgrrXy9B3xTk48OFTdWe2CxgZbY2B08nz2nZE877Ugd5vtDiLKBfS9OBxcdeCQgTwwMZO2l0wMKPSMWVg3O8JM6qc7U69cNqWt~1gJWVlTr0pnddzbTNT8iGmn2GGWsROykE5KK0jEEw__',
    'https://s3-alpha-sig.figma.com/img/de34/1550/126a53b1f837036a5be5e454feb86df4?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=SWAXUesQuH2SZX75-1YALvBUkVuZpcim3AmXdSmcxWoJH5P3b1h9H~4mvzUDp7qz5K-Syn~J~NgRG8eRsXdEOXixXBBeRb2u6ddSnnIflBpfx80bh--tJzawxyVdkd0qjWi5FoOtDgbDcbYxSQlcZvU~dcPj6cbdHrOI1~7q9~N9f~xi9baDR~pm0WcVCU8V0n2jAPuZXPa6E3pHxxdkeUBQ3wUz~6-WU8aPWxpeJxrDFwnpvDmp~-TSQ4gd0yXDOjISaNWhwaDKlvDuuQi24YaXcE46ocV~yh5lv48OvN0bjHoNXWB4yGe440xBdmuQPcwxqXpX409Qon8Yb4cJ~A__',
    'https://s3-alpha-sig.figma.com/img/90b8/7947/614a4a262c43d4766ca278c3f9711650?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=IBBcoGp5-o45G0UA79XBE39Da-IYLzVTB-GNkP8jDTkMSpUPRqwSuqgYufJ5vKXDpX3onk9yufajT~XjxDzx48-hPKYQgrSJdgiWJdh4grh8UAcCpVpYi789w3ED3ba2H7QkrcTbhlAIwIa77NZOfhXu0OSyPjxf2Azm3BbiadZvqBa9679lvpaNKpSVNUB9FQhsjlxQfmNI2Td7YnuhUjjc7OGpQdlJKOpJWW14x75sl-9rAEuqEOu29PSK-ggMnjZ5PXDQrHWizok2bSQBgPJBqGaKkWBYk5eZ7P5WQ~nRQe86hh9Ylt32jzR6Y-3csssaDB2anTOvoD-iYNFwjQ__',
    'https://s3-alpha-sig.figma.com/img/80d8/833a/dc225ed6b48d3335a41ee1a4560cde4b?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=kYhKE-3EktFt7HOWFi4OlS6V6QqxB8sSMDDC0kpWOGTISOFzK~0TfV1JKXGZCy-DasxZRV6L4hTseW-hFNlxoAcGw0wY9r52Ov8kyuOV23AiS89k8oz408d6HQcbvT0OAsZNXcx31zLK~RhhNjATuQKKhM4DtRVTqdxj7xt8aO77NOmbmRkvUfBAFqUstIyZBLu4go937zEbtNtrMJ44e05JmMXYVBfhmZAjdLVnNqPAswiY0wNKdXuzWpSEnujjDoYMfeTyyLc~ylz5ZfOwUKTfYlPRXEc9xmliE50yPBnKrCdpAEzlUfoOfxb~byGfL7Nlm45VyLxwhiXtd7IEIQ__',
    'https://s3-alpha-sig.figma.com/img/954c/d47f/7d791f57f690e63ce19ec9156d8a7e6d?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=U~pWXpAv3jkr3X8AHgPLkbIdsiXhec-iRTas-NCRusvPjnWiTRHOAz6-HMSi-2O~O-bj8OqXR32a41mV73Ps6bePH2oBta7tmRlsQY6mTczxqSL5r2X3fUf6mtwI6VvgaDzXOz69rjqooFmDTz-YkgHds~2WMgLjG92J5ViZiCUkSE5qo04WusQkSL~rYlS8F8VykrudtOS~-iTC7TKZ8WVL0Xg34oHCWTqPu7rn2384STDgWS2rwyAB5bagRWrBz6~qTBmRU0Ui1tEDZ2iMdqUN-2flV3lHA0lNKxhoak58Cn8BLyopsD~8NXap6WHuBpz1cOjLosg8MRh0I2CFCw__',
  ];

  useEffect(() => {
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
    passengerLogisticsStore.mapValuechanged = false;
    loadInitialData();
    setFormPlaceholders();
  }, []);

  const saveData = () => {
    upsertVendorLocationOperationalInsights();
  };

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  const validateForm = () => {
    return !formRef.isValid || formRef.hasError || (!passengerLogisticsStore.mapValuechanged && !formRef.changed);
  };

  useEffect(() => {
    onNextButtonDisable(validateForm() || UIStore.pageLoading);
  }, [ formRef.isValid, formRef.hasError, passengerLogisticsStore.mapValuechanged, formRef.changed ]);

  const setFormPlaceholders = () => {
    useUpsert.getField('aircraftParkingOptionLatitudeLongitudeCityName').$placeholder = 'Choose from Map';
    useUpsert.getField('aircraftHandlingLocationLatitudeLongitudeCityName').$placeholder = 'Choose from Map';
    useUpsert.getField('arrivalMeetingPoint').$placeholder = 'e.g. FBO desk';
    useUpsert.getField('earlyCrewArrival').$placeholder = 'e.g. 30';
    useUpsert.getField('earlyPaxArrival').$placeholder = 'e.g. 30';
    useUpsert.getField('customsClearanceTiming').$placeholder = 'e.g. 30';
    useUpsert.getField('appliedAmenities').$placeholder = 'Select';
  };

  const formattedCoordinates = (latitude, longitude) => {
    return (latitude || longitude) && `${latitude !== null && latitude}, ${longitude !== null && longitude}`;
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    loadVendorLocationData(),
    settingsStore.getSettings(SETTING_ID.SETTING_DISABILITY_ACCOMMODATIONS).subscribe(response => {
      setDisabilityAccommodationsList(response.results);
    });
    settingsStore.getSettings(SETTING_ID.SETTING_ARRIVAL_CREW_PAX_PASSPORT_HANDLING).subscribe(response => {
      setArrivalCrewPaxPassportHandlingList(response.results);
    });
    settingsStore.getSettings(SETTING_ID.SETTING_LUGGAGE_HANDLING).subscribe(response => {
      setLuggageHandlingList(response.results);
    });
    settingsStore.getSettings(SETTING_ID.SETTING_AMENITIES).subscribe(response => {
      setAppliedAmenitiesList(response.results);
    });
    settingsStore.getSettings(SETTING_ID.SETTING_INTERNATIONAL_DEPARTURE_PROCEDURES).subscribe(response => {
      setInternationalDepartureProcedures(response.results);
    });
    forkJoin([
      vendorLocationStore.getVendorLocationById(parseInt(RootDataStore.locationData.locationId)),
      vendorLocationStore.getCustomers(),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: [VendorLocationModel, CustomersModel]) => {
        if (response[0].operationalInsight === null || !response[0].operationalInsight) {
          useUpsert.setFormValues(new PassengerLogisticsModel());
        } else {
          useUpsert.setFormValues(PassengerLogisticsModel.deserialize(response[0].operationalInsight));
          setFormValues(response[0].operationalInsight);
        }
      });
  };

  const loadVendorLocationData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(parseInt(RootDataStore?.locationData?.locationId))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        vendorLocationPhotoStore.photosAddedList = response?.vendorLocationPhoto;
      });
  };

  const setFormValues = response => {
    if (response?.agentFeesApply === true) {
      useUpsert.getField('agentFeesApply').set(
        SettingBaseModel.deserialize({
          id: 1,
          name: 'Yes',
        })
      );
    } else if (response?.agentFeesApply === false) {
      useUpsert.getField('agentFeesApply').set(
        SettingBaseModel.deserialize({
          id: 2,
          name: 'No',
        })
      );
    }
    useUpsert
      .getField('aircraftParkingOptionLatitudeLongitude')
      .set(formattedCoordinates(response?.aircraftParkingOptionLatitude, response?.aircraftParkingOptionLongitude));
    useUpsert
      .getField('aircraftHandlingLocationLatitudeLongitude')
      .set(
        formattedCoordinates(response?.aircraftHandlingLocationLatitude, response?.aircraftHandlingLocationLongitude)
      );
    useUpsert
      .getField('aircraftParkingOptionLatitudeLongitudeCityName')
      .set(formattedCoordinates(response?.aircraftParkingOptionLatitude, response?.aircraftParkingOptionLongitude));
    useUpsert
      .getField('aircraftHandlingLocationLatitudeLongitudeCityName')
      .set(
        formattedCoordinates(response?.aircraftHandlingLocationLatitude, response?.aircraftHandlingLocationLongitude)
      );
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'aircraftParkingOptionLatitudeLongitudeCityName':
        if (!value) {
          useUpsert.getField('aircraftParkingOptionLatitudeLongitude').set(null);
        }
        break;
      case 'aircraftHandlingLocationLatitudeLongitudeCityName':
        if (!value) {
          useUpsert.getField('aircraftHandlingLocationLatitudeLongitude').set(null);
        }
        break;
      default:
        break;
    }
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'appliedDisabilityAccommodation':
        const filteredList = settingsStore.disabilityAccommodations.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setDisabilityAccommodationsList(filteredList);
        break;
      case 'arrivalCrewPaxPassportHandling':
        const searchedList = settingsStore.arrivalCrewPaxPassportHandling.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setArrivalCrewPaxPassportHandlingList(searchedList);
        break;
      case 'luggageHandling':
        const searchList = settingsStore.luggageHandling.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setLuggageHandlingList(searchList);
        break;
      case 'appliedAmenities':
        const filterList = settingsStore.amenities.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setAppliedAmenitiesList(filterList);
        break;
      case 'agentFeesApply':
        const agentFeesApplyList = PassengerLogisticsModel.getYesNo().filter(item => {
          return item.name?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setYesOrNoListList(agentFeesApplyList);
        break;
      case 'appliedInternationalDepartureProcedures':
        const appliedInternationalDepartureList = settingsStore.internationalDepartureProcedures.filter(item => {
          return item.name?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setInternationalDepartureProcedures(appliedInternationalDepartureList);
        break;
      default:
        break;
    }
    return;
  };

  const onMarkerDragEnd = (letlng: any, fieldKey, placeName: string) => {
    useUpsert.getField(fieldKey[0])?.set(`${letlng.lng}, ${letlng.lat}`);
    const name = placeName ? `${placeName}` : `${letlng.lng}, ${letlng.lat}`;
    passengerLogisticsStore.mapValuechanged = true;
    useUpsert.getField(fieldKey[1])?.set(name);
  };

  const openMapViewDialogue = (fieldKey): ReactNode => {
    const fieldValue = useUpsert.getField(fieldKey[0])?.value;
    const fieldLabel = useUpsert.getField(fieldKey[0])?.label;
    const coordinatesArray = fieldValue && fieldValue?.split(',').map(parseFloat);
    ModalStore.open(
      <CustomDialogMap fullWidth fullScreen open={true} onClose={() => ModalStore.close()}>
        <Box>
          <DialogTitle>{fieldLabel}</DialogTitle>
          <IconButton onClick={() => ModalStore.close()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <MapBoxViewV1
            onMarkerDragEnd={(data, placeName) => onMarkerDragEnd(data, fieldKey, placeName)}
            value={coordinatesArray ? coordinatesArray : [ 28.853220553990308, 53.12500655651093 ]}
          />
        </DialogContent>
      </CustomDialogMap>
    );
  };

  const openMapIcon = (fieldKey): ReactNode => {
    return <LocationOn onClick={() => openMapViewDialogue(fieldKey)} />;
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'Operational Insights:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'aircraftParkingOptionLatitudeLongitudeCityName',
            type: EDITOR_TYPES.TEXT_FIELD,
            endAdormentValue: openMapIcon([
              'aircraftParkingOptionLatitudeLongitude',
              'aircraftParkingOptionLatitudeLongitudeCityName',
            ]),
            isReadOnly: true,
          },
          {
            fieldKey: 'aircraftHandlingLocationLatitudeLongitudeCityName',
            type: EDITOR_TYPES.TEXT_FIELD,
            endAdormentValue: openMapIcon([
              'aircraftHandlingLocationLatitudeLongitude',
              'aircraftHandlingLocationLatitudeLongitudeCityName',
            ]),
            isReadOnly: true,
          },
          {
            fieldKey: 'aircraftParkingOptionLatitudeLongitude',
            type: EDITOR_TYPES.TEXT_FIELD,
            endAdormentValue: openMapIcon([ 'aircraftParkingOptionLatitudeLongitude' ]),
            isReadOnly: true,
            isHidden: true,
          },
          {
            fieldKey: 'aircraftHandlingLocationLatitudeLongitude',
            type: EDITOR_TYPES.TEXT_FIELD,
            endAdormentValue: openMapIcon([ 'aircraftHandlingLocationLatitudeLongitude' ]),
            isReadOnly: true,
            isHidden: true,
          },
          {
            fieldKey: 'agentFeesApply',
            type: EDITOR_TYPES.DROPDOWN,
            options: yesOrNoList,
          },
          {
            fieldKey: 'appliedInternationalDepartureProcedures',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: passengerLogisticsStore.getOperationalInsightsSettingOptions<SettingBaseModel>(
              internationalDepartureProceduresList,
              'internationalDepartureProcedures'
            ),
            isFullFlex: true,
            multiple: true,
            disableCloseOnSelect: true,
            isHidden: true,
          },
          {
            fieldKey: 'appliedDisabilityAccommodation',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: passengerLogisticsStore.getOperationalInsightsSettingOptions<SettingBaseModel>(
              disabilityAccommodationsList,
              'disabilityAccommodations'
            ),
            isHalfFlex: true,
            multiple: true,
            disableCloseOnSelect: true,
          },
          {
            fieldKey: 'arrivalCrewPaxPassportHandling',
            type: EDITOR_TYPES.DROPDOWN,
            options: arrivalCrewPaxPassportHandling,
            isHalfFlex: true,
          },
          {
            fieldKey: 'luggageHandling',
            type: EDITOR_TYPES.DROPDOWN,
            options: luggageHandling,
            isFullFlex: true,
          },
          {
            fieldKey: 'arrivalMeetingPoint',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'earlyCrewArrival',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'earlyPaxArrival',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'customsClearanceTiming',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'appliedAmenities',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: passengerLogisticsStore.getOperationalInsightsSettingOptions<SettingBaseModel>(
              appliedAmenities,
              'amenities'
            ),
            isHalfFlex: true,
            multiple: true,
            disableCloseOnSelect: true,
          },
        ],
      },
    ];
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case '':
        break;
      default:
        break;
    }
  };

  const getFieldValue = (fieldKey, index) => {
    const fieldValue = useUpsert.getField(fieldKey)?.value;
    if (fieldValue) {
      const coordinatesArray = fieldValue && fieldValue?.split(',').map(parseFloat);
      return coordinatesArray[index] || fieldValue;
    }
    return null;
  };

  const upsertVendorLocationOperationalInsights = (): void => {
    const request = new PassengerLogisticsModel({
      ...useUpsert.form.values(),
      appliedInternationalDepartureProcedures: null,
    });
    request.aircraftParkingOptionLatitude = getFieldValue('aircraftParkingOptionLatitudeLongitude', 0);
    request.aircraftParkingOptionLongitude = getFieldValue('aircraftParkingOptionLatitudeLongitude', 1);
    request.aircraftHandlingLocationLatitude = getFieldValue('aircraftHandlingLocationLatitudeLongitude', 0);
    request.aircraftHandlingLocationLongitude = getFieldValue('aircraftHandlingLocationLatitudeLongitude', 1);

    UIStore.setPageLoader(true);
    passengerLogisticsStore
      ?.upsertVendorLocationOperationalInsights(request.serialize(), false)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: PassengerLogisticsModel) => {
          useUpsert.form.reset();
          useUpsert.setFormValues(PassengerLogisticsModel.deserialize(response));
          setFormValues(response);
          passengerLogisticsStore.mapValuechanged = false;
        },
        error: error => {
          if (error.response?.data?.errors) {
            errorHandler(error.response?.data?.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error?.message, request.id.toString());
        },
      });
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">Available Handler Facilities at Airport</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => navigate('/vendor/locations')}
              disabled={!passengerLogisticsStore.mapValuechanged && !formRef.changed}
              size="large"
            >
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={
                !formRef.isValid || formRef.hasError || (!passengerLogisticsStore.mapValuechanged && !formRef.changed)
              }
              onClick={() => upsertVendorLocationOperationalInsights()}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const getConfirmation = (item: number): void => {
    ModalStore.open(
      <ConfirmDialog
        title="Remove this Photo"
        message={'Are you sure you want to remove this Photo?'}
        noButton="Cancel"
        yesButton="Confirm"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => deleteImage(item)}
      />
    );
  };

  const downloadImage = (id: number, photouri: string, imageName: string) => {
    vendorLocationPhotoStore
      ?.downloadVendorLocationPhoto(photouri, id, RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: IAPIDownloadDocumentFile) => {
          const link = document.createElement('a');
          link.href = response?.photoUri;
          link.target = '_blank';
          link.download = imageName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: error => {
          BaseStore.showAlert(`Error Downloading ${error.message}`, 0);
        },
      });
  };
  const deleteImage = (id: number) => {
    UIStore.setPageLoader(true);
    vendorLocationPhotoStore
      ?.removeVendorLocationPhoto(id, RootDataStore.locationData.locationId, '')
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe({
        next: (response: IAPIDownloadDocumentFile) => {
          loadInitialData();
        },
        error: error => {
          BaseStore.showAlert(`Error While Deleting Image ${error.message}`, 0);
        },
      });
  };
  const uploadDocumentFile = (): void => {
    UIStore.setPageLoader(true);
    progressLoader.setLoadingState(true);
    vendorLocationPhotoStore
      ?.importDocumentFile(vendorLocationPhotoStore.file, RootDataStore.locationData.locationId.toString())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          progressLoader.setLoadingState(false);
        })
      )
      .subscribe({
        next: (response: IAPIDocumentFile) => {
          if (response) {
            vendorLocationPhotoStore.file = [];
            vendorLocationPhotoStore.documentUpdated = true;
            // vendorLocationPhotoStore.photoUrl = response.results;
            const payload = [];
            vendorLocationPhotoStore.photoUrl.map(item => {
              const request = new VendorLocationPhoto({
                vendorLocationId: RootDataStore.locationData.locationId,
                fileName: item.photoName,
                photoUri: item.url,
                statusId: 1,
              });
              payload.push({
                ...request.serialize(item.url, RootDataStore.locationData.locationId),
              });
            });
            vendorLocationPhotoStore
              .upsertLocationPhotos(payload)
              .pipe(
                takeUntil(unsubscribe.destroy$),
                finalize(() => {
                  UIStore.setPageLoader(false);
                  progressLoader.setLoadingState(false);
                })
              )
              .subscribe({
                next: (response: VendorLocationPhoto[]) => {
                  if (response) {
                    loadVendorLocationData();
                  }
                },
              });
          }
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, '0');
            return;
          }
          BaseStore.showAlert(`Photos imported with errors ${error.message}`, 0);
        },
      });
  };
  const onRequestImportDocument = (): void => {
    ModalStore.open(
      <AircraftPhotoUpload
        fileType=".jpg, .png"
        title="Upload Document File"
        uploadDocumentFile={() => uploadDocumentFile()}
        loader={progressLoader}
      />
    );
  };
  const clickUploadDocument = (): void => {
    ModalStore.open(
      <CustomDialog
        title="Warning"
        message={`<p style="font-size: 14px; line-height: 28px; margin-bottom: 20px;">
          By loading photographs into Universal’s Provider Portal, I certify <br> 
          that I have permission to share these photographs with Universal. <br>
          If there is a person in the photograph, I certify that I have consent <br> 
          under applicable law to share that person’s likeness. <br>
          <strong>I HEREBY RELEASE UNIVERSAL FROM ANY LIABILITY ARISING OUT <br> 
          OF RELATING TO THE PHOTOGRAPHS WHICH I UPLOAD.</strong>
        </p>`}
        yesButton="Confirm"
        noButton="Cancel"
        onNoClick={() => {
          ModalStore.close();
        }}
        onYesClick={() => {
          onRequestImportDocument();
        }}
      />
    );
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <ConfirmNavigate isBlocker={passengerLogisticsStore.mapValuechanged || formRef.changed}>
        <div className={classes.editorWrapperContainer}>
          <BootstrapDialog
            onClose={handleClose}
            className={classes.tooltipDialogWidth}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <IconButton className={classes.crossIcon} style={{ justifyContent: 'end' }} onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
            <strong className={classes.uploadingguide}>Uploading Image Guidelines</strong>
            <Typography className={classes.iconText}>
              <div className={classes.tick}>
                <CheckCircleIcon className={classes.checkCircleIcon} />
                <span className={classes.impText}>Please offer any images of available facilities.</span>
              </div>
              <div className={classes.tick}>
                <CheckCircleIcon className={classes.checkCircleIcon} />
                <span className={classes.impText}>Please don't include any aircraft in the pictures.</span>
              </div>
            </Typography>
            <Typography style={{ paddingBottom: '10px', fontSize: '12px' }}>Image examples:</Typography>
            <div className={classes.imageLoop}>
              {list.map(item => (
                <div>
                  <img height={'138px'} width={'138px'} src={item} className={classes.img} />
                </div>
              ))}
            </div>
          </BootstrapDialog>
          <ViewInputControls
            isEditable={true}
            groupInputControls={groupInputControls()}
            onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
            onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
            field={fieldKey => useUpsert.getField(fieldKey)}
            onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
            onFocus={fieldKey => onFocus(fieldKey)}
            classes={classes}
          />
          <Box>
            <Typography className="radioLabel" style={{ paddingBottom: '10px' }}>
              <span style={{ fontWeight: 'bold', fontSize: '12px', marginBottom: '10px' }}>Upload Image </span>
              <span style={{ fontWeight: 'normal', fontSize: '12px', marginBottom: '10px' }}>(Only .PNG, .JPG)</span>
              <Tooltip
                style={{ fontFamily: 'normal', marginBottom: '-3px' }}
                title=""
                placement="right"
                onClick={handleClickOpen}
              >
                <HelpIcon className={classes.imageIcon} />
              </Tooltip>
            </Typography>
            <div className={`${classes.primaryUploadButton} ${classes.customButton}`}>
              <PrimaryButton
                variant="outlined"
                color="primary"
                className={classes.primaryUploadButton}
                startIcon={<UploadIcon />}
                onClick={() => {
                  clickUploadDocument();
                }}
              >
                Upload
              </PrimaryButton>
            </div>
            <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              {vendorLocationPhotoStore.photosAddedList &&
                vendorLocationPhotoStore.photosAddedList.length > 0 &&
                vendorLocationPhotoStore.photosAddedList.map((item, index) => (
                  <Box className={classes.boxFile}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <DownloadIcon
                        size="medium"
                        className={classes.downloadIcon}
                        onClick={() => {
                          downloadImage(item?.id, item?.photoUri, item?.fileName);
                        }}
                      />
                      <Typography style={{ fontSize: '14px', fontWeight: '400' }}>
                        {item?.fileName && `${item?.fileName}`}
                      </Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      {/* <Typography style={{ fontSize: '14px', fontWeight: '400' }}>
                      {item && `${(item?.size / 1024).toFixed(2)}K`}
                    </Typography> */}
                      <IconButton
                        className={classes.crossIcon2}
                        style={{ justifyContent: 'end' }}
                        disabled={UIStore.pageLoading}
                        onClick={() => {
                          getConfirmation(item?.id);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </Box>
                ))}
            </Box>
          </Box>
        </div>
      </ConfirmNavigate>
    </>
  );
};

export default inject(
  'settingsStore',
  'vendorLocationStore',
  'passengerLogisticsStore',
  'vendorLocationPhotoStore'
)(withStyles(styles)(observer(AircraftFacilities)));

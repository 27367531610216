import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: '1',
    display: 'flex',
    padding: '12px',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
  },
  customTooltip: {
    backgroundColor: ' #5F5F5F', 
    color: '#FFFFFF',
    fontSize: '14px',
  },
  headerActions: {
    justifyContent: 'space-between',
    '& button': {
      width: '150px',
      marginRight: theme.spacing(1),
    },
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    '& .MuiTypography-h6':{
      fontSize: '1.625rem',
      textTransform: 'capitalize !important',
      // width: '500px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      whiteSpace: 'nowrap',
      // color: theme.palette.primary.main
    },
  },

  defaultButton: {
    marginLeft:theme.spacing(2),
    '& button':{
      border: '1px solid #1976D2',
      padding:'4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize:'14px',
      fontWeight: '400',
      minWidth:'150px',
      textTransform:'capitalize',
      color:'#1976D2'
    }
  },
  primaryButton:{
    '& button':{
      background: '#1976D2',
      color:'#fff'
    },
    '& button:disabled':{
      background: '#afafaf',
      border:'none'
    }
  },
  primaryButtonWithIcon:{
    '& button':{
      color:'#1976D2',
      background: 'none',
      '& .MuiButton-startIcon':{
        backgroundColor: '#1976D2',
        borderRadius: '2px',
        padding: '2px 4px',
        fontWeight:'400',
        color: theme.palette.common.white
      }
    }
  },
  flexIcon:{
    display: 'flex',
  },
  buttonStyle: {
    height: '25px',
    cursor: 'pointer',
    color: '#1976D2',
  },
  imageIcon: {
    height: '15px',
    width: '15px',
    cursor: 'pointer',
    color: '#1976D2',
    pointerEvents: 'all',
    marginLeft: theme.spacing(1),
  },
}));

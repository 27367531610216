import React, { FC, ReactNode, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, FormControl, FormHelperText, OutlinedInput, Typography, withStyles } from '@material-ui/core';
import { styles } from '../../OperationalHours.styles';
import {
  AccordionRadioGroups,
  CustomAccordion,
  LocationHoursModel,
  PassengerLogisticsModel,
  VendorLocationModel,
} from '../../../../../Shared';
import { IAPIGridRequest, IAPISearchFiltersDictionary, IClasses, UIStore } from '@wings-shared/core';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { RootDataStore } from '@uplink-shared/layout';
import { PassengerLogisticsStore, VendorLocationHoursStore, VendorLocationStore } from '../../../../../../Stores';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AlertStore } from '@uvgo-shared/alert';
import { useUnsubscribe } from '@wings-shared/hooks';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import MainOperationalHours from 
  '../../../../Airport/AirportHours/Components/MainTerminalOnly/MainOperationalHours/MainOperationalHours';
import { useBaseUpsertComponent } from '@uplink/shared';
import { fields } from './Fields';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  vendorLocationHoursStore: VendorLocationHoursStore;
  passengerLogisticsStore: PassengerLogisticsStore;
  onNextButtonDisable?: (boolean) => void;
  registerSaveData: (saveData: () => void) => void;
  searchFilters: IAPISearchFiltersDictionary;
}

const PrivateHandlerFbo: FC<Props> = ({
  classes,
  vendorLocationStore,
  vendorLocationHoursStore,
  passengerLogisticsStore,
  onNextButtonDisable,
  registerSaveData,
  searchFilters,
}) => {
  const useUpsert = useBaseUpsertComponent<PassengerLogisticsModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const [ error, setError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  useEffect(() => {
    loadInitialData();
    loadLocationData();
    vendorLocationStore.isHoursValueChange = false;
    vendorLocationStore.overTimeValue = null;
    vendorLocationStore.leadTimeValue = null;
  }, []);

  const loadLocationData = () => {
    vendorLocationStore
      .getVendorLocationById(parseInt(RootDataStore.locationData.locationId))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        if (response?.operationalInsight !== null) {
          const data = PassengerLogisticsModel.deserialize(response.operationalInsight);
          setFormValues(data);
        }
      });
  };
  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  useEffect(() => {
    function isValid() {
      if (vendorLocationStore.isHoursValueChange) {
        if (vendorLocationStore.overTimeValue === 1) {
          if (!vendorLocationStore.leadTimeValue) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
    onNextButtonDisable(isValid() || error);
  }, [ vendorLocationStore.isHoursValueChange, vendorLocationStore.overTimeValue, vendorLocationStore.leadTimeValue ]);

  const saveData = () => {
    upsertOverTimeData();
  };

  const loadInitialData = () => {
    vendorLocationStore.isTimeChanged = false;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Id',
          propertyValue: RootDataStore.locationData.locationId,
        },
      ]),
    };
    vendorLocationHoursStore.getVendorLocationHours(request).subscribe(() => UIStore.setPageLoader(false));
  };

  const errorHandler = (errors: { [key: string]: string[] }): void => {
    Object.keys(errors).forEach(key => {
      const errorMessages = errors[key];
      errorMessages.forEach(message => {
        AlertStore.info(message);
      });
    });
  };

  const handleErrorResponse = error => {
    if (error?.response?.data?.errors) {
      errorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      AlertStore.info(error?.message);
    }
  };

  const deleteAllRecords = () => {
    const ids =
      vendorLocationHoursStore.locationHoursList && vendorLocationHoursStore.locationHoursList.map(item => item.id);
    if (ids.length === 0) {
      ModalStore.close();
      return;
    }
    UIStore.setPageLoader(true);
    vendorLocationHoursStore
      ?.deleteLocationHours(ids)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel[]) => {
          AlertStore.info('Airport Hours saved successfully!');
          loadInitialData();
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  const handleSave = () => {
    const hoursIdZeroData = vendorLocationHoursStore.updatedHoursData.filter(item => item.id === 0);
    const hoursIdOtherData = vendorLocationHoursStore.updatedHoursData.filter(item => item.id !== 0);

    if (hoursIdZeroData.length === 0 && hoursIdOtherData.length === 0) {
      deleteAllRecords();
      return;
    }

    if (hoursIdOtherData.length === 0) {
      deleteAllRecords();
    }
    const apiCalls = [];

    UIStore.setPageLoader(true);
    apiCalls.push(
      vendorLocationHoursStore
        ?.updateVendorLocationHour(LocationHoursModel.hoursSerializeList(hoursIdOtherData, 1))
        .pipe(
          mergeMap(() => {
            return forkJoin(
              vendorLocationHoursStore?.addVendorLocationHour(LocationHoursModel.hoursSerializeList(hoursIdZeroData, 1))
            );
          })
        )
        .pipe(
          catchError(error => {
            handleErrorResponse(error);
            return of(null);
          })
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(responses => {
          const allSuccessful = responses && responses.every(response => response !== null);
          if (allSuccessful) {
            AlertStore.info('Vendor Location Hours saved successfully!');
            loadInitialData();
            ModalStore.close();
          }
        })
    );
  };

  const overTimeData = [
    { id: 1, value: 1, label: 'Yes' },
    { id: 2, value: 2, label: 'No' },
  ];

  const onOvertimeValueChange = (value: number) => {
    vendorLocationStore.isHoursValueChange = true;
    vendorLocationStore.overTimeValue = value;
    if (value === 1) {
      if (!vendorLocationStore.leadTimeValue) {
        setError(true);
        setErrorMessage('This field is required');
      }
    } else {
      vendorLocationStore.leadTimeValue = null;
      setError(false);
      setErrorMessage(null);
    }
  };

  const handleInputValue = event => {
    vendorLocationStore.isHoursValueChange = true;
    const value = event.target.value;
    vendorLocationStore.leadTimeValue = value;
    let errorMessage = '';
    if (!value) {
      errorMessage = 'This field is required';
    } else if (!/^\d*(\.\d*)?$/.test(value)) {
      errorMessage = 'The field value must be a number';
    } else if (parseFloat(value) < 0 || parseFloat(value) > 2140000000) {
      errorMessage = 'The field must be between 0 and 2140000000';
    }

    if (errorMessage) {
      setErrorMessage(errorMessage);
      setError(true);
    } else {
      setError(false);
    }
  };

  const setFormValues = response => {
    useUpsert.setFormValues(response);
    if (response?.isOvertimeAvailable === true) {
      vendorLocationStore.overTimeValue = 1;
    } else if (response?.isOvertimeAvailable === false) {
      vendorLocationStore.overTimeValue = 2;
    }
    vendorLocationStore.leadTimeValue = response?.leadTimeForOvertime;
  };

  const upsertOverTimeData = (): void => {
    const request = new PassengerLogisticsModel({
      ...useUpsert.form.values(),
      isOvertimeAvailable:
        vendorLocationStore.overTimeValue === 1 ? true : vendorLocationStore.overTimeValue === 2 ? false : null,
      leadTimeForOvertime:
        vendorLocationStore.overTimeValue === 1
          ? +vendorLocationStore.leadTimeValue
          : vendorLocationStore.overTimeValue === 2 ? null : null,
      appliedInternationalDepartureProcedures: null,
    });
    UIStore.setPageLoader(true);
    passengerLogisticsStore
      ?.upsertVendorLocationOperationalInsights(request.serialize(parseInt(RootDataStore.locationData.locationId)))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: PassengerLogisticsModel) => {
          useUpsert.form.reset();
          setFormValues(PassengerLogisticsModel.deserialize(response));
        },
        error: error => {
          if (error?.response?.data?.errors) {
            errorHandler(error.response?.data?.errors);
            return;
          }
          if (error?.message) {
            useUpsert.showAlert(error?.message, request.id.toString());
            return;
          }
        },
      });
  };
  return (
    <div className="mainFBOWrapper">
      <Box marginBottom={2}>
        <CustomAccordion
          panelName="fboOperationalHours"
          panelHeading="Private Handler FBO - Operational Hours (in local time)"
          panelContent={
            <MainOperationalHours
              locationHoursList={vendorLocationHoursStore.locationHoursList}
              handleSave={handleSave}
            />
          }
        />
      </Box>
      <Box marginBottom={2}>
        <CustomAccordion
          panelName="overtime"
          panelHeading="Overtime"
          panelContent={
            <Box>
              <AccordionRadioGroups
                radioValue={vendorLocationStore.overTimeValue}
                onRadioChange={onOvertimeValueChange}
                radioGroupData={overTimeData}
                label="Overtime Available?"
              />
              {vendorLocationStore.overTimeValue === 1 && (
                <FormControl variant="outlined">
                  <Typography className={classes.heading}>Lead Time for Overtime (in minutes)</Typography>
                  <Box className={classes.costFields}>
                    <OutlinedInput
                      className="inputTextField"
                      value={vendorLocationStore.leadTimeValue}
                      onChange={handleInputValue}
                      aria-describedby="outlined-terminal-cost-text"
                      inputProps={{
                        'aria-label': 'gat cost',
                      }}
                      labelWidth={0}
                      style={{
                        borderColor: error ? 'red' : 'initial',
                        borderWidth: '1px',
                        borderStyle: error ? 'solid' : 'none',
                      }}
                      placeholder="Lead Time for Overtime (in minutes)"
                    />
                  </Box>
                  {error && <FormHelperText style={{ color: 'red' }}>{errorMessage}</FormHelperText>}
                </FormControl>
              )}
            </Box>
          }
        />
      </Box>
    </div>
  );
};

export default inject(
  'vendorLocationStore',
  'vendorLocationHoursStore',
  'passengerLogisticsStore'
)(withStyles(styles)(observer(PrivateHandlerFbo)));

import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { VendorLocationModel } from './VendorLocation.model';
import { RootDataStore } from '@uplink-shared/layout';
import { Airports } from './Airports.model';
import { SettingBaseModel } from './SettingBase.model';
import { AoeDesignationOptionModel } from './AoeDesignationOptionModel.model';

@modelProtection
export class AoeDesignationModel extends CoreModel implements ISelectOption {
  userId: string = '';
  statusId: number = 0;
  id: number = 0;
  customsGeneralInformationId: number = 0;
  isAirsidePickupArrangementPossible: boolean = false;
  airportEntry: SettingBaseModel = new SettingBaseModel();
  vendorLocation: VendorLocationModel = new VendorLocationModel();
  airportOfEntryCode: string = '';
  isCustomsAvailableAtAirport: boolean = false;
  clearanceLocationSpecifics: string = '';
  gaClearanceAvailable: boolean = false;
  maximumPersonsOnBoardAllowedForGAClearanceNotes: string = '';
  airportReference: Airports = new Airports();
  status: SettingBaseModel = new SettingBaseModel();
  customOfficerDispatchedFromAirport: Airports = new Airports();
  appliedCustomsLocationInformations: AoeDesignationOptionModel[] = [];
  appliedCustomsClearanceFBOs: AoeDesignationOptionModel[] = [];
  appliedMaxPOBAltClearanceOptions: AoeDesignationOptionModel[] = [];
  airportOfEntryOptions: AoeDesignationOptionModel[] = [];
  name: string = '';
  description: string = '';
  customsOfficerDispatchedFromAirportId: number = 0;

  constructor(data?: Partial<AoeDesignationModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: AoeDesignationModel): AoeDesignationModel {
    if (!apiData) {
      return new AoeDesignationModel();
    }
    
    const data: Partial<AoeDesignationModel> = {
      ...apiData,
      id: apiData.id || 0,
      airportEntry: SettingBaseModel.deserialize({
        id: apiData.airportOfEntryId,
        name: apiData.airportOfEntryName,
        code: apiData.airportOfEntryCode,
      }),
      airportReference: Airports.deserialize(apiData.airportReference),
      appliedCustomsLocationInformations: AoeDesignationOptionModel.deserializeList(
        apiData.appliedCustomsLocationInformations
      ),
      customOfficerDispatchedFromAirport: apiData.customOfficerDispatchedFromAirport
        ? Airports.deserializeAirportReference(apiData.customOfficerDispatchedFromAirport)
        : null,
      appliedCustomsClearanceFBOs: AoeDesignationOptionModel.deserializeList(apiData.appliedCustomsClearanceFBOs),
      appliedMaxPOBAltClearanceOptions: AoeDesignationOptionModel.deserializeList(
        apiData.appliedMaxPOBAltClearanceOptions
      ),
      airportOfEntryOptions: AoeDesignationOptionModel.deserializeList(
        apiData.airportOfEntryOptions
      ),
      
    };
    return new AoeDesignationModel(data);
  }

  static deserializeList(apiDataList: AoeDesignationModel[]): AoeDesignationModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => AoeDesignationModel.deserialize(apiData)) : [];
  }

  public serialize() {
    return {
      userId: this.userId,
      statusId: this.status?.id || null,
      id: this.id || 0,
      customsGeneralInformationId: this.id || 0,
      isAirsidePickupArrangementPossible: this.isAirsidePickupArrangementPossible,
      airportOfEntryId: this.airportEntry.id,
      airportOfEntryName: this.airportEntry.name,
      airportOfEntryCode: this.airportEntry.code,
      vendorLocationId: RootDataStore.locationData.locationId,
      airportReferenceId: RootDataStore.locationData.airportReference?.id,
      isCustomsAvailableAtAirport: this.isCustomsAvailableAtAirport,
      clearanceLocationSpecifics: this.clearanceLocationSpecifics,
      gaClearanceAvailable: this.gaClearanceAvailable,
      maximumPersonsOnBoardAllowedForGAClearanceNotes: this.maximumPersonsOnBoardAllowedForGAClearanceNotes,
      customsOfficerDispatchedFromAirportReference: this.customOfficerDispatchedFromAirport,
      appliedCustomsLocationInformations:
        this.appliedCustomsLocationInformations?.length > 0
          ? this.appliedCustomsLocationInformations.map(item => {
            return {
              id: item.id,
              customsLocationInformationId: item.customsLocationInformation?.id,
              customsLocationInformationName: item.customsLocationInformation?.name,
            };
          })
          : [],
      appliedMaxPOBAltClearanceOptions:
        this.appliedMaxPOBAltClearanceOptions.length > 0
          ? [
            {
              id: this.appliedMaxPOBAltClearanceOptions[0]?.id,
              maxPOBOptionId: this.appliedMaxPOBAltClearanceOptions[0]?.maxPOBOption?.id,
              maxPOBOptionName: this.appliedMaxPOBAltClearanceOptions[0]?.maxPOBOption?.name,
            },
          ]
          : [],
      airportOfEntryOptions:
        this.airportOfEntryOptions.length > 0
          ? [
            {
              id: this.airportOfEntryOptions[0]?.id,
              maxPOBOptionId: this.airportOfEntryOptions[0]?.maxPOBOption?.id,
              maxPOBOptionName: this.airportOfEntryOptions[0]?.maxPOBOption?.name,
            },
          ]
          : [],
      appliedCustomsClearanceFBOs: null,
    };
  }

  public get label(): string {
    return this.label;
  }

  public get value(): string | number {
    return this.id;
  }

  public static getMaxPOBAltClearanceOption() {
    return SettingBaseModel.deserializeList([
      {
        id: 1,
        name: 'Gate Clearance',
      },
      {
        id: 2,
        name: 'Cargo Ramp',
      },
      {
        id: 3,
        name: 'Commercial FBO',
      },
    ]);
  }

  public static getCustomsPassengersOption() {
    return SettingBaseModel.deserializeList([
      { id: 1, name: 'Onboard' },
      { id: 2, name: 'At FBO' },
      { id: 3, name: 'At Hanger' },
      { id: 4, name: 'Customs Ramp' },
      { id: 5, name: 'Terminal' },
    ]);
  }
}

import { action, observable } from 'mobx';
import { apiUrls } from '../API.url';
import { map, tap } from 'rxjs/operators';
import { Logger } from '@uplink-shared/security';
import { baseApiPath, HttpClient } from '@uplink/shared';
import { Observable } from 'rxjs';
import { vendorManagementHeaders } from '../Base.store';
import { IAPIPageResponse } from '@wings-shared/core';
import {
  VendorLocationModel,
  VendorOnBoardSlideEightModel,
  VendorOnBoardSlideNineModel,
  VendorOnBoardSlideSevenModel,
} from '../../Modules/Shared';
import { AlertStore } from '@uvgo-shared/alert';

export class SlideEightStore {
  @observable public groundServiceLocation: VendorLocationModel = new VendorLocationModel(); 

  @action
  public getByVendorIdSlideEight(vendorId?: number): Observable<IAPIPageResponse<VendorOnBoardSlideSevenModel>> {
    const http: HttpClient = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .get<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideEight}/GetByVendorId?vendorId=${vendorId}`
      )
      .pipe(
        Logger.observableCatchError,
        map(response => {
          return { ...response };
        })
      );
  }

  @action
  public upsertSlideEight(payload: VendorOnBoardSlideEightModel): Observable<VendorOnBoardSlideEightModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<VendorOnBoardSlideEightModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideEight}`, payload)
      : http.put<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideEight}/${payload.id}`,
        payload
      );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => AlertStore.info('Slide Eight data saved successfully!')),
      map(response => response)
    );
  }

  @action
  public getByVendorIdSlideNine(tempLocationId?: string): Observable<IAPIPageResponse<VendorOnBoardSlideNineModel>> {
    const http: HttpClient = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .get<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnboardSlide9}/byTempLocationId?tempLocationId=${tempLocationId}`
      )
      .pipe(
        Logger.observableCatchError,
        map(response => {
          return { ...response };
        })
      );
  }

  @action
  public upsertSlideNine(payload: VendorOnBoardSlideNineModel): Observable<VendorOnBoardSlideNineModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<VendorOnBoardSlideNineModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnboardSlide9}`, payload)
      : http.put<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnboardSlide9}/${payload.id}`,
        payload
      );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => AlertStore.info('Slide Nine data saved successfully!')),
      map(response => response)
    );
  }
}

import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  mainOperationalHoursWrapper:{
    '& [class*="DetailsEditorWrapper-container-"]':{
      height:'auto'
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  customHeight: {
    height: 'calc(100vh - 290px)',
  },
  editorWrapperContainer: {
    boxShadow:'0px 2px 3px rgba(0,0,0,.2)',
    borderRadius:'4px',
    '& .MuiTabPanel-root':{
      padding:'24px',
    },
    '& .MuiFormControlLabel-root':{
      paddingBottom:'8px',
    },
    '& .checkbox-wrapper-header .MuiFormControlLabel-label':{
      fontSize:'18px',
      fontWeight:'600',
      lineHeight:'1.2'
    },
    '& .MuiCheckbox-root':{
      width:'18px',
      height:'18px'
    },
    '& .inputTextField':{
      padding: '8px',
      borderRadius: '4px',
      width: '100%'
    }
  },
  headerActions: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    '& button': {
      width: '150px',
      marginRight: theme.spacing(1),
    },
    '& h5': {
      fontSize: '1.625rem',
    },
    '& .MuiTypography-h6': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      whiteSpace: 'nowrap',
      fontSize: '1.625rem',
    },
  },
  defaultButton: {
    marginLeft: '10px',
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      width: '140px',
      textTransform: 'capitalize',
      color: '#1976D2',
      marginRight:'0'
    },
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
    },
  },
  primaryButtonWithIcon: {
    '& button': {
      color: '#1976D2',
      background: 'none',
      '& .MuiButton-startIcon': {
        backgroundColor: '#1976D2',
        borderRadius: '2px',
        padding: '2px 4px',
        fontWeight: '400',
      },
    },
  },
  imageIcon: {
    height: '16px',
    width: '16px',
    cursor: 'pointer',
    color: '#1976D2',
    pointerEvents: 'all',
    marginLeft: theme.spacing(1),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    fontWeight:'600',
    display:'flex',
    alignItems:'center',
    marginBottom:theme.spacing(1),
  },
  mainTerminalOnlyWrapper:{
    '& > .MuiBox-root':{
      marginTop:'16px',
    },
    '& > .MuiBox-root:first-child':{
      marginTop:'0'
    }    
  },
  heading:{
    fontSize: '14px',
    fontWeight: 600,
    margin: '12px 0px 8px 0'
  },
  costFields:{
    display: 'flex',
    gap: '10px'
  },
}));

import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { RootDataStore } from '@uplink-shared/layout';
import { VendorLocationAddressModel } from './../VendorLocationAddress.model';
import { Airports } from './../Airports.model';
import { SettingBaseModel } from './../SettingBase.model';
import { WorldEventSpecialConsiderationModel } from './../WorldEventSpecialConsideration.model';
import { CityModel, CountryModel, StateModel } from '@uplink/shared';
import { AuthStore } from '@uplink-shared/security';
import { WorldEvents } from '../WorldEvents.model';

@modelProtection
export class RefDataWorldEvents extends CoreModel {
  id: number = 0;
  name: string = '';
  worldEventId: number = 0;
  description: string = '';
  worldEventType: SettingBaseModel = new SettingBaseModel();
  worldEventCategory: SettingBaseModel = new SettingBaseModel();
  url: string = '';
  startDate: Date = new Date();
  endDate: Date = new Date();
  beginPlanning: number = 0;
  worldEventEffectType: SettingBaseModel = new SettingBaseModel();
  specialConsideration: WorldEventSpecialConsiderationModel[] = [];
  worldEventAirports: Array<Airports> = new Array<Airports>();
  cities: Array<CityModel> = new Array<CityModel>();
  states: Array<StateModel> = new Array<StateModel>();
  countries: Array<CountryModel> = new Array<CountryModel>();
  countries: Array<CountryModel> = new Array<CountryModel>();
  regions: Array<CountryModel> = new Array<CountryModel>();
  status: SettingBaseModel =  new SettingBaseModel();
  vendorLocationId: number = 0;

  constructor(data?: Partial<RefDataWorldEvents>) {
    super(data);
    Object.assign(this, data);
  }

  static deserializeToVMSModel(apiData: RefDataWorldEvents): WorldEvents {
    if (!apiData) {
      return new WorldEvents();
    }
    const data: Partial<WorldEvents> = {
      id: 0,
      worldEventId: apiData?.worldEventId,
      name: apiData.name,
      description: apiData.description,
      beginPlanning: apiData.beginPlanning || null,
      eventType: SettingBaseModel.deserialize({
        id: apiData.worldEventType?.worldEventTypeId,
        name: apiData.worldEventType?.name,
      }),
      eventCategory: SettingBaseModel.deserialize({
        id: apiData.worldEventCategory?.worldEventCategoryId,
        name: apiData.worldEventCategory?.name,
      }),
      worldEventEffectType:this.getEventEffactType(apiData),
      url: apiData.url,
      startDate: apiData.eventSchedule?.startDate,
      endDate: apiData.eventSchedule?.endDate,
      specialConsideration: apiData.appliedWorldEventSpecialConsiderations?.length>0 ? 
        WorldEventSpecialConsiderationModel
          .deserializeListToVMSModel(apiData.appliedWorldEventSpecialConsiderations):null,
      countryReference: apiData.countries?.length>0? CountryModel.deserialize(apiData.countries[0]):null,
      cityReference: apiData.cities?.length>0? CityModel.deserialize(apiData.cities[0]):null,
      stateReference: apiData.states?.length>0? StateModel.deserialize(apiData.states[0]):null,
      airportReference:apiData.worldEventAirports?.length>0? Airports.deserialize(apiData.worldEventAirports[0]):null,
    };
    return new WorldEvents(data);
  }

  static getEventEffactType(apiData: RefDataWorldEvents) {
    if (apiData.countries?.length > 0  && 
      apiData.worldEventAirports?.length > 0) {
      return SettingBaseModel.deserialize({ name: 'Airport', id: 1 });
    }
    if (apiData.countries?.length > 0  && 
      apiData.cities?.length >0) {
      return SettingBaseModel.deserialize({ name: 'City', id: 2 });
    }
    if (apiData.countries?.length > 0  && 
      apiData.states?.length >0) {
      return SettingBaseModel.deserialize({ name: 'Department', id: 3 });
    }
    if (apiData.countries?.length > 0) {
      return SettingBaseModel.deserialize({ name: 'Country', id: 4 });
    }
  }

  static deserializeListToVMSModel(apiDataList: RefDataWorldEvents[]): WorldEvents[] {
    return apiDataList ? apiDataList.map(apiData => RefDataWorldEvents.deserializeToVMSModel(apiData)) : [];
  }
}

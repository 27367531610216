import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { VendorLocationModel } from '../VendorLocation.model';
import { SettingBaseModel } from '../SettingBase.model';
import { RootDataStore } from '@uplink-shared/layout';
import { Airports } from '../Airports.model';
import { VendorLocationAddressModel } from '../VendorLocationAddress.model';

@modelProtection
export class RefDataBulletins extends CoreModel implements ISelectOption {
  id: number = 0;
  bulletinId: number = 0;
  bulletinLevel: SettingBaseModel = new SettingBaseModel();
  bulletinEntity: VendorLocationModel = new VendorLocationModel();
  bulletinType: SettingBaseModel = new SettingBaseModel();
  startDate: Date = new Date();
  endDate: Date = new Date();
  isUFN: boolean = false;
  bulletinText: string = '';
  notamNumber: string = '';
  airportReference: Airports = new Airports();
  status: SettingBaseModel = new SettingBaseModel();

  constructor(data?: Partial<RefDataBulletins>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize(entityValue: VendorLocationModel, statusId: number): RefDataBulletins {
    return {
      userId: this.userId || '',
      id: this.id || 0,
      bulletinId: this.bulletinId || 0,
      bulletinLevelId: this.bulletinLevel.id,
      bulletinLevelName: this.bulletinLevel.name,
      bulletinEntityId: this.bulletinEntity?.id || entityValue.id,
      bulletinEntityCode: this.bulletinEntity?.code || entityValue.code,
      bulletinEntityName: this.bulletinEntity?.name || entityValue.name,
      bulletinTypeId: this.bulletinType?.id,
      bulletinTypeName: this.bulletinType?.name,
      startDate: this.startDate,
      endDate: !this.isUFN ? this.endDate : null,
      isUFN: this.isUFN || false,
      bulletinText: this.bulletinText,
      notamNumber: this.notamNumber,
      airportId: RootDataStore.locationData.airportReference.id,
      statusId: statusId || this.statusId || null,
    };
  }

  static deserialize(apiData: RefDataBulletins): RefDataBulletins {
    if (!apiData) {
      return new RefDataBulletins();
    }
    const data: Partial<RefDataBulletins> = {
      ...apiData,
      id: apiData?.bulletinId,
      bulletinId: apiData?.bulletinId,
      bulletinLevel: SettingBaseModel.deserialize({
        ...apiData.bulletinLevel,
        id: apiData.bulletinLevel?.bulletinLevelId,
      }),
      bulletinEntity: VendorLocationModel.deserialize({
        ...apiData.bulletinEntity,
        id: apiData.bulletinEntity?.bulletinEntityId
      }),
      bulletinType: SettingBaseModel.deserialize({
        ...apiData.appliedBulletinTypes[0]?.bulletinType,
        id: apiData.appliedBulletinTypes[0]?.bulletinType?.bulletinTypeId,
      }),
      startDate: apiData.startDate,
      endDate: apiData.endDate,
      notamNumber: apiData.notamNumber,
      isUFN: apiData.isUFN,
      bulletinText: apiData.bulletinText,
      status: SettingBaseModel.deserialize({
        ...apiData?.status,
        id: apiData.status?.statusId
      }),
    };
    return new RefDataBulletins(data);
  }

  static deserializeListToVMSModel(apiDataList: RefDataBulletins[]): RefDataBulletins[] {
    return apiDataList ? apiDataList.map(apiData => RefDataBulletins.deserialize(apiData)) : [];
  }

  static getBulletinEntityId(
    isAirportBulletin: boolean,
    isCountryBulletin: boolean,
    bulletinLevelId: number,
    locationAddress: VendorLocationAddressModel
  ) {
    const locationData = RootDataStore.locationData;
    if (isAirportBulletin && bulletinLevelId == 3) {
      return locationAddress?.cityReference?.id;
    }

    if (isAirportBulletin && bulletinLevelId == 1) {
      return locationData.airportReference.id;
    }
    if (isCountryBulletin && bulletinLevelId == 4) {
      return locationAddress?.countryReference?.countryId;
    }
    if (isCountryBulletin && bulletinLevelId == 5) {
      return locationAddress?.stateReference?.stateId;
    }
  }

  static getBulletinEntityName(
    isAirportBulletin: boolean,
    isCountryBulletin: boolean,
    bulletinLevelId: number,
    locationAddress: VendorLocationAddressModel,
    airportData: Airports
  ) {
    if (isAirportBulletin && bulletinLevelId == 3) {
      return locationAddress?.cityReference?.commonName;
    }

    if (isAirportBulletin && bulletinLevelId == 1) {
      return airportData.airportName;
    }
    if (isCountryBulletin && bulletinLevelId == 4) {
      return locationAddress?.countryReference?.name;
    }
    if (isCountryBulletin && bulletinLevelId == 5) {
      return locationAddress?.stateReference?.name;
    }
  }

  static getBulletinEntityCode(
    isAirportBulletin: boolean,
    isCountryBulletin: boolean,
    bulletinLevelId: number,
    locationAddress: VendorLocationAddressModel,
    airportData: Airports
  ) {
    if (isAirportBulletin && bulletinLevelId == 3) {
      return locationAddress?.cityReference?.cappsCode;
    }
    if (isAirportBulletin && bulletinLevelId == 1) {
      return airportData.displayCode;
    }
    if (isCountryBulletin && bulletinLevelId == 4) {
      return locationAddress?.countryReference?.code;
    }
    if (isCountryBulletin && bulletinLevelId == 5) {
      return locationAddress?.stateReference?.code;
    }
  }

  public get label(): string {
    return this.bulletinLevel.name;
  }

  public get value(): string | number {
    return this.id;
  }
}

import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';

const UserRoleDefinations: FC = () => {
  return (
    <>
      <Box mb={3} mt={2}>
        <div>
          <strong>Information Ambassador : </strong> 
            Can view all data at the vendor level but can only edit 
            information for the locations to which they are assigned.
        </div>
      </Box>
      <Box mb={1}>
        <div>
          <strong>Corporate Authorized Agent : </strong> 
            Can view and edit all information for the vendor and any 
            location in which that vendor may operate.
        </div>
      </Box>
      <Box>To assign additional Corporate Authorized Agents to your account 
        please reach out to XXXXXX@Univ-wea.com
      </Box>
    </>
  );
};

export default UserRoleDefinations;

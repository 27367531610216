import React, { FC, ReactNode, useEffect, useState } from 'react';
import { CabotageStore, SettingsStore, VendorManagementStore, EventsStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { CountryModel, NO_SQL_COLLECTIONS, useBaseUpsertComponent } from '@uplink/shared';
import {
  UIStore,
  IAPISearchFiltersDictionary,
  IOptionValue,
  IClasses,
  IAPIGridRequest,
  IAPIPageResponse,
} from '@wings-shared/core';
import { forkJoin } from 'rxjs';
import {
  SettingBaseModel,
  ViewInputControls,
  SidebarMenuOperationalInfo,
  COLLECTION_NAMES,
  CustomDialog,
  CabotageModel,
  VendorLocationAddressModel,
} from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { DetailsEditorWrapper, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { Box, Button, Switch, Typography, withStyles } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { styles } from './Cabotage.styles';
import { fields } from './Fields';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { ModalStore } from '@uvgo-shared/modal-keeper';

interface Props {
  classes?: IClasses;
  settingsStore: SettingsStore;
  cabotageStore: CabotageStore;
  vendorManagementStore: VendorManagementStore;
  searchFilters: IAPISearchFiltersDictionary;
  eventsStore: EventsStore;
}

const Cabotage: FC<Props> = ({
  settingsStore,
  cabotageStore,
  vendorManagementStore,
  classes,
  searchFilters,
  eventsStore,
}) => {
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();
  const [ isCabotageEnforced, setIsCabotageEnforced ] = useState(false);
  const useUpsert = useBaseUpsertComponent<CabotageModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const [ countryList, setCountryList ] = useState<CountryModel[]>([]);
  const gridState = useGridState();
  const [ isCountryRegionEmpty, setIsCountryRegionEmpty ] = useState(false);

  useEffect(() => {
    SidebarStore.setNavLinks(
      SidebarMenuOperationalInfo(),
      'vendor',
      'Operational Information',
      '/vendor/locations',
      'panel2'
    );
    loadDropDownData();
    loadLocationAddress();
    setFormPlaceholders();
    cabotageStore.formValueChanged = false;
    cabotageStore.countryOrRegionError = false;
  }, []);

  const setFormPlaceholders = () => {
    const placeholders = {
      'appliedRegionCabotageExemptions': 'Search for cabotage affected region or country',
      'appliedCountryCabotageExemptions': 'Search for cabotage affected country',
    };
  
    Object.keys(placeholders).forEach(field => {
      const fieldElement = useUpsert.getField(field);
      if (fieldElement) {
        fieldElement.$placeholder = placeholders[field];
      }
    });
  
    console.log('Placeholders have been set');
  };

  const loadDropDownData = () => {
    UIStore.setPageLoader(true);
    forkJoin([
      settingsStore.getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.REGION),
      settingsStore.getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.FARTYPE),
      vendorManagementStore.getVmsCountryCode(),
      settingsStore.getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.FARTYPE),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(
        (response: [SettingBaseModel[], SettingBaseModel[], IAPIPageResponse<CountryModel>, SettingBaseModel[]]) => {
          setCountryList(response[2].results);
        }
      );
  };

  const loadLocationAddress = () => {
    UIStore.setPageLoader(true);
    const cityAddressRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
        },
      ]),
    };
    eventsStore
      .getVMSComparison(COLLECTION_NAMES.VENDOR_LOCATION_ADDRESS, cityAddressRequest)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: IAPIPageResponse<VendorLocationAddressModel>) => {
        if (response.results?.length > 0) {
          loadInitialData();
        }
      });
  };

  const filterCabotageAppliesData = (selectedValue?: SettingBaseModel) => {
    if (!selectedValue || !selectedValue.label) {
      return;
    }
    cabotageStore.filteredCabotageFarTypes = cabotageStore.getCabotageSettingOptions<SettingBaseModel>(
      settingsStore.reffDataEnforcedForFARTypeList.filter(item => {
        const lowerCaseName = selectedValue?.label?.toLocaleLowerCase();
        const itemName = item.name?.toLocaleLowerCase() || '';

        if (lowerCaseName === 'private') {
          return itemName.includes('private');
        } else if (lowerCaseName === 'charter') {
          return !itemName.includes('private');
        } else if (lowerCaseName === 'both') {
          return true;
        }

        return false;
      }),
      'farType'
    );
  };

  const setFormValues = (data: CabotageModel) => {
    const item = CabotageModel.deserialize(data);
    const filterFarTypes = keyword => {
      return item.cabotageEnforcedForFARTypes.filter(farType => farType.name.toLocaleLowerCase().includes(keyword));
    };

    const farTypeCategoryPrivate = filterFarTypes('private');
    const farTypeCategoryCharter = filterFarTypes('charter');

    useUpsert.setFormValues(item);
    if (item.cabotageExemptionLevel?.id === 1) {
      useUpsert
        .getField('cabotageExemptionLevel')
        .set(SettingBaseModel.deserialize(CabotageModel.getCabotageApplyList()[0]));
      if (item.appliedCountryCabotageExemptions.length === 0) {
        cabotageStore.countryOrRegionError = true;
      } else {
        cabotageStore.countryOrRegionError = false;
      }
    } else if (item.cabotageExemptionLevel?.id === 2) {
      useUpsert
        .getField('cabotageExemptionLevel')
        .set(SettingBaseModel.deserialize(CabotageModel.getCabotageApplyList()[1]));
      if (item.appliedRegionCabotageExemptions.length === 0) {
        cabotageStore.countryOrRegionError = true;
      } else {
        cabotageStore.countryOrRegionError = false;
      }
    } else {
      useUpsert
        .getField('cabotageExemptionLevel')
        .set(SettingBaseModel.deserialize(CabotageModel.getCabotageApplyList()[0]));
      if (item.appliedCountryCabotageExemptions.length === 0) {
        cabotageStore.countryOrRegionError = true;
      } else {
        cabotageStore.countryOrRegionError = false;
      }
    }

    if (farTypeCategoryPrivate.length > 0 && farTypeCategoryCharter.length > 0) {
      useUpsert
        .getField('cabotageEnforcedForFARTypes')
        .set(SettingBaseModel.deserialize(CabotageModel.getCabotageEnforcedForFARTypeOptions()[2]));
      filterCabotageAppliesData(CabotageModel.getCabotageEnforcedForFARTypeOptions()[2]);
    } else if (farTypeCategoryPrivate.length > 0) {
      useUpsert
        .getField('cabotageEnforcedForFARTypes')
        .set(SettingBaseModel.deserialize(CabotageModel.getCabotageEnforcedForFARTypeOptions()[1]));
      filterCabotageAppliesData(CabotageModel.getCabotageEnforcedForFARTypeOptions()[1]);
    } else if (farTypeCategoryCharter.length > 0) {
      useUpsert
        .getField('cabotageEnforcedForFARTypes')
        .set(SettingBaseModel.deserialize(CabotageModel.getCabotageEnforcedForFARTypeOptions()[0]));
      filterCabotageAppliesData(CabotageModel.getCabotageEnforcedForFARTypeOptions()[0]);
    }

    cabotageStore.formValueChanged = false;
    setIsCabotageEnforced(data?.isCabotageEnforced);
    useUpsert.getField('isImportationFeesforDomesticFlight').set(data.isImportationFeesforDomesticFlight ? 1 : 2);
    useUpsert.getField('isCustomsStopsExempt').set(data.isCustomsStopsExempt ? 1 : 2);
    useUpsert.getField('isPaxMustDepartwithSameOperator').set(data.isPaxMustDepartwithSameOperator ? 1 : 2);
    useUpsert.getField('isNoNewPaxAllowedtoDepart').set(data.isNoNewPaxAllowedtoDepart ? 1 : 2);
    useUpsert.getField('isCabotageAppliestoLivestock').set(data.isCabotageAppliestoLivestock ? 1 : 2);
    useUpsert.getField('isCabotageAppliestoCargo').set(data.isCabotageAppliestoCargo ? 1 : 2);
    useUpsert.getField('isCabotageAppliestoNonResidents').set(data.isCabotageAppliestoNonResidents ? 1 : 2);
  };

  const loadInitialData = () => {
    const countryId = eventsStore.locationAddress && eventsStore.locationAddress[0]?.countryReference?.countryId;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'CountryReference.CountryId',
          propertyValue: countryId,
        },
      ]),
    };
    cabotageStore
      .getCabotageData(COLLECTION_NAMES.CABOTAGE, request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: CabotageModel[]) => {
        if (response.results.length > 0) {
          const data = response.results[0];
          setFormValues(data);
        }
      });
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    cabotageStore.formValueChanged = true;
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'cabotageExemptionLevel':
        if (value?.id === 1) {
          setIsCountryRegionEmpty(false);
        } else if (value?.id === 2) {
          setIsCountryRegionEmpty(false);
        } else {
          setIsCountryRegionEmpty(true);
          useUpsert.getField('appliedRegionCabotageExemptions').set([]);
          useUpsert.getField('appliedCountryCabotageExemptions').set([]);
        }
        break;
      case 'cabotageEnforcedForFARTypes':
        if (value) {
          filterCabotageAppliesData(value);
        }
        break;
      default:
        break;
    }
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'appliedDisinsectionCountries':
        const filteredList = vendorManagementStore.countries.filter(country => {
          return (
            country.commonName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            country.isO2Code?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setCountryList(filteredList);
        break;
      default:
        break;
    }
    return;
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'Aircraft Logistics:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'vendorLocation',
            type: EDITOR_TYPES.DROPDOWN,
            isHidden: true,
          },
          {
            fieldKey: 'cabotageExemptionLevel',
            type: EDITOR_TYPES.DROPDOWN,
            options: CabotageModel.getCabotageApplyList(),
            isHalfFlex: true,
          },
          {
            fieldKey: 'appliedCountryCabotageExemptions',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: cabotageStore.getCabotageSettingOptions<CountryModel>(countryList, 'country'),
            multiple: true,
            isHalfFlex: true,
            disableCloseOnSelect: true,
            isDisabled: isCountryRegionEmpty,
            isHidden:
              useUpsert.getField('cabotageExemptionLevel').value?.id === 2 ||
              !useUpsert.getField('cabotageExemptionLevel').value,
          },
          {
            fieldKey: 'appliedRegionCabotageExemptions',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: cabotageStore.getCabotageSettingOptions<SettingBaseModel>(
              settingsStore.reffDataRegionExemptionList,
              'region'
            ),
            multiple: true,
            isHalfFlex: true,
            disableCloseOnSelect: true,
            isDisabled: isCountryRegionEmpty,
            isHidden: useUpsert.getField('cabotageExemptionLevel').value?.id === 1,
          },
          {
            fieldKey: 'cabotageEnforcedForFARTypes',
            type: EDITOR_TYPES.DROPDOWN,
            options: cabotageStore.getCabotageSettingOptions<SettingBaseModel>(
              CabotageModel.getCabotageEnforcedForFARTypeOptions(),
              'farType'
            ),
            isFullFlex: true,
          },
          {
            fieldKey: 'isImportationFeesforDomesticFlight',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'isCustomsStopsExempt',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'isPaxMustDepartwithSameOperator',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'isNoNewPaxAllowedtoDepart',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'isCabotageAppliestoLivestock',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'isCabotageAppliestoCargo',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'isCabotageAppliestoNonResidents',
            type: EDITOR_TYPES.RADIO,
            showLabel: true,
            selectControlOptions: CabotageModel.getYesNo(),
            defaultValue: 1,
          },
          {
            fieldKey: 'cabotageOperationalRequirementId',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
        ],
      },
    ];
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'appliedDisinsectionCountries':
        setCountryList(vendorManagementStore.countries);
        break;
      default:
        break;
    }
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const isInValid = (): boolean => {
    if (isCabotageEnforced) {
      return !formRef.isValid || formRef.hasError || !cabotageStore.formValueChanged;
    } else {
      return !cabotageStore.formValueChanged;
    }
  };

  const getConfirmation = (): void => {
    ModalStore.open(
      <CustomDialog
        title="Confirm changes"
        message={'Canceling will lost your changes. Are you sure you want to cancel?'}
        yesButton="Confirm"
        noButton="Cancel"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          navigate('/vendor/locations');
        }}
      />
    );
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">Aircraft Spray Requirements</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => getConfirmation()}
              disabled={!cabotageStore.formValueChanged}
              size="large"
            >
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={isInValid()}
              onClick={() => upsertCabotage()}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const upsertCabotage = (): void => {
    const countryId = eventsStore.locationAddress && eventsStore.locationAddress[0]?.countryReference?.countryId;
    const request = new CabotageModel({
      ...useUpsert.form.values(),
      isCabotageEnforced: isCabotageEnforced,
      countryReferenceId: countryId || null,
      cabotageEnforcedForFARTypes: cabotageStore.filteredCabotageFarTypes,
      appliedRegionCabotageExemptions:
        useUpsert.getField('cabotageExemptionLevel').value === 1
          ? []
          : useUpsert.getField('appliedRegionCabotageExemptions').value || [],
      appliedCountryCabotageExemptions:
        useUpsert.getField('cabotageExemptionLevel').value === 2
          ? []
          : useUpsert.getField('appliedCountryCabotageExemptions').value || [],
    });
    UIStore.setPageLoader(true);
    cabotageStore
      ?.upsertCabotage(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: CabotageModel) => {
          useUpsert.form.reset();
          setFormValues(CabotageModel.deserialize(response));
        },
        error: error => {
          if (error?.response?.data?.errors) {
            errorHandler(error?.response?.data?.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error?.message, request.id.toString());
        },
      });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cabotageLevelVal = useUpsert.getField('cabotageExemptionLevel').value;
    cabotageStore.formValueChanged = true;
    setIsCabotageEnforced(Boolean(event.target.checked));
    setIsCountryRegionEmpty(!cabotageLevelVal?.id);
  };

  return (
    <>
      <DetailsEditorWrapper
        headerActions={headerActions()}
        isEditMode={true}
        classes={{ headerActions: classes.headerActions }}
      >
        <div className={classes.editorWrapperContainer}>
          <Typography className={classes.heading}>Is Cabotage Enforced?</Typography>
          <div className={classes.toggle}>
            <Typography>No</Typography>
            <Switch checked={isCabotageEnforced} onChange={handleSwitchChange} color="primary" name="switch" />
            <Typography>Yes</Typography>
          </div>
          {isCabotageEnforced && (
            <ViewInputControls
              isEditable={true}
              groupInputControls={groupInputControls()}
              onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
              onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
              field={fieldKey => useUpsert.getField(fieldKey)}
              onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
              onFocus={fieldKey => onFocus(fieldKey)}
            />
          )}
        </div>
      </DetailsEditorWrapper>
    </>
  );
};

export default inject(
  'settingsStore',
  'cabotageStore',
  'vendorManagementStore',
  'eventsStore'
)(withStyles(styles)(observer(Cabotage)));

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { VendorManagementStore, VendorUserStore } from '../../Stores';
import { inject, observer } from 'mobx-react';
import { Box, FormControl, InputAdornment, OutlinedInput, withStyles, IconButton } from '@material-ui/core';
import { styles } from './VendorList.styles';
import {
  CustomHeader,
  VendorLocationModel,
  VendorModuleSecurity,
  VENDOR_LOCATION_COMPARISON_FILTERS,
  VENDOR_LOCATION_DATA_FILTER,
  SidebarMenus,
  InfiniteScroll,
  CustomList,
  VendorManagmentModel,
} from '../Shared';
import { IClasses, UIStore } from '@wings-shared/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { useNavigate } from 'react-router';
import { AuthStore } from '@uplink-shared/security';
import { CloseIcon, RefreshIcon, SearchIcon } from '@uvgo-shared/icons';
import { debounce } from 'lodash-es';

type Props = {
  vendorManagementStore: VendorManagementStore;
  vendorUserStore: VendorUserStore;
  classes: IClasses;
};

const VendorList: FC<Props> = ({ vendorManagementStore, vendorUserStore, classes }) => {
  const navigate = useNavigate();
  const unsubscribe = useUnsubscribe();
  const [ searchValue, setSearchValue ] = useState('');
  const [ vendorUserList, setVendorUserList ] = useState(vendorUserStore?.vendorUser ?? []);
  const isEditable = AuthStore.user.isCorporateAuthorizedAgent;

  useEffect(() => {
    setVendorUserList(vendorUserStore?.vendorUser ?? []);
  }, [ vendorUserStore?.vendorUser ]);

  const onSearch = query => {
    const trimmedQuery = query.trim().toLowerCase();
    if (!vendorUserStore?.vendorUser) return;

    const filteredList = vendorUserStore.vendorUser.filter(item => item.name?.toLowerCase().includes(trimmedQuery));

    setVendorUserList(filteredList);
  };

  const debouncedSearch = useCallback(
    debounce(query => {
      onSearch(query);
    }, 300),
    [ vendorUserStore?.vendorUser ]
  );

  const handleChange = value => {
    setSearchValue(value);
    debouncedSearch(value);
  };

  const handleClear = () => {
    setSearchValue('');
    setSearchValue('');
    setVendorUserList(vendorUserStore?.vendorUser ?? []);
  };

  useEffect(() => {
    AuthStore.setVendor(
      AuthStore.vendorProfile.id ? true : false,
      AuthStore.vendorProfile.id,
      AuthStore.vendorProfile.name,
      AuthStore.vendorProfile.code
    );
    if (!AuthStore.vendorProfile.isVendorSelected) {
      RootDataStore.setLocation(
        false,
        0,
        '',
        '',
        null,
        '',
        false,
        false,
        false,
        RootDataStore.locationData.hasMultipleVendor || true
      );
    }
    vendorManagementStore.hasDataLoaded = false;
    SidebarStore.setNavLinks(SidebarMenus(), 'vendor');
    loadInitialData();
  }, [ AuthStore.vendorProfile.id ]);

  useEffect(() => {
    if (vendorUserStore.vendorUser.length === 1) {
      RootDataStore.setLocation(false, 0, '', '', null, '', false, false, false, false);
    } else {
      RootDataStore.setLocation(false, 0, '', '', null, '', false, false, false, true);
    }
  }, [ vendorUserStore.vendorUser?.length ]);
  
  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    vendorUserStore
      .getVendorUserByVendorId(AuthStore.user.sub,AuthStore?.user?.preferred_username)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe();
  };

  const colDefNew = [
    {
      headerName: 'Code',
      field: 'code',
    },
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'City',
      field: 'vendorAddress.cityReference.name',
    },
    {
      headerName: 'Country',
      field: 'vendorAddress.countryReference.name',
    },
    {
      field: 'actionRenderer',
      headerName: '\u00A0\u00A0\u00A0\u00A0Edit\u00A0\u00A0\u00A0\u00A0',
    },
  ];

  const onEdit = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    AuthStore.setVendor(true, item.id, item.name, item.code);
    navigate('/vendor/general-info');
  };

  const handleVendorSelect = (item: VendorManagmentModel) => {
    RootDataStore.setLocation(
      false,
      0,
      '',
      '',
      null,
      '',
      false,
      false,
      false,
      RootDataStore.locationData.hasMultipleVendor || true
    );
    if (AuthStore.vendorProfile.isVendorSelected && AuthStore.vendorProfile.id == item?.id) {
      AuthStore.setVendor(false, 0, '', '');
    } else {
      AuthStore.setVendor(true, item.id, item.name, item.code);
    }
    SidebarStore.setNavLinks(SidebarMenus(), 'vendor');
  };

  return (
    <>
      <Box>
        <CustomHeader title={'Vendor List'} />
        <FormControl fullWidth variant="outlined" className={classes.customHeader}>
          <OutlinedInput
            placeholder="Search Vendor Name"
            value={searchValue}
            onChange={e => handleChange(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                {searchValue && (
                  <IconButton onClick={handleClear} size="small">
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
            labelWidth={60}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                onSearch(searchValue);
              }
            }}
            style={{ width: '30%' }}
          />
          <IconButton
            onClick={() => {
              AuthStore.removeVendor();
              RootDataStore.setLocation(
                false,
                0,
                '',
                '',
                null,
                '',
                false,
                false,
                false,
                RootDataStore.locationData.hasMultipleVendor || true
              );
              loadInitialData();
            }}
            size="small"
            className={classes.refreshIcon}
          >
            <RefreshIcon />
          </IconButton>
        </FormControl>
        <CustomList
          classes={classes}
          colDef={colDefNew}
          rowData={vendorUserList}
          isHeaderVisible={false}
          showEditButton={isEditable}
          onEdit={(item, e) => onEdit(item, e)}
          isContact={false}
          isVendors={true}
          showDeleteButton={false}
          isLocationSelected={item => handleVendorSelect(item)}
          selectedItemId={AuthStore.vendorProfile.id}
          isLoading={UIStore.pageLoading}
        />
      </Box>
    </>
  );
};

export default inject('vendorManagementStore', 'vendorUserStore')(withStyles(styles)(observer(VendorList)));

import { createStyles, Theme , lighten } from '@material-ui/core';
import { CheckCircle, Height } from '@material-ui/icons';
import { pad } from 'lodash-es';

export const styles = createStyles((theme: Theme) => ({
  dialogContainerWidth:{
    '& .MuiDialog-paperWidthSm':{
      width:'420px'
    }
  },
  tooltipDialogWidth:{
    '& .MuiDialog-paperWidthSm':{
      maxWidth:'632px',
      padding:'32px'
    }
  },
  crossIcon: {
    '&:hover': {
      backgroundColor:'transparent !important',
    },
    '& .MuiIconButton-label:hover':{
      color:'#63666a !important'
    }
  },
  crossIcon2: {
    '&:hover': {
      backgroundColor:'transparent !important',
    },
    '& .MuiIconButton-label:hover':{
      color:'#63666a !important'
    },
    '& .MuiIconButton-root':{
      padding:'0px !important'
    },
  },
  editorWrapperContainer: {
    '& .MuiFormLabel-root': {
      fontSize: '12px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-adornedEnd svg': {
      width: '16px !important',
      height: '16px !important',
    },
    '& .MuiChip-label': { 
      fontSize: '14px', 
      paddingTop: '2px' 
    },
    '& .MuiChip-colorPrimary': { 
      backgroundColor: '#004ba0' 
    },
  },
  primaryUploadButton: {
    '& button:hover': {
      backgroundColor: 'rgba(99, 164, 255, 0.1)',
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
    },
  },
  imageIcon: {
    height: '16px',
    width: '16px',
    cursor: 'pointer',
    color: '#1976D2',
    pointerEvents: 'all',
    marginLeft: theme.spacing(1),
    '& MuiSvgIcon-root':{
      fontWeight: 'normal !important'
    }
  },
  checkCircleIcon:{
    color:'green',
    width:'18px',
    height:'18px',
  },
  img:{
    borderRadius:'6px',
    paddingLeft:'2px',
    paddingRight:'2px'

  },
  flexRow: {
    display: 'flex',
    flex: 1,
    width: '100%',
    overflowY: 'auto',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingBottom: '0px !important',
  },
  flexWrap: {
    flexWrap: 'wrap',
    display: 'flex',
    overflow: 'auto',
  },
  title:{
    paddingBottom: theme.spacing(3),
    fontWeight: 600
  },
  inputControl: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    flexBasis: '25%',
  },
  imageLoop:{
    display:'grid',
    gridTemplateColumns:'repeat(4, 1fr)',
    gap:'2px',
    justifyItems:'center'
  },
  impText:{
    paddingLeft:'10px',
    fontSize:'14px'
  },
  tick:{
    display:'flex',
  },
  downloadIcon:{
    color: '#1976D2' ,
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '4px',
      backgroundColor:'rgba(0, 0, 0, 0.05)',
    },
  },
  boxFile: {
    height: '38px',
    width: '348px',
    margin: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '4px',
    paddingBottom: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: '2px',
    borderRadius: '4px',
    borderStyle: 'dashed',
    borderColor: `${lighten(theme.palette.divider, 0.6)}`,
    backgroundColor: theme.palette.background.paper,
  },
  imageContainer:{
    width: '83px',
    height: '56px',
    overflow: 'hidden',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    marginLeft: '10px',
  },
  image:{
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  
  iconText:{
    display:'flex',
    flexDirection:'column',
    marginTop:'20px',
    marginBottom:'20px',
    gap:'10px', 
  },
  uploadingguide: {
    fontSize: '14px',
    fontWeight: '600',
  },
  customButton: {
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      width: '98%',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  uploadedFileBox: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
  },
  headerActions: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    '& button': {
      width: '150px',
      marginRight: '10px',
    },
    '& h5': {
      fontSize: '1.625rem',
    },
    '& .MuiTypography-h6': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      whiteSpace: 'nowrap',
      fontSize: '1.625rem',
    },
  },
  defaultButton: {
    marginLeft: '16px',
    '& button': {
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      width: '140px',
      minWidth: '140px',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
    },
    '& button:hover': {
      backgroundColor: '#63A4FF',
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
    },
  },
  imageIcon: {
    height: '15px',
    width: '15px',
    cursor: 'pointer',
    color: '#1976D2',
    pointerEvents: 'all',
    marginLeft: theme.spacing(1),
  },
}));

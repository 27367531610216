import { HttpClient, NO_SQL_COLLECTIONS, baseApiPath } from '@uplink/shared';
import { observable, action } from 'mobx';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@wings-shared/security';
import { IAPIGridRequest, IAPIPageResponse, Utilities } from '@wings-shared/core';
import { refDataHeaders, vendorManagementHeaders } from './Base.store';
import { IAPIVMSComparison } from '../Modules/Shared/Interfaces';
import { apiUrls } from './API.url';
import { RefDataAiportHoursModel, VendorLocationModel } from '../Modules/Shared';
import { LocationHoursModel } from '../Modules/Shared/Models/LocationHours.model';

export class VendorLocationHoursStore {
  @observable public locationHoursList: LocationHoursModel[] = [];
  @observable public airportHoursList: LocationHoursModel[] = [];
  @observable public refDataAirportHoursList: RefDataAiportHoursModel[] = [];
  @observable public quarantineAirportHoursList: LocationHoursModel[] = [];
  @observable public updatedHoursData: LocationHoursModel[] = [];
  @observable public timeDataHoursData: LocationHoursModel[] = [];
  @observable public overTimeHoursData: LocationHoursModel[] = [];

  @action
  public getVendorLocationHours(pageRequest?: IAPIGridRequest): Observable<IAPIPageResponse<VendorLocationModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'VendorLocation',
      ...pageRequest,
    });

    return http.get<IAPIPageResponse<IAPIVMSComparison>>(`${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => {
        this.locationHoursList = LocationHoursModel.deserializeList(response.results[0]?.vendorLocationHours);
        this.locationHoursList = this.locationHoursList.filter(item => item.hoursScheduleType.id === 1);
        return { ...response, results: this.locationHoursList };
      })
    );
  }

  @action
  public getAirportHours(
    pageRequest?: IAPIGridRequest,
    isQuarantine?: boolean
  ): Observable<IAPIPageResponse<LocationHoursModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'AirportHoursReference',
      ...pageRequest,
    });
    return http.get<IAPIPageResponse<IAPIVMSComparison>>(`${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => {
        if (isQuarantine) {
          this.quarantineAirportHoursList = LocationHoursModel.deserializeList(response.results);
          this.quarantineAirportHoursList = this.quarantineAirportHoursList.filter(item => item.status?.id !== 2);
          return { ...response, results: this.quarantineAirportHoursList };
        } else {
          this.airportHoursList = LocationHoursModel.deserializeList(response.results);
          this.airportHoursList = this.airportHoursList.filter(item => item.status?.id !== 2);
          return { ...response, results: this.airportHoursList };
        }
      })
    );
  }

  @action
  public getrefDataAirportHours(pageRequest?: IAPIGridRequest): Observable<IAPIPageResponse<RefDataAiportHoursModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.noSqlData,
      headers: refDataHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: NO_SQL_COLLECTIONS.AIRPORT_HOUR,
      ...pageRequest,
    });

    return http.get<IAPIPageResponse<RefDataAiportHoursModel>>(`/${apiUrls.refData}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => {
        this.refDataAirportHoursList = RefDataAiportHoursModel.deserializeList(response.results);
        return { ...response, results: this.refDataAirportHoursList };
      })
    );
  }

  @action
  public updateVendorLocationHour(payload: LocationHoursModel[]): Observable<LocationHoursModel[]> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    const upsertRequest: Observable<LocationHoursModel[]> = http.put<any>(
      `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocationHours}/UpdateList`,
      payload
    );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => LocationHoursModel.deserializeList(response))
    );
  }

  @action
  public addVendorLocationHour(payload: LocationHoursModel[]): Observable<LocationHoursModel[]> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    const upsertRequest: Observable<LocationHoursModel[]> = http.post<any>(
      `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocationHours}/AddList`,
      payload
    );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => LocationHoursModel.deserializeList(response))
    );
  }

  @action
  public updateAirportHours(payload: LocationHoursModel[]): Observable<LocationHoursModel[]> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    const upsertRequest: Observable<LocationHoursModel[]> = http.put<any>(
      `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.airportHoursReference}`,
      payload
    );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => LocationHoursModel.deserializeList(response))
    );
  }

  @action
  public addAirportHours(payload: LocationHoursModel[]): Observable<LocationHoursModel[]> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    const upsertRequest: Observable<LocationHoursModel[]> = http.post<any>(
      `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.airportHoursReference}`,
      payload
    );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => LocationHoursModel.deserializeList(response))
    );
  }

  @action
  public upsertAirportHour(payload: LocationHoursModel[]): Observable<LocationHoursModel[]> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    const upsertRequest: Observable<LocationHoursModel[]> = http.post<any>(
      `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.airportHoursReference}/upsert`,
      payload
    );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => LocationHoursModel.deserializeList(response))
    );
  }

  @action
  public deleteAirportHours(airportReferenceHoursIds: number[]): Observable<LocationHoursModel[]> {
    const payload = {
      userId: 'string',
      airportReferenceHoursIds,
    };
    const http = new HttpClient({ headers: vendorManagementHeaders });
    const upsertRequest: Observable<LocationHoursModel[]> = http.delete<any>(
      `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.airportHoursReference}/DeleteList`,
      payload
    );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => LocationHoursModel.deserializeList(response))
    );
  }

  @action
  public deleteLocationHours(vendorLocationHoursIds: number[]): Observable<LocationHoursModel[]> {
    const payload = {
      userId: 'string',
      vendorLocationHoursIds,
    };
    const http = new HttpClient({ headers: vendorManagementHeaders });
    const upsertRequest: Observable<LocationHoursModel[]> = http.delete<any>(
      `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocationHours}/DeleteList`,
      payload
    );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => LocationHoursModel.deserializeList(response))
    );
  }
}

import { observable, action } from 'mobx';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IAPIPageResponse } from '@wings-shared/core';
import {
  DataExpirationModel,
  DataExpirationSectionTypeModel,
  IAPIVMSVendorComparison,
  VendorDocumentModel,
} from '../Modules/Shared';
import { baseApiPath, HttpClient } from '@uplink/shared';
import { vendorManagementHeaders } from './Base.store';
import { apiUrls } from './API.url';
import { Logger } from '@uplink-shared/security';
import { DataExpirationUptoDate } from '../Modules/Shared/Models/DataExpirationUptoDate.model';

export class DataExpiryStore {
  @observable public dataExpiration: DataExpirationModel[] = [];
  @observable public dataExpirationSectionTypes: DataExpirationSectionTypeModel[] = [];
  @observable public dataExpiryState: any;
  @observable public documentNameList: VendorDocumentModel[] = [];
  @observable public inputValue: string = '';
  @observable public isExpiryDataUpdated: boolean = false;
  @observable public operationTypeId: number = 0;

  @action
  public setInputValue(value: string) {
    this.inputValue = value;
  }

  @action
  public getDataExpirationByVendorId(vendorIds: number[]): Observable<IAPIVMSVendorComparison> {
    const http: HttpClient = new HttpClient({
      headers: vendorManagementHeaders,
    });
    return http
      .post<IAPIVMSVendorComparison>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.dataExpiration}/GetVendorLocationExpiryByVendorId`,
        vendorIds
      )
      .pipe(
        Logger.observableCatchError,
        map(response => {
          this.dataExpiration = DataExpirationModel.deserializeList(response);
          return { ...response, results: this.dataExpiration };
        })
      );
  }

  @action
  public getDataExpirationSectionType(): Observable<IAPIVMSVendorComparison> {
    const http: HttpClient = new HttpClient({
      headers: vendorManagementHeaders,
    });
    return http
      .get<IAPIPageResponse<IAPIVMSVendorComparison>>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.dataExpirationSectionType}`
      )
      .pipe(
        Logger.observableCatchError,
        map(response => {
          this.dataExpirationSectionTypes = DataExpirationSectionTypeModel.deserializeList(response.results);
          return { ...response, results: this.dataExpirationSectionTypes };
        })
      );
  }

  @action
  public getDataExpirationByVendorLocationId(vendorId: number): Observable<IAPIVMSVendorComparison> {
    const http: HttpClient = new HttpClient({
      headers: vendorManagementHeaders,
    });
    return http
      .get<IAPIPageResponse<IAPIVMSVendorComparison>>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.dataExpiration}/GetVendorLocationExpiryByVendorLocationId?vendorLocationId=${vendorId}`
      )
      .pipe(
        Logger.observableCatchError,
        map(response => {
          this.dataExpiration = DataExpirationModel.deserializeList(response);
          return { ...response, results: this.dataExpiration };
        })
      );
  }

  @action
  public upsertUpToDateConfirm(payload: DataExpirationUptoDate): Observable<DataExpirationUptoDate> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .post<DataExpirationUptoDate>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.dataExpiration}/uptodateconfirm`,
        payload
      )
      .pipe(
        Logger.observableCatchError,
        map(response => {
          return { ...response, results: DataExpirationUptoDate.deserialize(response) };
        })
      );
  }
}

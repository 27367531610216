import { CoreModel, modelProtection } from '@wings-shared/core';
import { SettingBaseModel } from './SettingBase.model';
import { Airports } from './Airports.model';

@modelProtection
export class ParkingSeasonalityOptionModel extends CoreModel {
  id: number = 0;
  overnightParkingDifficulty: SettingBaseModel = new SettingBaseModel();
  overnightParkingSeasonDifficulty: SettingBaseModel = new SettingBaseModel();
  airportReference: Airports = new Airports();

  constructor(data?: Partial<ParkingSeasonalityOptionModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(
    apiData: ParkingSeasonalityOptionModel
  ): ParkingSeasonalityOptionModel {
    if (!apiData) {
      return new ParkingSeasonalityOptionModel();
    }
    const data: Partial<ParkingSeasonalityOptionModel> = {
      ...apiData,
      overnightParkingDifficulty: SettingBaseModel.deserialize({
        id: apiData.overnightParkingDifficultyId,
        name: apiData.overnightParkingDifficultyName,
      }),
      overnightParkingSeasonDifficulty: SettingBaseModel.deserialize({
        id: apiData.parkingSeasonDifficultyMonthId,
        name: apiData.parkingSeasonDifficultyMonthName,
      }),
      airportReference: Airports.deserializeAirportReference(
        apiData.airportReference
      ),
    };
    return new ParkingSeasonalityOptionModel(data);
  }

  static deserializeList(
    apiDataList: ParkingSeasonalityOptionModel[]
  ): ParkingSeasonalityOptionModel[] {
    return apiDataList
      ? apiDataList.map((apiData: any) =>
        ParkingSeasonalityOptionModel.deserialize(apiData)
      )
      : [];
  }

  public get label(): string {
    if(this.overnightParkingSeasonDifficulty.name) return this.overnightParkingSeasonDifficulty.name
    if (this.overnightParkingDifficulty.label)
      return this.overnightParkingDifficulty.label;
    if (this.airportReference.label) return this.airportReference.label;
    return '';
  }

  public get value(): string | number {
    if(this.overnightParkingSeasonDifficulty.value) return this.overnightParkingSeasonDifficulty.value
    if (this.overnightParkingDifficulty.value)
      return this.overnightParkingDifficulty.value;
    if (this.airportReference.value) return this.airportReference.value;
    return '';
  }
}

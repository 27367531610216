import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  headerActions: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: '12px',
    '& button': {
      width: '150px',
      marginRight: theme.spacing(1),
    },
    '& h5': {
      fontSize: '1.625rem',
    },
    '& h6': {
      fontSize: '26px'
    }
  },
  outerBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  innerBox1: {
    position: 'sticky',
    top: '-10px',
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    padding: '16px 24px',
  },
  innerBox2: {
    flexGrow: 1,
    paddingBottom: '120px',
  },
  thirdBox: {
    position: 'sticky',
    bottom: '-10px',
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    paddingBottom: '40px',
    paddingTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  innerBox3: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px'
  },
  defaultButton: {
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      minWidth: '100px',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
      minWidth: '100px',
      height: '40px'
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
      color: 'white'
    },
  },
}));

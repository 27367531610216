import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { VendorLocationModel } from './VendorLocation.model';
import { Airports } from './Airports.model';
import { SettingBaseModel } from './SettingBase.model';
import { OperationInfoSettingOptionModel } from './OperationInfoSettingOptionModel.model';
import { CustomersModel } from './CustomersModel.model';
import { ApprovalDataModel } from './ApprovalData.model';
@modelProtection
export class OperationalEssentialsModel extends CoreModel implements ISelectOption {
  id: number = 0;
  coordinatingOfficeId: number = 0;
  vendorLevelId: number = 0;
  vendorLocationId: number = 0;
  vendorLocation: VendorLocationModel = new VendorLocationModel();
  certifiedMemberFeeScheduleId: number = 0;
  creditProvidedById: number = 0;
  coordinatingOffice: VendorLocationModel = null;
  isSupervisoryAgentAvailable: boolean = true;
  agentDispatchedFrom: Airports = null;
  isPrincipleOffice: boolean = false;
  vendorLevel: SettingBaseModel = null;
  certifiedMemberFeeSchedule: SettingBaseModel = new SettingBaseModel();
  certifiedMemberFee: number = 0;
  contractRenewalDate: Date = new Date();
  complianceDiligenceDueDate: Date = new Date();
  startDate: Date = new Date();
  endDate: Date = null;
  isProprietary: boolean = false;
  netSuitId: number = 0;
  creditProvidedBy: VendorLocationModel = new VendorLocationModel();
  locationAirfield: string = '';
  airToGroundFrequency: number = 0;
  managerName: string = '';
  asstManagerName: string = '';
  appliedOperationType: OperationInfoSettingOptionModel = new OperationInfoSettingOptionModel();
  provideCoordinationFor: OperationInfoSettingOptionModel[] = [];
  commsCopyFor: OperationInfoSettingOptionModel[] = [];
  appliedPaymentOptions: OperationInfoSettingOptionModel[] = [];
  appliedCreditAvailable: OperationInfoSettingOptionModel[] = [];
  appliedMainServicesOffered: OperationInfoSettingOptionModel[] = [];
  creditProvidedFor: OperationInfoSettingOptionModel[] = [];
  customers: CustomersModel[] = [];

  constructor(data?: Partial<OperationalEssentialsModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: OperationalEssentialsModel): OperationalEssentialsModel {
    if (!apiData) {
      return new OperationalEssentialsModel();
    }
    const data: Partial<OperationalEssentialsModel> = {
      ...apiData,
      coordinatingOffice:
        apiData.coordinatingOffice?.vendorLocationId !== 0
          ? VendorLocationModel.deserialize(apiData.coordinatingOffice)
          : null,
      vendorLevel: SettingBaseModel.deserialize(apiData.vendorLevel),
      vendorLocation: VendorLocationModel.deserialize(apiData.vendorLocation),
      certifiedMemberFeeSchedule: SettingBaseModel.deserialize(apiData.certifiedMemberFeeSchedule),
      creditProvidedBy: VendorLocationModel.deserialize(apiData.creditProvidedBy),
      agentDispatchedFrom: apiData?.agentDispatchedFrom
        ? Airports.deserializeAirportReference(apiData?.agentDispatchedFrom)
        : null,
      appliedOperationType: OperationInfoSettingOptionModel.deserializeList(apiData.appliedOperationType)[0],
      provideCoordinationFor: OperationInfoSettingOptionModel.deserializeList(apiData?.provideCoordinationFor),
      commsCopyFor: OperationInfoSettingOptionModel.deserializeList(apiData?.commsCopyFor),
      appliedPaymentOptions: OperationInfoSettingOptionModel.deserializeList(apiData?.appliedPaymentOptions),
      appliedCreditAvailable: OperationInfoSettingOptionModel.deserializeList(apiData?.appliedCreditAvailable),
      appliedMainServicesOffered: OperationInfoSettingOptionModel.deserializeList(apiData?.appliedMainServicesOffered),
      creditProvidedFor: OperationInfoSettingOptionModel.deserializeList(apiData?.creditProvidedFor),
      customers: CustomersModel.deserializeList(apiData.customers),
    };
    return new OperationalEssentialsModel(data);
  }

  static deserializeList(apiDataList: OperationalEssentialsModel[]): OperationalEssentialsModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => OperationalEssentialsModel.deserialize(apiData)) : [];
  }

  public serialize(locationId: number, operationTypeId: number) {
    return {
      id: this.id || 0,
      userId: this.userId,
      vendorLocationId: locationId,
      coordinatingOfficeId: this.coordinatingOffice?.vendorLocationId || this.coordinatingOffice?.id || null,
      coordinatingOffice: this.coordinatingOffice || null,
      isSupervisoryAgentAvailable: null,
      agentDispatchedFrom: this.serializeAgentDispatchedFrom() || null,
      isPrincipleOffice: this.isPrincipleOffice,
      vendorLevelId: this.vendorLevel?.id || 1 || null,
      certifiedMemberFeeScheduleId: this.certifiedMemberFeeSchedule.id || null,
      certifiedMemberFee: parseFloat(this.certifiedMemberFee) || 0,
      contractRenewalDate: this.contractRenewalDate || null,
      complianceDiligenceDueDate: this.complianceDiligenceDueDate,
      startDate: this.startDate,
      endDate: this.endDate || null,
      isProprietary: this.isProprietary,
      netSuitId: parseInt(this.netSuitId) || 0,
      creditProvidedById: this.creditProvidedBy?.vendorLocationId || this.creditProvidedBy?.id || null,
      locationAirfield: this.locationAirfield || null,
      airToGroundFrequency: parseFloat(this.airToGroundFrequency) || 0,
      managerName: this.managerName || null,
      asstManagerName: this.asstManagerName || null,
      appliedOperationType: [
        {
          id: 0,
          operationTypeId: this.appliedOperationType?.operationType?.id || 0,
        },
      ],
      provideCoordinationFor: this.provideCoordinationFor.map(data => ({
        id: 0,
        vendorLocationId: data?.vendorLocation?.vendorLocationId || data?.vendorLocation?.id || 0,
      })),
      commsCopyFor: this.commsCopyFor.map(data => ({
        id: 0,
        vendorLocationId: data?.vendorLocation?.vendorLocationId || data?.vendorLocation?.id || 0,
      })),
      appliedPaymentOptions: this.appliedPaymentOptions.map(data => ({
        id: 0,
        paymentOptionsId: data?.paymentOptions?.id || 0,
      })),
      appliedCreditAvailable: this.appliedCreditAvailable.map(data => ({
        id: 0,
        creditAvailableId: data?.creditAvailable?.id || 0,
      })),
      creditProvidedFor: this.creditProvidedFor.map(data => ({
        id: 0,
        vendorLocationId: data?.vendorLocation?.vendorLocationId || data?.vendorLocation?.id || 0,
      })),
      appliedMainServicesOffered: this.appliedMainServicesOffered.map(data => ({
        id: 0,
        mainServicesOfferedId: data?.mainServicesOffered?.id || 0,
      })),
      customers: this.customers.map(customer => ({
        id: 0,
        name: customer.name,
        number: customer.number,
      })),
    };
  }

  public isString(input): boolean {
    return typeof input === 'string' && Object.prototype.toString.call(input) === '[object String]';
  }

  public serializeAgentDispatchedFrom = (): Airports => {
    return new Airports({
      id: this.agentDispatchedFrom?.id || 0,
      airportId: this.agentDispatchedFrom?.airportId || 0,
      airportName: this.agentDispatchedFrom?.airportName,
      icaoCode: this.agentDispatchedFrom?.icaoCode,
      uwaCode: this.agentDispatchedFrom?.uwaCode,
      faaCode: this.agentDispatchedFrom?.faaCode,
      iataCode: this.agentDispatchedFrom?.iataCode,
      regionalCode: this.agentDispatchedFrom?.regionalCode,
      displayCode: this.agentDispatchedFrom?.displayCode,
    });
  };
  // required in auto complete
  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}

import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useBaseUpsertComponent } from '@uplink/shared';
import { IAPISearchFiltersDictionary, IClasses, UIStore } from '@wings-shared/core';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Box, Button, Checkbox, FormControlLabel, Tab, Tabs, Typography, styled, withStyles } from '@material-ui/core';
import { styles } from './OperationalHours.styles';
import { ConfirmNavigate, DetailsEditorWrapper, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import PrivateHandlerFbo from './Components/PrivateHandlerFbo/PrivateHandlerFbo';
import { fields } from './Fields';
import { VendorLocationStore } from '../../../../Stores';
import { finalize, takeUntil } from 'rxjs/operators';
import { VendorLocationModel, SidebarMenuOperationalInfo } from '../../../Shared';
import { useUnsubscribe } from '@wings-shared/hooks';

interface Props {
  classes?: IClasses;
  searchFilters: IAPISearchFiltersDictionary;
  vendorLocationStore: VendorLocationStore;
}

const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  width: '100%',
});

const CustomTab = styled(props => <Tab disableRipple {...props} />)(() => ({
  flex: 1,
  textTransform: 'none',
  padding: 0,
  color: '#7C7C7C',
  fontWeight: 600,
  fontSize: '14px',
  backgroundColor: '#D3D3D3',
  '&.Mui-selected': {
    color: '#202020',
    borderLeft: '4px solid #004BA0',
    background: '#FFFFFF',
  },
}));

const OperationalHours: FC<Props> = ({ classes, searchFilters, vendorLocationStore }) => {
  const useUpsert = useBaseUpsertComponent({}, fields, searchFilters);
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();
  const formRef = useUpsert.form;
  const [ activeTab, setActiveTab ] = useState('1');
  const [ checkedTabs, setCheckedTabs ] = useState([ true, false ]);
  const [ airportDataManagement, setAirportDataManagement ] = useState(false);
  const [ isNextDisabled, setIsNextDisabled ] = useState(true);

  const saveDataFuncRef = useRef({});

  useEffect(() => {
    loadVendorLocationData();
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
  }, [ airportDataManagement ]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    useUpsert.form.reset();
    setActiveTab(newValue);
    setIsNextDisabled(true);
  };

  const handleNextButtonDisableState = disabled => {
    setIsNextDisabled(disabled);
  };

  const TABS = [
    {
      label: 'Private Handler (FBO)',
      value: '1',
      component: PrivateHandlerFbo,
      desc: 'FBO Hours',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('1', func),
    },
    {
      label: 'No Facility',
      value: '2',
      desc: 'No Facility',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('2', func),
    },
  ];

  const handleSaveClick = () => {
    const saveDataFunc = saveDataFuncRef.current[activeTab];
    if (saveDataFunc) {
      saveDataFunc();
    } else {
      console.warn('Save function not available for', activeTab);
    }
  };

  const loadVendorLocationData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        if (response) {
          setAirportDataManagement(response.airportDataManagement);
          RootDataStore.setLocation(
            true,
            response.id,
            response.name,
            response.code,
            response.airportReference,
            '',
            response.airportDataManagement,
            response.countryDataManagement,
            response.permitDataManagement,
            RootDataStore.locationData.hasMultipleVendor
          );
        }
      });
  };

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const newCheckedTabs = [ ...checkedTabs ];
    newCheckedTabs[index] = checked;
    setCheckedTabs(newCheckedTabs);
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">Available Handler Facilities at Airport</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button color="primary" variant="outlined" onClick={() => navigate('/vendor/locations')} size="large">
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={isNextDisabled}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const registerSaveData = (key, saveDataFunc) => {
    if (saveDataFunc) {
      saveDataFuncRef.current[key] = saveDataFunc;
    } else {
      delete saveDataFuncRef.current[key];
    }
  };

  return (
    <div className={classes?.mainOperationalHoursWrapper}>
      <ConfirmNavigate isBlocker={formRef.changed}>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={true}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes?.editorWrapperContainer}>
            <TabContext value={activeTab}>
              <CustomTabs value={activeTab} onChange={handleChange} variant="fullWidth">
                {TABS.map((tab, index) => (
                  <CustomTab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </CustomTabs>
              {TABS.map((tab, index) => (
                <TabPanel key={tab.value} value={tab.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedTabs[index]}
                        onChange={e => handleCheckboxChange(index, e.target.checked)}
                        color="primary"
                      />
                    }
                    className="checkbox-wrapper-header"
                    label={tab.label}
                  />
                  {checkedTabs[index] && tab.component && (
                    <Box>
                      <tab.component
                        onNextButtonDisable={handleNextButtonDisableState}
                        registerSaveData={func => registerSaveData(activeTab, func)}
                      />
                    </Box>
                  )}
                </TabPanel>
              ))}
            </TabContext>
          </div>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    </div>
  );
};

export default inject('vendorLocationStore')(withStyles(styles)(observer(OperationalHours)));

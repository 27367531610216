import React, { FC, useEffect, useRef, useState } from 'react';
import { BaseStore, PassengerLogisticsStore, SettingsStore, VendorLocationStore } from '../../../../../../Stores';
import { styles } from './Logistics.styles';
import { IAPISearchFiltersDictionary, IClasses, UIStore } from '@wings-shared/core';
import {
  CustomAccordion,
  PassengerLogisticsModel,
  SETTING_ID,
  SettingBaseModel,
  SidebarMenuOperationalInfo,
  VendorLocationModel,
} from '../../../../../Shared';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { inject, observer } from 'mobx-react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  OutlinedInput,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useUnsubscribe } from '@wings-shared/hooks';
import { finalize, takeUntil } from 'rxjs/operators';
import { truncate } from 'fs';
import { PassengerTransportModel } from 'apps/vendor/src/Modules/Shared/Models/PassengerTransport.model';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  settingsStore: SettingsStore;
  passengerLogisticsStore: PassengerLogisticsStore;
  searchFilters: IAPISearchFiltersDictionary;
  onNextButtonDisable?: (boolean) => void;
  registerSaveData: (saveData: () => void) => void;
  updateIsBlocker: (value: boolean) => void;
}

const Logistics: FC<Props> = ({
  registerSaveData,
  vendorLocationStore,
  settingsStore,
  classes,
  passengerLogisticsStore,
  onNextButtonDisable,
  updateIsBlocker,
}) => {
  const previousLogisticsDataRef = useRef(passengerLogisticsStore.logisticsData);

  const saveData = () => {
    upsertLogistics();
  };

  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    passengerLogisticsStore.logisticsData = [];
    passengerLogisticsStore.otherNameField = '';
    passengerLogisticsStore.isValueChanged = false;
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
    settingsStore.getSettings(SETTING_ID.SETTING_INTERNATIONAL_DEPARTURE_PROCEDURES).subscribe();
    loadInitialData();
  }, []);

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  useEffect(() => {
    const isOtherNameRequired = passengerLogisticsStore.logisticsData.some(item => item.id === 5);
    const isSpecifiedLocationSelected = passengerLogisticsStore.logisticsData.some(
      item => item.id === 1 || item.id === 2
    );
    if (isOtherNameRequired && passengerLogisticsStore.otherNameField === '') {
      onNextButtonDisable(true);
      return;
    } else if (
      (isOtherNameRequired && passengerLogisticsStore.otherNameField.length < 1) ||
      passengerLogisticsStore.otherNameField.length > 200
    ) {
      onNextButtonDisable(true);
      return;
    }
    if (passengerLogisticsStore.logisticsData?.length === 0) {
      onNextButtonDisable(true);
      return;
    }
    if (isSpecifiedLocationSelected) {
      if (passengerLogisticsStore.additionalInfo === '' || passengerLogisticsStore.additionalInfo?.length > 200) {
        onNextButtonDisable(true);
        return;
      }
    }
    onNextButtonDisable(!passengerLogisticsStore.isValueChanged);
    updateIsBlocker(passengerLogisticsStore.isValueChanged)
  }, [
    passengerLogisticsStore.otherNameField,
    passengerLogisticsStore.logisticsData?.length,
    passengerLogisticsStore.additionalInfo,
    passengerLogisticsStore.isValueChanged
  ]);

  const setFieldValues = data => {
    const newProcedures = Array.isArray(data)
      ? data.map(item => item.internationalDepartureProcedures)
      : [ data.internationalDepartureProcedures ];

    newProcedures.forEach(newProcedure => {
      const isDuplicate = passengerLogisticsStore.logisticsData.some(
        existingProcedure => existingProcedure.id === newProcedure.id
      );

      if (!isDuplicate) {
        passengerLogisticsStore.logisticsData.push(newProcedure);
      }
      if (newProcedure?.name?.toLocaleLowerCase() === 'other') {
        passengerLogisticsStore.otherNameField = newProcedure.description;
      } else if (newProcedure.id === 1 || newProcedure.id === 2) {
        passengerLogisticsStore.additionalInfo = newProcedure.description;
      }
    });
    passengerLogisticsStore.isValueChanged = false;
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        if (response.operationalInsight) {
          passengerLogisticsStore.operationalInsightsData = PassengerLogisticsModel.deserialize(
            response?.operationalInsight
          );
        }
        if (response.operationalInsight?.appliedInternationalDepartureProcedures) {
          const procedures = response.operationalInsight.appliedInternationalDepartureProcedures;
          setFieldValues(procedures);
        }
      });
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => BaseStore.showAlert(errorMessage[0], id));
  };

  const upsertLogistics = (): void => {
    const request = new PassengerLogisticsModel({
      ...passengerLogisticsStore.operationalInsightsData,
      appliedInternationalDepartureProcedures:
        passengerLogisticsStore.logisticsData?.map(data => ({
          userId: data.userId || '',
          id: 0,
          internationalDepartureProceduresId: data.id || 0,
          internationalDepartureProcedures: data,
          otherValue:
            data.id === 5
              ? passengerLogisticsStore.otherNameField || data.description?.toString()?.replace(/\s+/gi, ' ') || ''
              : data.id === 1 || data.id === 2
                ? passengerLogisticsStore.additionalInfo
                : null,
        })) || [],
    });
    UIStore.setPageLoader(true);
    passengerLogisticsStore
      ?.upsertVendorLocationOperationalInsights(request.serialize(RootDataStore.locationData.locationId))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: PassengerLogisticsModel) => {
          setFieldValues(response.appliedInternationalDepartureProcedures);
        },
        error: error => {
          errorHandler(error?.response?.data?.errors, request.id.toString());
        },
      });
  };

  const handleCheckboxChange = (event, item) => {
    const { checked } = event.target;
    passengerLogisticsStore.isValueChanged = true;
    if (checked) {
      if (!passengerLogisticsStore.logisticsData.some(data => data.id === item.id)) {
        passengerLogisticsStore.logisticsData.push(item);
      }
    } else {
      passengerLogisticsStore.logisticsData = passengerLogisticsStore.logisticsData.filter(data => data.id !== item.id);
    }
  };

  const showValidationError = (value: string, length?: number) => {
    if (!value) {
      return 'This field is required.';
    } else if (value && value?.length > length) {
      return `This field must be between 1 and ${length} characters.`;
    }
    return '';
  };

  return (
    <Box className={classes.parentBox}>
      <CustomAccordion
        panelName="gatOperationalHours"
        panelHeading="Departure Logistics"
        panelBodyHeading="Please advise the expected procedures once at the airport (select all that apply)"
        panelContent={
          <FormGroup className={classes.formControl}>
            {settingsStore.internationalDepartureProcedures &&
              settingsStore.internationalDepartureProcedures.map((item, index) => (
                <React.Fragment key={item.id || index}>
                  <div style={{ border: '1px solid #D3D3D3', padding: '10px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={event => handleCheckboxChange(event, item)}
                          checked={passengerLogisticsStore.logisticsData.some(data => data.id === item.id)}
                        />
                      }
                      label={item.name}
                    />
                    {passengerLogisticsStore.logisticsData.some(data => data?.name?.toLocaleLowerCase() === 'other') &&
                      item.name.toLocaleLowerCase() === 'other' && (
                      <>
                        <OutlinedInput
                          className="otherTextField"
                          value={passengerLogisticsStore.otherNameField}
                          onChange={e => {
                            passengerLogisticsStore.otherNameField = e.target.value;
                            passengerLogisticsStore.isValueChanged = true;
                          }}
                          labelWidth={0}
                          placeholder="Enter here"
                          style={{
                            borderColor: showValidationError(passengerLogisticsStore.otherNameField, 200)
                              ? 'red'
                              : 'initial',
                            borderWidth: '1px',
                            borderStyle: showValidationError(passengerLogisticsStore.otherNameField, 200)
                              ? 'solid'
                              : 'none',
                            width: '98%',
                          }}
                        />
                        {showValidationError(passengerLogisticsStore.otherNameField, 200) && (
                          <FormHelperText style={{ color: 'red' }}>
                            {showValidationError(passengerLogisticsStore.otherNameField, 200)}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  </div>
                </React.Fragment>
              ))}
            {passengerLogisticsStore.logisticsData.some(data => data.id === 1 || data.id === 2) && (
              <FormControl variant="outlined" className="formControlField">
                <Typography className="radioLabel">
                  Please advise where this specified meeting location is (directions, gate/terminal, etc.)
                </Typography>
                <OutlinedInput
                  className="inputTextField"
                  value={passengerLogisticsStore.additionalInfo}
                  onChange={e => {
                    passengerLogisticsStore.additionalInfo = e.target.value;
                    passengerLogisticsStore.isValueChanged = true;
                  }}
                  labelWidth={0}
                  style={{
                    borderColor: showValidationError(passengerLogisticsStore.additionalInfo, 200) ? 'red' : 'initial',
                    borderWidth: '1px',
                    borderStyle: showValidationError(passengerLogisticsStore.additionalInfo, 200) ? 'solid' : 'none',
                  }}
                  placeholder="Enter here"
                />
                {showValidationError(passengerLogisticsStore.additionalInfo, 200) && (
                  <FormHelperText style={{ color: 'red' }}>
                    {showValidationError(passengerLogisticsStore.additionalInfo, 200)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </FormGroup>
        }
      />
    </Box>
  );
};

export default inject(
  'settingsStore',
  'vendorLocationStore',
  'passengerLogisticsStore'
)(withStyles(styles)(observer(Logistics)));

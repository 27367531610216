import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  editorWrapperContainer: {
    paddingLeft: '20px',
    '& .MuiFormLabel-root': {
      fontSize: '12px',
      fontWeight: 'bold',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#db063b !important',
      fontWeight: 'normal !important',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-adornedEnd svg': {
      width: '16px !important',
      height: '16px !important',
    },
    '& .MuiChip-label': {
      fontSize: '12px',
      paddingTop: '2px',
    },
    '& .MuiChip-colorPrimary': {
      backgroundColor: '#004ba0',
    },
    '& .ql-container': {
      width: '100%',
      display: 'grid',
    },
    '& .ql-editor': {
      width: '100%',
    },
    '& .MuiIconButton-root': {
      color: '#1976D2',
    },
    '& .MuiFormControlLabel-root': {
      marginTop: '21px !important',
    },
    '& .MuiInputBase-input.Mui-disabled':{
      color: '#7C7C7C',
    }
  },
  subHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'flex-start',
    '& .MuiIconButton-label': {
      color: '#1976D2',
    },
  },
  headerActions: {
    padding: '20px 0px 0px 20px',
    justifyContent: 'flex-start',
    '& h5': {
      fontSize: '1.625rem',
    },
    '& .MuiTypography-h6': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      whiteSpace: 'nowrap',
      fontSize: '1.625rem',
    },
  },
  defaultButton: {
    marginLeft: '10px',
    '& button': {
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      width: '100px',
      minWidth: '100px',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
    '& button:hover':{
      backgroundColor: '#FFFFFF',
      color:'#63A4FF',
      textDecoration: 'underline',
    }
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
    },
    '& button:hover': {
      backgroundColor: '#63A4FF',
      color: '#fff',
      textDecoration: 'none'
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
    },
    '& .MuiButton-contained':{
      boxShadow: 'none !important',
    }
  },
  footerActions :{
    display: 'flex',
    justifyContent: 'flex-end', 
    paddingRight: '20px'
  }
}));

import React, { ReactElement } from 'react';
import { INavigationLink, RootDataStore } from '@uplink-shared/layout';
import { HandlerIcon, AirplaneIcon, CountryFlagIcon } from '@uvgo-shared/icons';
import { ModeStore } from '@wings-shared/mode-store';

interface INavigationLinkUpdated extends Omit<INavigationLink, 'subMenuItem'> {
  subMenuItem: {
    to: string;
    title: string;
    icon?: string | ReactElement;
    isDisabled?: boolean;
  }[];
}

const SidebarMenuOperationalInfo = (): INavigationLinkUpdated[] =>
  [
    {
      to: '/vendor/location/operational-information/operational-hours',
      title: 'Handler',
      expandedMenuBlock: 'panel2',
      icon: <HandlerIcon />,
      subMenuItem: [
        {
          to: '/vendor/location/operational-information/operational-hours',
          title: 'Operational Hours',
          isDisabled: !RootDataStore.locationData.locationId,
        },
        {
          to: '/vendor/location/operational-information/aircraft-logistics',
          title: 'Aircraft Logistics',
          isDisabled: !RootDataStore.locationData.locationId,
        },
        {
          to: '/vendor/location/operational-information/passenger-logistics',
          title: 'Passenger Logistics',
          isDisabled: !RootDataStore.locationData.locationId,
        },
        {
          to: '/vendor/location/handler/notes',
          title: 'Notes',
          isDisabled: !RootDataStore.locationData.locationId,
        },
        {
          to: '/vendor/location/operational-information/ground-service-equipment',
          title: 'Ground Service Equipment',
          isDisabled: !RootDataStore.locationData.locationId,
        },
        {
          to: '/vendor/location/handler/events',
          title: 'Events',
          isDisabled: !RootDataStore.locationData.locationId,
        },
      ],
    },
    RootDataStore.locationData.countryDataManagement && {
      to: '/vendor/location/country/notes',
      title: 'Country',
      expandedMenuBlock: 'panel4',
      icon: <CountryFlagIcon />,
      isDisabled: !RootDataStore.locationData.locationId || !RootDataStore.locationData.countryDataManagement,
      subMenuItem: [
        {
          to: '/vendor/location/country/notes',
          title: 'Notes',
          isDisabled: !RootDataStore.locationData.locationId || !RootDataStore.locationData.countryDataManagement,
        },
        ...(ModeStore.isDevModeEnabled
          ? [
            {
              to: '/vendor/location/country/health-requirements',
              title: 'Health Requirements',
              isDisabled: !RootDataStore.locationData.locationId || !RootDataStore.locationData.countryDataManagement,
            },
          ]
          : []),
        ...(ModeStore.isDevModeEnabled
          ? [
            {
              to: '/vendor/location/country/cabotage',
              title: 'Cabotage',
              isDisabled: !RootDataStore.locationData.locationId || !RootDataStore.locationData.countryDataManagement,
            },
          ]
          : []),
      ],
    },
    RootDataStore.locationData.airportDataManagement && {
      to: '/vendor/location/airport/notes',
      title: 'Airport',
      expandedMenuBlock: 'panel3',
      icon: <AirplaneIcon />,
      isDisabled: !RootDataStore.locationData.airportReference.id || !RootDataStore.locationData.airportDataManagement,
      subMenuItem: [
        ...(ModeStore.isDevModeEnabled ?[{
          to: '/vendor/location/airport/parking',
          title: 'Parking',
          isDisabled:
            !RootDataStore.locationData.locationId ||
            !RootDataStore.locationData.airportReference.id ||
            !RootDataStore.locationData.airportDataManagement,
        }]:[]),
        ...(ModeStore.isDevModeEnabled ?[{
          to: '/vendor/location/operational-information/airport-hours',
          title: 'Airport Hours',
          isDisabled:
            !RootDataStore.locationData.locationId ||
            !RootDataStore.locationData.airportReference.id ||
            !RootDataStore.locationData.airportDataManagement,
        }]:[]),
        {
          to: '/vendor/location/airport/notes',
          title: 'Notes',
          isDisabled:
            !RootDataStore.locationData.locationId ||
            !RootDataStore.locationData.airportReference.id ||
            !RootDataStore.locationData.airportDataManagement,
        },
        ...(ModeStore.isDevModeEnabled
          ? [
            {
              to: '/vendor/location/airport/custom-notes',
              title: 'Customs',
              isDisabled: !RootDataStore.locationData.locationId || !RootDataStore.locationData.airportReference.id,
            },
          ]
          : []),
        ...(ModeStore.isDevModeEnabled
          ? [
            {
              to: '/vendor/location/airport/permissions',
              title: 'Permissions',
              isDisabled: !RootDataStore.locationData.locationId || !RootDataStore.locationData.airportReference.id,
            },
          ]
          : []),
      ],
    },
  ].filter(Boolean);

export default SidebarMenuOperationalInfo;

import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { RootDataStore } from '@uplink-shared/layout';
import { Airports } from './Airports.model';
import { SettingBaseModel } from './SettingBase.model';
import { WorldEventSpecialConsiderationModel } from './WorldEventSpecialConsideration.model';
import { CityModel, CountryModel, StateModel } from '@uplink/shared';
import { AuthStore } from '@uplink-shared/security';
import { RefDataWorldEvents } from './RefDataModel/RefDataWorldEvents.model';

@modelProtection
export class WorldEvents extends CoreModel implements ISelectOption {
  userId?: string;
  id: number = 0;
  name: string = '';
  worldEventId: number = 0;
  description: string = '';
  eventTypeId: number = 0;
  eventType: SettingBaseModel = new SettingBaseModel();
  eventCategoryId: number = 0;
  eventCategoryName: string = '';
  eventCategory: SettingBaseModel = new SettingBaseModel();
  url: string = '';
  startDate: Date = new Date();
  endDate: Date = new Date();
  beginPlanning: number = 0;
  worldEventEffectType: SettingBaseModel = new SettingBaseModel();
  specialConsideration: WorldEventSpecialConsiderationModel[] = [];
  countryReferenceId: number = 0;
  stateReferenceId: number = 0;
  cityReferenceId: number = 0;
  airportReferenceId: number = 0;
  airportReference: Airports = new Airports();
  cityReference: CityModel = new CityModel();
  stateReference: StateModel = new StateModel();
  countryReference: CountryModel = new CountryModel();
  statusId: number = 0;
  vendorLocationId: number = 0;

  constructor(data?: Partial<WorldEvents>) {
    super(data);
    Object.assign(this, data);
  }

  public static handlerEventOptions() {
    return SettingBaseModel.deserializeList([
      { name: 'Airport', id: 1 },
      { name: 'City', id: 2 },
      { name: 'Department', id: 3 },
      { name: 'Country', id: 4 },
    ]);
  }

  public serialize(statusId: number, item): WorldEvents {
    const serializedSpecialConsiderations =
      this.specialConsideration != '' ? this.specialConsideration?.map(item => item.serialize()) || [] : null;

    return {
      userId: AuthStore?.user?.preferred_username || '',
      id: this.id || 0,
      name: this.name || '',
      vendorLocationId: RootDataStore?.locationData?.locationId,
      description: this.description?.toString()?.replace(/\s+/gi, ' ') || '',
      worldEventId: this.worldEventId || 0,
      eventTypeId: this.eventType?.id || null,
      eventTypeName: this.eventType?.name?.toString()?.replace(/\s+/gi, ' ') || '',
      eventCategoryId: this.eventCategory?.id || null,
      eventCategoryName: this.eventCategory?.name || '',
      url: this.url || '',
      startDate: this.startDate || new Date(),
      endDate: this.endDate || new Date(),
      beginPlanning: this.beginPlanning || null,
      worldEventEffectType:
        this.worldEventEffectType.name === 'Department'
          ? 'State'
          : this.worldEventEffectType.name || '',
      appliedWorldEventSpecialConsiderationReferenceRequest: serializedSpecialConsiderations,
      countryId: item ? item.countryReference?.countryId : this.countryReferenceId || null,
      stateId: item ? item.stateReference?.stateId :this.stateReferenceId || null,
      cityId: item ? item.cityReference?.id : this.cityReferenceId || null,
      airportId: item ? item.airportReference?.airportId : this.airportReferenceId || null,
      statusId: statusId || this.statusId || null,
    };
  }

  static deserialize(apiData: WorldEvents): WorldEvents {
    if (!apiData) {
      return new WorldEvents();
    }
    const data: Partial<WorldEvents> = {
      ...apiData,
      id: apiData?.id,
      worldEventId: apiData?.worldEventId,
      name: apiData.name,
      description: apiData.description,
      eventType: SettingBaseModel.deserialize({
        id: apiData.eventTypeId,
        name: apiData.eventTypeName,
      }),

      eventCategory: SettingBaseModel.deserialize({
        id: apiData.eventCategoryId || apiData.worldEventCategoryId,
        name: apiData.eventCategoryName,
      }),
      worldEventEffectType:
        apiData?.worldEventEffectType === 'State'
          ? SettingBaseModel.deserialize({ name: 'Department', id: 3 })
          : SettingBaseModel.deserialize(
            WorldEvents.handlerEventOptions().find(
              item =>
                  item.name?.toLocaleLowerCase() === apiData?.worldEventEffectType?.toString()?.toLocaleLowerCase()
            )
          ),
      url: apiData.url,
      startDate: apiData.startDate,
      endDate: apiData.endDate,
      specialConsideration: WorldEventSpecialConsiderationModel.deserializeList(
        apiData?.appliedWorldEventSpecialConsiderationReferenceResponse
      ),
      countryReference: apiData.appliedWorldEventCountryReference?.length>0 ? 
        CountryModel.deserialize(apiData.appliedWorldEventCountryReference[0]):null,
      cityReference: apiData.appliedWorldEventCityReference?.length>0 ? 
        CityModel.deserialize(apiData.appliedWorldEventCityReference[0]):null,
      stateReference: apiData.appliedWorldEventStateReference?.length>0 ? 
        StateModel.deserialize(apiData.appliedWorldEventStateReference[0]):null,
      airportReference:apiData.appliedWorldEventAirportReference?.length>0 ? 
        Airports.deserialize(apiData.appliedWorldEventAirportReference[0]):null,
    };
    return new WorldEvents(data);
  }

  static deserializeList(apiDataList: WorldEvents[]): WorldEvents[] {
    return apiDataList ? apiDataList.map(apiData => WorldEvents.deserialize(apiData)) : [];
  }

  static buildFinalResults(vmsResults: WorldEvents[], refDataResults: RefDataWorldEvents[])
  {
    
    const filteredRefResult = refDataResults.filter(item2 => 
      !vmsResults.some(item1 => item1.worldEventId === item2.worldEventId));

    vmsResults.push(...filteredRefResult);
    const finalResults = vmsResults.filter(item=>item.status?.id==1);

    return finalResults;
  }


  public get label(): string {
    return this.name || '';
  }

  public get value(): string | number {
    return this.id;
  }
}

import {
  IAPIGridRequest,
  IAPISearchFiltersDictionary,
  IClasses,
  IOptionValue,
  UIStore,
  Utilities,
  MODEL_STATUS
} from '@wings-shared/core';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { forkJoin } from 'rxjs';
import { Box, Typography, withStyles } from '@material-ui/core';
import { MixPanelTrackingEvents, NO_SQL_COLLECTIONS, useBaseUpsertComponent, VIEW_MODE } from '@uplink/shared';
import { useGridState } from '@wings-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { 
  DetailsEditorWrapper, SidebarStore, RootDataStore, SidebarOptions 
} from '@uplink-shared/layout';
import Button from '@material-ui/core/Button';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { fields } from './Fields';
import {
  CustomTooltip,
  SidebarMenuOperationalInfo,
  ViewInputControls,
  CustomDialog,
  COLLECTION_NAMES,
  RefDataWorldEvents,
} from '../../../../Shared';
import { styles } from './UpsertEvents.styles';
import { SettingsStore, VendorLocationStore, EventsStore } from '../../../../../Stores';
import { finalize, takeUntil } from 'rxjs/operators';
import { useNavigate, useParams } from 'react-router';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { WorldEvents } from '../../../../Shared/Models/WorldEvents.model';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  searchFilters?: IAPISearchFiltersDictionary;
  settingsStore: SettingsStore;
  eventsStore: EventsStore;
}

const UpsertNotes: FC<Props> = ({ 
  classes, vendorLocationStore, settingsStore, searchFilters, eventsStore 
}) => {
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<WorldEvents>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const navigate = useNavigate();
  const params = useParams();
  const isEditable = useUpsert.isEditable;
  const [ eventTypes, setEventTypes ] = useState([]);
  const isCountry = params.type === 'country';
  const [ disableSaveButton, setDisableSaveButton ] = useState(false);
  const isRefDataEvent = params?.dataSource?.toLocaleLowerCase() === 'ref';
  
  useEffect(() => {
    if (!RootDataStore.locationData.countryDataManagement && isCountry) {
      navigate('/vendor/locations');
    }
  }, [ RootDataStore.locationData.countryDataManagement ]);

  useEffect(() => {
    useUpsert.setViewMode((params.upsert.toUpperCase() as VIEW_MODE) || VIEW_MODE.EDIT);
    SidebarStore.setNavLinks(
      SidebarOptions.updateSidebarOptions(SidebarMenuOperationalInfo(), params.type, 'Events'),
      'vendor',
      'Operational Information',
      '/vendor/locations'
    );
    loadLocationAddress();
    forkJoin([
      eventsStore.getReffDataEventType('WorldEventType'),
      eventsStore.getReffDataEventType('WorldEventCategory'),
      eventsStore.getReffDataEventType('WorldEventSpecialConsideration')
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(response => {
        setEventTypes(response[0].results);
      });
    useUpsert.getField('startDate').set(new Date());
    eventsStore.isEndDateRequired = true;
    eventsStore.isNotesTextExist = false;
    if (params?.id && !isRefDataEvent) {
      loadInitialData();
    } else if (params?.id && isRefDataEvent) {
      loadRefWorldEventData();
    }
    if (params.type !== 'handler') {
      loadAirportData();
    }
    
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_GENERALINFORMATION);
  }, []);
  
  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'worldEventId',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'name',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
          },
          {
            fieldKey: 'description',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'eventType',
            type: EDITOR_TYPES.DROPDOWN,
            options: eventsStore.eventTypeList,
            isHalfFlex: true,
          },
          {
            fieldKey: 'eventCategory',
            type: EDITOR_TYPES.DROPDOWN,
            options: eventsStore.eventCategoryList,
            isHalfFlex: true,
          },
          {
            fieldKey: 'specialConsideration',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: eventsStore.eventSpecialConsiderationList,
            isHalfFlex: true,
            multiple: true,
            disableCloseOnSelect: true,
          },
          {
            fieldKey: 'worldEventEffectType',
            type: EDITOR_TYPES.DROPDOWN,
            options: WorldEvents.handlerEventOptions(),
            isHalfFlex: true,
          },
          {
            fieldKey: 'url',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'startDate',
            type: EDITOR_TYPES.DATE,
            isHalfFlex: true,
            maxDate: useUpsert.getField('endDate').value,
          },
          {
            fieldKey: 'endDate',
            type: EDITOR_TYPES.DATE,
            isHalfFlex: true,
            minDate: useUpsert.getField('startDate').value,
          },
          {
            fieldKey: 'beginPlanning',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
          },
        ],
      },
    ];
  };

  const checkWorldEventEffectType = (value) =>{
    if(value?.name?.toLocaleLowerCase() === 'department' && eventsStore.locationAddress[0].stateReference.name ==''){
      setDisableSaveButton(true)
      useUpsert.showAlert('No state found in physical address');
    }else{
      setDisableSaveButton(false)
    }
  }

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    if (fieldKey === 'beginPlanning') {
      value = value.replace(/\s+/g, '');
      if (!value) {
        value = '';
      }
    }
    
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'startDate':
        break;
      case 'worldEventEffectType':
        checkWorldEventEffectType(value);
        break;
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'startDate':
        break;
      default:
        break;
    }
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    eventsStore
      .getVendorLocationEvent(params?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: WorldEvents) => {
        useUpsert.setFormValues(response);
      });
  };  


  const loadRefWorldEventData = () => {
    UIStore.setPageLoader(true);
    const refDataRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([{ propertyName: 'WorldEventId', propertyValue: params?.id }]),
    };
    eventsStore.getRefDataAllWorldEvents(NO_SQL_COLLECTIONS.WORLDEVENT, refDataRequest)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe(response => {
        const refResults = response.results[0];
        useUpsert.setFormValues(refResults);
      });
  };

  const loadAirportData = () => {
    UIStore.setPageLoader(true);
    const locationAddress = eventsStore.locationAddress && eventsStore.locationAddress[0];
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportId',
          propertyValue: locationAddress.vendorLocation?.airportReference?.airportId,
        },
      ]),
    };
    vendorLocationStore
      .getVmsIcaoCode(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe();
  };

  const loadLocationAddress = () => {
    UIStore.setPageLoader(true);
    const cityAddressRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
        },
      ]),
    };
    eventsStore
      .getVMSComparison(COLLECTION_NAMES.VENDOR_LOCATION_ADDRESS, cityAddressRequest)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe();
  };
  const headerTitle = () => {
    if (params.upsert.toUpperCase() === VIEW_MODE.NEW) return 'Add New Event';
    else if (params.upsert.toUpperCase() === VIEW_MODE.EDIT) return 'Edit Event';
    return 'Note Details';
  };

  const updateViewMode = () => {
    useUpsert.setViewMode(VIEW_MODE.EDIT);
    navigate(`/vendor/location/${params.type}/notes/edit/${params?.id}`);
  };

  const isButtonDisable = () => {
    if (params.upsert.toUpperCase() === VIEW_MODE.DETAILS) {
      return false;
    }
    return (
      !formRef.changed ||
      !formRef.isValid ||
      disableSaveButton ||
      formRef.hasError 
    );
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {<CustomTooltip title={headerTitle()} />}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button color="primary" variant="outlined" onClick={() => getConfirmation()} size="large">
              {params.upsert.toUpperCase() === VIEW_MODE.DETAILS ? 'Back to List' : 'Cancel'}
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                params.upsert.toUpperCase() === VIEW_MODE.DETAILS ? updateViewMode() : upsertHandlerEvents()
              }
              size="large"
              disabled={isButtonDisable()}
            >
              {params.upsert.toUpperCase() === VIEW_MODE.DETAILS ? 'Edit' : 'Save'}
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const upsertHandlerEvents = (): void => {
    UIStore.setPageLoader(true);
    const request = new WorldEvents({ ...useUpsert.form.values() });
    const locationAddress = eventsStore.locationAddress && eventsStore.locationAddress[0];
    if (locationAddress) {
      request.cityReferenceId = locationAddress.cityReference?.id || null;
      request.countryReferenceId = locationAddress.countryReference?.countryId || null;
      request.stateReferenceId = locationAddress.stateReference?.stateId || null;
      request.airportReferenceId = locationAddress.vendorLocation?.airportReference?.airportId || null;
    }
    const finalRequest = request;    
    
    eventsStore
      ?.upsertVendorLocationHanlderEvents(finalRequest.serialize(MODEL_STATUS.ACTIVE))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: WorldEvents) => {
          useUpsert.form.reset();
          useUpsert.resetFormValidations(response, () => {
            navigate(`/vendor/location/${params.type}/events`);
          });
        },
        error: error => {
          errorHandler(error?.response?.data?.errors, request.id.toString());
        },
      });
  };

  const getConfirmation = (): void => {
    if (formRef.changed) {
      ModalStore.open(
        <CustomDialog
          title="Confirm changes"
          message={'Canceling will lost your changes. Are you sure you want to cancel?'}
          yesButton="Confirm"
          noButton="Cancel"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => {
            navigate(`/vendor/location/${params.type}/events`);
          }}
        />
      );
    } else {
      navigate(`/vendor/location/${params.type}/events`);
    }
  };

  return (
    <DetailsEditorWrapper
      headerActions={headerActions()}
      isEditMode={true}
      classes={{ headerActions: classes.headerActions }}
    >
      <div className={classes.editorWrapperContainer}>
        <ViewInputControls
          isEditable={isEditable}
          groupInputControls={groupInputControls()}
          onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
          onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
          field={fieldKey => useUpsert.getField(fieldKey)}
          onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
          onFocus={fieldKey => onFocus(fieldKey)}
        />
      </div>
    </DetailsEditorWrapper>
  );
};

export default inject(
  'settingsStore',
  'vendorLocationStore',
  'eventsStore'
)(withStyles(styles)(observer(UpsertNotes)));

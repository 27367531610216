import { HttpClient, baseApiPath } from '@uplink/shared';
import { observable, action } from 'mobx';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { Logger } from '@wings-shared/security';
import {
  IAPIGridRequest,
  IAPIPageResponse,
  Utilities,
  tapWithAction,
} from '@wings-shared/core';
import { vendorManagementHeaders } from './Base.store';
import { apiUrls } from './API.url';
import { COLLECTION_NAMES } from '../Modules/Shared/Enums/CollectionName.enum';
import { VendorDocumentModel } from '../Modules/Shared/Models/VendorDocument.model';
import { IAPIDocumentFile } from '../Modules/Shared/Interfaces/Request/API-Request-VendorDocument';
import { IAPIResponseVendorDocument } from '../Modules/Shared/Interfaces/Response/API-Response-VendorDocument';
import { VendorLocationPhoto } from '../Modules/Shared/Models/VendorLocationPhoto.model';
export interface IAPIPhotoFile {
  vendorLocationId: string;
  photos: File[];
}
export interface IUrlName {
  url: string;
  photoName:string;
}

export class VendorLocationPhotoStore {
  @observable public documentUri: string = null;
  @observable public file: File[] | null = [];
  @observable public photoUrl: IUrlName[] = [];
  @observable public photosAddedList: VendorLocationPhoto[] = [];
  

  @action
  public getVendorDocumentById(
    id: number
  ): Observable<IAPIResponseVendorDocument> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    return http
      .get<IAPIPageResponse<IAPIResponseVendorDocument>>(
        `/${apiUrls.vendorDocument}/${id}`
      )
      .pipe(
        Logger.observableCatchError,
        map((response) => VendorDocumentModel.deserialize(response))
      );
  }

  @action
  public getVendorLocationDocumentById(
    id: number
  ): Observable<IAPIResponseVendorDocument> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    return http
      .get<IAPIPageResponse<IAPIResponseVendorDocument>>(
        `/${apiUrls.vendorLocationDocument}/${id}`
      )
      .pipe(
        Logger.observableCatchError,
        map((response) => VendorDocumentModel.deserialize(response))
      );
  }

  deserializePhotos(response: any[]): IUrlName[] {
    return response.map(item => ({
      url: item.photoUri,
      photoName: item.fileName
    }));
  }

  @action
  public importDocumentFile(
    file: File[],
    id: string
  ): Observable<IAPIPhotoFile> {
    const data: FormData = new FormData();
    data.append('vendorLocationId', id);
    file.forEach((file) => {
      data.append('photos', file); 
    });
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http
      .post<IAPIPhotoFile>(`${apiUrls.vendorLocationPhoto}/upload`, data)
      .pipe(
        Logger.observableCatchError,
        map((response) => ({ ...response, results: response })),
        tapWithAction((response) => {
          return(this.photoUrl = this.deserializePhotos(response.results))})
      );
  }


  @action
  public upsertLocationPhotos(
    payload: VendorLocationPhoto[]
  ): Observable<VendorLocationPhoto[]> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    const upsertRequest: Observable<VendorLocationPhoto[]> =
      http.post<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocationPhoto}/add-photos`,
        payload
      )
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => AlertStore.info('Photos uploaded saved successfully!')),
      map((response) => (this.photosAddedList = VendorLocationPhoto.deserializeList(response)))
    );
  }


  @action
  public downloadVendorLocationPhoto(
    photoUri: string,
    photoId: number,
    id: number
  ): Observable<IAPIDocumentFile> {
    const data = {
      vendorLocationId: id,
      photoUri,
      photoId,
    };
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http
      .post<IAPIDocumentFile>(
        `${apiUrls.vendorLocationPhoto}/download`,
        data
      )
      .pipe(
        Logger.observableCatchError,
        map((response) => ({ ...response, results: response.results }))
      );
  }
  @action
  public removeVendorLocationPhoto(photoId: number,id: number,userId:string): Observable<string> {
    const data = {
      userId,
      vendorLocationId: id,
      vendorLocationPhotoId:photoId,
    };
    const http = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .delete<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocationPhoto}/${photoId}`,
        data
      )
      .pipe(
        Logger.observableCatchError,
        map((response: any) => response),
        tap(() => AlertStore.info('Photo deleted successfully!'))
      );
  }
}

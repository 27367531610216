import React, { FC, useEffect, useState } from 'react';
import { IAPIGridRequest, IClasses, UIStore, MODEL_STATUS } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { Box, FormControl, OutlinedInput, withStyles, FormHelperText, Typography, TextField } from '@material-ui/core';
import {
  CustomAccordion,
  AccordionRadioGroups,
  ServiceItemPricingModel,
  SETTING_ID,
  SettingBaseModel,
  HoursCost,
  PricingFeeCost,
  LocationHoursModel,
} from '../../../../../Shared';
import {
  ServiceItemPricingStore,
  SettingsStore,
  VendorLocationHoursStore,
  VendorLocationStore,
} from '../../../../../../Stores';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import MainTerminalBothOperationalHours from './Component/MainTerminalBothOperationalHours';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AlertStore } from '@uvgo-shared/alert';
import { useUnsubscribe } from '@wings-shared/hooks';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { RootDataStore } from '@uplink-shared/layout';
import { styles } from '../../AirportHours.styles';
import moment from 'moment';
import { NO_SQL_COLLECTIONS } from '@uplink/shared';
import { Autocomplete } from '@material-ui/lab';

const dayNames = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];

interface TimeRange {
  id: string;
  hoursId: number;
  scheduleId: number;
  patternedRecurrenceId: number;
  startTime: string;
  endTime: string;
  is24Hours: boolean;
  isNew?: boolean;
  sequence?: number;
  hoursTypeId?: number;
  statusId?: number;
  accessLevelId?: number;
  startDate?: Date;
  endDate?: Date;
  includeHoliday?: boolean;
  dayOfWeekId?: number;
  patternedRecurrenceDaysofWeekId?: number;
  active?: boolean;
  airportHourId?: number;
}

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  vendorLocationHoursStore: VendorLocationHoursStore;
  settingsStore: SettingsStore;
  serviceItemPricingStore: ServiceItemPricingStore;
  onNextButtonDisable?: (boolean) => void;
  registerSaveData: (saveData: () => void) => void;
}

const MainTerminalBoth: FC<Props> = ({
  classes,
  vendorLocationStore,
  vendorLocationHoursStore,
  onNextButtonDisable,
  registerSaveData,
  serviceItemPricingStore,
  settingsStore,
}) => {
  const gridState = useGridState();
  const [ overTimeValue, setOverTimeValue ] = useState(vendorLocationStore.overTimeValue);
  const [ error, setError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ airportList, setAirportList ] = useState([]);

  const dayOfWeekIds: { [key: string]: number } = {
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
    Saturday: 7,
  };

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  const handleInputValue = event => {
    const value = event.target.value;
    vendorLocationStore.isHoursValueChange = true;
    const validFloat = /^([0-9]{1,5})(\.[0-9]{1,2})?$/;
    let errorMessage = '';

    if (!value) {
      errorMessage = 'This value is required';
    } else if (!/^\d*(\.\d*)?$/.test(value)) {
      errorMessage = 'The value must be a number';
    } else if (parseFloat(value) < 0 || parseFloat(value) > 99999.99) {
      errorMessage = 'The value must be between 0 and 99999.99';
    } else if (!validFloat.test(value)) {
      errorMessage = 'This value format is invalid';
    }

    vendorLocationStore.priceValue = value;

    if (errorMessage) {
      setErrorMessage(errorMessage);
      setError(true);
    } else {
      setError(false);
    }
  };

  const loadPricingData = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'ServiceItem.Id',
          propertyValue: '744',
        },
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: [ RootDataStore.locationData.locationId ],
          filterType: 'in',
          operator: 'and',
        },
      ]),
    };
    serviceItemPricingStore.getVMSComparison(request).subscribe(response => {
      UIStore.setPageLoader(false);
      if (response.results.length > 0) {
        const result = ServiceItemPricingModel.deserialize(response.results[0]);
        serviceItemPricingStore.pricingId = result.id;
        if (result.status.id === MODEL_STATUS.ACTIVE) {
          vendorLocationStore.priceValue = result.price;
          vendorLocationStore.feeValue = result.uom.id;
          vendorLocationStore.isCostYes = HoursCost.YES;
          vendorLocationStore.currencyValue = SettingBaseModel.deserialize(result.currency);
        }
      }
    });
  };

  const pricingErrorHandler = (errors: object): void => {
    Object.values(errors)?.forEach(errorMessage => AlertStore.info(errorMessage[0]));
  };

  const saveData = () => {
    if (vendorLocationStore.isCostYes === HoursCost.NO) {
      if (serviceItemPricingStore.pricingId) {
        updateServiceItemPricing(MODEL_STATUS.IN_ACTIVE);
      }
    } else if (
      (vendorLocationStore.priceValue && vendorLocationStore.feeValue) ||
      vendorLocationStore.currencyValue.id !== undefined
    ) {
      UIStore.setPageLoader(true);
      updateServiceItemPricing(MODEL_STATUS.ACTIVE)
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe({
          next: () => loadPricingData(),
          error: handlePricingError,
        });
    }

    updateAirportHoursIfNeeded();
  };

  const updateServiceItemPricing = (statusId: number) => {
    const model = new ServiceItemPricingModel();
    const serializedPricing = model.serializeHoursPricing(
      serviceItemPricingStore.pricingId,
      744,
      vendorLocationStore.priceValue,
      vendorLocationStore.feeValue,
      statusId,
      vendorLocationStore.currencyValue.id
    );

    return serviceItemPricingStore?.upsertServiceItemPricingLocations([ serializedPricing ]);
  };

  const handlePricingError = error => {
    const errors = error?.response?.data?.errors;
    if (errors) {
      pricingErrorHandler(errors);
      return;
    }
    AlertStore.info(error.message);
  };

  const updateAirportHoursIfNeeded = () => {
    vendorLocationStore.isHoursValueChange = false;
    if (airportList.length === 0) return;

    UIStore.setPageLoader(true);

    const hoursTypeId = getHoursTypeId('Operational') || 1;
    const hoursSubTypeId = getHoursSubTypeId('VIP Terminal') || 22;

    vendorLocationHoursStore
      ?.upsertAirportHour(
        LocationHoursModel.airportHoursSerializeList(
          vendorLocationHoursStore.overTimeHoursData,
          hoursTypeId,
          hoursSubTypeId,
          vendorLocationStore.overTimeValue === 1,
          settingsStore.airportHoursConditionType[0]?.conditionTypeId,
          settingsStore.airportHoursConditionValue[0].overtimeId,
          settingsStore.airportHoursConditionOperator[0].conditionalOperatorId
        )
      )
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(() => {
        loadInitialData();
      });
  };

  const getHoursTypeId = name => {
    const type = settingsStore.airportHoursType.find(item => item.name === name);
    return type?.id;
  };

  const getHoursSubTypeId = name => {
    const subType = settingsStore.airportHoursSubType.find(item => item.name === name);
    return subType?.id;
  };

  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    vendorLocationStore.isCostYes = HoursCost.NO;
    serviceItemPricingStore.pricingId = 0;
    vendorLocationStore.isHoursValueChange = false;
    vendorLocationStore.priceValue = null;
    vendorLocationStore.feeValue = 0;
    setError(false);
    loadInitialData();
    loadSettingHoursId();
    loadPricingData();
  }, []);

  const loadSettingHoursId = () => {
    settingsStore?.getSettings(SETTING_ID.SETTING_AIRPORT_HOURS_TYPE).subscribe();
    settingsStore?.getSettings(SETTING_ID.SETTING_AIRPORT_HOURS_SUB_TYPE).subscribe();
    settingsStore?.getSettings(SETTING_ID.SETTINGS_CURRENCY).subscribe();
    const typeRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Name',
          propertyValue: 'Overtime',
        },
      ]),
    };
    const valueRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Name',
          propertyValue: 'On Request',
        },
      ]),
    };
    const operatorRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Operator',
          propertyValue: '=',
        },
      ]),
    };
    settingsStore
      .getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.AIRPORT_CONDITION_TYPE, typeRequest)
      .subscribe();
    settingsStore
      .getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.AIRPORT_CONDITION_VALUE, valueRequest)
      .subscribe();
    settingsStore
      .getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.AIRPORT_CONDITIONAL_OPERATOR, operatorRequest)
      .subscribe();
  };

  const loadInitialData = () => {
    vendorLocationStore.isTimeChanged = false;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReference.id,
        },
        {
          propertyName: 'AirportHoursType.Name',
          propertyValue: 'Operational',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursScheduleType.Name',
          propertyValue: 'Recurrence',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursSubType.Name',
          propertyValue: 'VIP Terminal',
          operator: 'and',
        },
        {
          propertyName: 'ApprovalStatus.Name',
          propertyValue: 'Pending',
          operator: 'and',
        },
      ]),
    };
    const refRequestIAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Airport.AirportId',
          propertyValue: RootDataStore.locationData.airportReference.airportId,
        },
        {
          propertyName: 'AirportHoursType.Name',
          propertyValue: 'Operational',
          operator: 'and',
        },
        {
          propertyName: 'Schedule.ScheduleType.Name',
          propertyValue: 'Recurrence',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursSubType.Name',
          propertyValue: 'VIP Terminal',
          operator: 'and',
        },
      ]),
    };
    forkJoin([
      vendorLocationHoursStore.getAirportHours(request),
      vendorLocationHoursStore.getrefDataAirportHours(refRequestIAPIGridRequest),
    ]).subscribe(response => {
      UIStore.setPageLoader(false);
      const filteredRefResult = response[1].results.filter(
        item2 =>
          !response[0].results.some(item1 => {
            const startTime1 = moment(item1.schedule?.startTime)
              .utc()
              .format('HH:mm');
            const startTime2 = moment(item2?.schedule?.startTime)
              .utc()
              .format('HH:mm');
            const endTime1 = moment(item1.schedule?.endTime)
              .utc()
              .format('HH:mm');
            const endTime2 = moment(item2?.schedule?.endTime)
              .utc()
              .format('HH:mm');
            return item1.airportHourId === item2.airportHourId || (startTime1 === startTime2 && endTime1 === endTime2);
          })
      );
      setAirportList([ ...response[0].results, ...LocationHoursModel.deserializeList(filteredRefResult) ]);
      formatHoursData();
      if (response[0].results.length === 0) {
        setOverTimeValue(2);
        vendorLocationStore.overTimeValue = 2;
        return;
      }
      if (response[0].results && response[0].results[0]?.airportHoursCondition !== null) {
        setOverTimeValue(1);
        vendorLocationStore.overTimeValue = 1;
      } else {
        setOverTimeValue(2);
        vendorLocationStore.overTimeValue = 2;
      }
    });
  };

  const validateForm = () => {
    const isPriceValid = vendorLocationStore.priceValue !== null && vendorLocationStore.priceValue !== undefined;
    const isFeeValid = vendorLocationStore.feeValue !== 0;
    const isCurrencyValid = vendorLocationStore.currencyValue?.id !== undefined;
    const generalCondition = isPriceValid && isFeeValid && isCurrencyValid && !error;

    if (vendorLocationStore.isHoursValueChange) {
      if (vendorLocationStore.isCostYes === HoursCost.NO) {
        return false;
      }

      if (vendorLocationStore.isCostYes === HoursCost.YES) {
        return !generalCondition;
      }
    }

    return true;
  };

  useEffect(() => {
    onNextButtonDisable(validateForm() || UIStore.pageLoading);
  }, [
    vendorLocationStore.feeValue,
    vendorLocationStore.priceValue,
    error,
    vendorLocationHoursStore.airportHoursList.length,
    overTimeValue,
    vendorLocationStore.isCostYes,
    vendorLocationStore.isHoursValueChange,
    vendorLocationStore.currencyValue,
  ]);

  const areaTerminalApplicationData = [
    { id: PricingFeeCost.PerPerson, value: 'Per Person', label: 'Per person, per use (arrival or departure)' },
    { id: PricingFeeCost.PerUse, value: 'Per Use', label: 'Per facility use (regardless of pax count)' },
  ];

  const isVIPAreaData = [
    { id: HoursCost.YES, value: 'yes', label: 'Yes' },
    { id: HoursCost.NO, value: 'no', label: 'No' },
  ];

  const isOvertimePossibleData = [
    { id: 1, value: 'yes', label: 'Yes' },
    { id: 2, value: 'no', label: 'No' },
  ];

  const errorHandler = (errors: { [key: string]: string[] }): void => {
    Object.keys(errors).forEach(key => {
      const errorMessages = errors[key];
      errorMessages.forEach(message => {
        AlertStore.info(message);
      });
    });
  };

  const handleErrorResponse = error => {
    if (error?.response?.data?.errors) {
      errorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      AlertStore.info(error?.message);
    }
  };

  const deleteAllRecords = () => {
    const ids =
      vendorLocationHoursStore.timeDataHoursData && vendorLocationHoursStore.timeDataHoursData.map(item => item.id);
    if (ids.length === 0) {
      ModalStore.close();
      return;
    }
    UIStore.setPageLoader(true);
    vendorLocationHoursStore
      ?.deleteAirportHours(ids)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel[]) => {
          AlertStore.info('Airport Hours saved successfully!');
          loadInitialData();
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  const extractTime = isoString => {
    return new Date(isoString)
      .toISOString()
      .split('T')[1]
      .slice(0, 5);
  };

  const handleSave = () => {
    const hoursTypeId = settingsStore.airportHoursType.filter(item => {
      return item.name === 'Operational';
    });

    const hoursSubTypeId = settingsStore.airportHoursSubType.filter(item => {
      return item.name === 'VIP Terminal';
    });

    const filteredAirportList = airportList.filter(airportItem => {
      const airportStartTime = extractTime(airportItem.schedule.startTime);
      const airportEndTime = extractTime(airportItem.schedule.endTime);

      const existsInUpdatedHoursData = vendorLocationHoursStore.updatedHoursData.some(updatedItem => {
        const updatedStartTime = extractTime(updatedItem.scheduleRequest.startTime);
        const updatedEndTime = extractTime(updatedItem.scheduleRequest.endTime);

        return updatedStartTime === airportStartTime && updatedEndTime === airportEndTime;
      });

      return !existsInUpdatedHoursData;
    });

    const updatedFilteredAirportList = LocationHoursModel.deserializeList(
      filteredAirportList.map(item => ({
        ...item,
        status: SettingBaseModel.deserialize({ id: 2, name: 'Inactive' }),
        statusId: 2,
      }))
    );
    vendorLocationHoursStore.updatedHoursData.push(...updatedFilteredAirportList);

    UIStore.setPageLoader(true);
    vendorLocationHoursStore
      ?.upsertAirportHour(
        LocationHoursModel.airportHoursSerializeList(
          vendorLocationHoursStore.updatedHoursData,
          hoursTypeId[0].id || 1,
          hoursSubTypeId[0].id || 22,
          vendorLocationStore.overTimeValue === 1,
          settingsStore.airportHoursConditionType[0]?.conditionTypeId,
          settingsStore.airportHoursConditionValue[0].overtimeId,
          settingsStore.airportHoursConditionOperator[0].conditionalOperatorId
        )
      )
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel) => {
          AlertStore.info('Airport Hours saved successfully!');
          loadInitialData();
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  function convertToISOFormat(time: string) {
    if (moment(time, 'HH:mm', true).isValid()) {
      const [ hours, minutes ] = time.split(':');
      const date = new Date();
      date.setUTCHours(hours, minutes, 0, 0);
      return date.toISOString();
    }
  }

  const groupDaysByTimeRange = (editableTimeData: { [key: string]: TimeRange[] }) => {
    const groupedTimeData: { [key: string]: any } = {};

    for (const day in editableTimeData) {
      if (editableTimeData.hasOwnProperty(day)) {
        editableTimeData[day].forEach(
          ({
            hoursId,
            sequence,
            hoursTypeId,
            statusId,
            accessLevelId,
            startTime,
            endTime,
            patternedRecurrenceDaysofWeekId,
            startDate,
            endDate,
            is24Hours,
            includeHoliday,
            patternedRecurrenceId,
            scheduleId,
            airportHourId,
          }) => {
            const timeKey = `${startTime}-${endTime}`;

            if (groupedTimeData[timeKey]) {
              groupedTimeData[
                timeKey
              ].scheduleRequest.patternedRecurrenceRequest.patternedRecurrenceDaysofWeekRequest.push({
                id: patternedRecurrenceDaysofWeekId || 0,
                dayOfWeekId: dayOfWeekIds[day],
              });
            } else {
              groupedTimeData[timeKey] = {
                id: hoursId,
                userId: '',
                vendorLocationId: RootDataStore.locationData.locationId,
                sequence,
                airportHourId,
                hoursTypeId,
                statusId,
                accessLevelId,
                hoursScheduleTypeId: 1,
                scheduleRequest: {
                  id: scheduleId,
                  startTime: convertToISOFormat(startTime),
                  endTime: convertToISOFormat(endTime),
                  startDate,
                  endDate,
                  is24Hours: Boolean(
                    moment(startTime)
                      .utc()
                      .format('HH:mm') === '00:01' &&
                      moment(endTime)
                        .utc()
                        .format('HH:mm') === '23:59'
                  ),
                  includeHoliday,
                  patternedRecurrenceRequest: {
                    id: patternedRecurrenceId,
                    patternedRecurrenceDaysofWeekRequest: [
                      {
                        id: patternedRecurrenceDaysofWeekId || 0,
                        dayOfWeekId: dayOfWeekIds[day],
                      },
                    ],
                  },
                },
              };
            }
          }
        );
      }
    }

    const newState = Object.values(groupedTimeData);
    return newState;
  };

  const formatHoursData = () => {
    const updatedTimeData: { [key: string]: TimeRange[] } = dayNames.reduce((acc, day) => {
      acc[day] = [];
      return acc;
    }, {} as { [key: string]: TimeRange[] });

    airportList &&
      airportList.forEach(item => {
        const { schedule, id: hoursId, airportHourId } = item;
        const { patternedRecurrence } = schedule;
        patternedRecurrence.patternedRecurrenceDaysofWeek.forEach(day => {
          const dayName = day.dayOfWeek.name;
          if (updatedTimeData[dayName]) {
            const startTime = new Date(schedule.startTime).toISOString().slice(11, 16);
            const endTime = new Date(schedule.endTime).toISOString().slice(11, 16);

            updatedTimeData[dayName].push({
              id: `original-${dayName}-${startTime}-${endTime}`,
              startTime,
              endTime,
              is24Hours: Boolean(
                moment(schedule.startTime)
                  .utc()
                  .format('HH:mm') === '00:01' &&
                  moment(schedule.endTime)
                    .utc()
                    .format('HH:mm') === '23:59'
              ),
              hoursId,
              airportHourId,
              scheduleId: schedule.id,
              patternedRecurrenceId: patternedRecurrence.id,
              isNew: false,
              sequence: item.sequence,
              hoursTypeId: item.hoursType.id,
              statusId: item.status.id,
              accessLevelId: item.accessLevel.id,
              startDate: schedule.startDate,
              endDate: schedule.endDate,
              includeHoliday: schedule.includeHoliday,
              dayOfWeekId: dayOfWeekIds[dayName],
              patternedRecurrenceDaysofWeekId: day.id,
              active: true,
            });
          }
        });
      });
    vendorLocationHoursStore.overTimeHoursData = groupDaysByTimeRange(updatedTimeData).filter(item => {
      const startTime = moment(item.scheduleRequest.startTime)
        .utc()
        .format('HH:mm');
      const endTime = moment(item.scheduleRequest.endTime)
        .utc()
        .format('HH:mm');
      return !(startTime === '00:00' && endTime === '00:00') && item.id !== 0;
    });
  };

  const onRadioChange = (value: number) => {
    vendorLocationStore.isHoursValueChange = true;
    vendorLocationStore.isCostYes = value;
    if (value === HoursCost.YES) {
      vendorLocationStore.priceValue = null;
      vendorLocationStore.feeValue = 0;
      vendorLocationStore.currencyValue = null;
      setError(false);
      loadPricingData();
    }
  };

  const onFeeApplicationValueChange = (value: number) => {
    vendorLocationStore.feeValue = value;
    vendorLocationStore.isHoursValueChange = true;
  };

  const onOvertimeValueChange = (value: number) => {
    vendorLocationStore.isHoursValueChange = true;
    vendorLocationStore.overTimeValue = value;
    setOverTimeValue(value);
  };

  return (
    <div className={classes.mainTerminalOnlyWrapper}>
      <Box>
        <CustomAccordion
          panelName="vipAreaTerminalOperationalHours"
          panelHeading="VIP Area/Terminal - Operational Hours (in local time)"
          panelContent={<MainTerminalBothOperationalHours handleSave={handleSave} locationHoursList={airportList} />}
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="vipAreaOvertime"
          panelHeading="VIP Area/Terminal Overtime"
          panelBodyHeading="Is overtime possible?"
          panelContent={
            <AccordionRadioGroups
              radioValue={vendorLocationStore.overTimeValue}
              onRadioChange={onOvertimeValueChange}
              radioGroupData={isOvertimePossibleData}
              isOverTime={true}
            />
          }
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="isVipAreaCostApplicable"
          panelHeading="VIP Area/Terminal Cost"
          panelBodyHeading="Is there a fee for using the VIP Area/Terminal?"
          panelContent={
            <Box>
              <AccordionRadioGroups
                radioValue={vendorLocationStore.isCostYes}
                onRadioChange={onRadioChange}
                radioGroupData={isVIPAreaData}
              />
              {vendorLocationStore.isCostYes === HoursCost.YES && (
                <Box>
                  <Box>
                    <FormControl variant="outlined">
                      <Typography className={classes.heading}>
                        What is the cost for using the VIP Area/Terminal, at the Main Terminal?
                      </Typography>
                      <Box className={classes.costFields}>
                        <OutlinedInput
                          className="inputTextField"
                          id="outlined-adornment-terminal-cost"
                          value={vendorLocationStore.priceValue}
                          onChange={handleInputValue}
                          aria-describedby="outlined-terminal-cost-text"
                          inputProps={{
                            'aria-label': 'terminal cost',
                          }}
                          labelWidth={0}
                          style={{
                            borderColor: error ? 'red' : 'initial',
                            borderWidth: '1px',
                            borderStyle: error ? 'solid' : 'none',
                          }}
                          placeholder="Enter Cost"
                        />
                        <Autocomplete
                          autoHighlight
                          getOptionSelected={(option, value) => option.id === value.id}
                          value={vendorLocationStore.currencyValue}
                          options={settingsStore.vendorSettingsCurrency}
                          renderInput={params => <TextField {...params} placeholder="Select Currency" />}
                          getOptionLabel={option => option.label}
                          onChange={(event: any, newValue) => {
                            vendorLocationStore.isHoursValueChange = true;
                            vendorLocationStore.currencyValue = SettingBaseModel.deserialize(newValue);
                          }}
                          className={classes.inputDropdown}
                        />
                      </Box>
                      {error && <FormHelperText style={{ color: 'red' }}>{errorMessage}</FormHelperText>}
                    </FormControl>
                  </Box>
                  <AccordionRadioGroups
                    radioValue={vendorLocationStore.feeValue}
                    onRadioChange={onFeeApplicationValueChange}
                    radioGroupData={areaTerminalApplicationData}
                    label="Is this fee person, or per facility use?"
                  />
                </Box>
              )}
            </Box>
          }
        />
      </Box>
    </div>
  );
};

export default inject(
  'vendorLocationStore',
  'vendorLocationHoursStore',
  'serviceItemPricingStore',
  'settingsStore'
)(withStyles(styles)(observer(MainTerminalBoth)));

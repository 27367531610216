import React, { FC, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import CustomAccordion from '../../../../../Shared/Components/CustomAccordion/CustomAccordion';
import { useUnsubscribe } from '@wings-shared/hooks';
import {
  IClasses,
  UIStore,
  IAPISearchFiltersDictionary,
  Loader,
  IAPIGridRequest,
  IAPIPageResponse,
} from '@wings-shared/core';
import {
  BaseStore,
  PassengerLogisticsStore,
  SettingsStore,
  VendorDocumentStore,
  VendorLocationStore,
} from '../../../../../../Stores';
import { styles } from './PassengerTransport.styles';
import Landside from './Components/Landside';
import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  TextField,
  Typography,
  withStyles,
  IconButton,
} from '@material-ui/core';
import AccordionRadioGroups from '../../../../../Shared/Components/RadioGroup/AccordionRadioGroups';
import {
  COLLECTION_NAMES,
  CustomDialog,
  SETTING_ID,
  UploadDocumentOrMap,
  VendorDocumentModel,
} from '../../../../../Shared';
import { RootDataStore } from '@uplink-shared/layout';
import { finalize, takeUntil } from 'rxjs/operators';
import { PassengerTransportModel } from '../../../../../Shared/Models/PassengerTransport.model';
import { useBaseUpsertComponent } from '@uplink/shared';
import { DropOffAddressModel } from '../../../../../Shared/Models/DropOffAddress.model';
import { transportFieldsWithRules } from './Components/Fields';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { CloseIcon, UploadIcon } from '@uvgo-shared/icons';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { PROGRESS_TYPES } from '@uvgo-shared/progress';
import DownloadIcon from '@material-ui/icons/GetApp';
import { AuthStore } from '@uplink-shared/security';

interface Props {
  searchFilters?: IAPISearchFiltersDictionary;
  classes?: IClasses;
  settingsStore: SettingsStore;
  vendorLocationStore: VendorLocationStore;
  vendorDocumentStore: VendorDocumentStore;
  passengerLogisticsStore: PassengerLogisticsStore;
  onNextButtonDisable?: (boolean) => void;
  registerSaveData: (saveData: () => void) => void;
  updateIsBlocker: (value: boolean) => void;
}

const PassengerTransport: FC<Props> = ({
  classes,
  searchFilters,
  onNextButtonDisable,
  registerSaveData,
  settingsStore,
  vendorLocationStore,
  passengerLogisticsStore,
  vendorDocumentStore,
  updateIsBlocker,
}) => {
  const progressLoader: Loader = new Loader(false, { type: PROGRESS_TYPES.CIRCLE });

  const useUpsertArrivalLandside = useBaseUpsertComponent<DropOffAddressModel>(
    {},
    transportFieldsWithRules,
    searchFilters
  );
  const useUpsertArrivalAirside = useBaseUpsertComponent<DropOffAddressModel>(
    {},
    transportFieldsWithRules,
    searchFilters
  );

  const useUpsertDepartureLandside = useBaseUpsertComponent<DropOffAddressModel>(
    {},
    transportFieldsWithRules,
    searchFilters
  );
  const useUpsertDepartureAirside = useBaseUpsertComponent<DropOffAddressModel>(
    {},
    transportFieldsWithRules,
    searchFilters
  );

  const [ arrivalErrorMessage, setArrivalErrorMessage ] = useState('');
  const [ departureErrorMessage, setDepartureErrorMessage ] = useState('');

  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    passengerLogisticsStore.disableSaveButton = true;
    passengerLogisticsStore.isValueChanged = false;
    passengerLogisticsStore.arrivalAdditionalInfo = '';
    passengerLogisticsStore.departureAdditionalInfo = '';
    vendorDocumentStore.file = null;
    setDropDownLabels();
    settingsStore.getSettings(SETTING_ID.SETTING_DRIVER_LOCATION_CREW).subscribe();
    loadInitialData();
  }, []);

  useEffect(() => {
    loadLocationDocuments();
  }, [ vendorDocumentStore.documentRemove, vendorDocumentStore.documentUpdated ]);

  const loadLocationDocuments = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
        },
        {
          propertyName: 'Status.Id',
          propertyValue: 4,
          filterType: 'ne',
          operator: 'and',
        },
      ]),
    };
    vendorDocumentStore
      .getVendorDocument(COLLECTION_NAMES.VENDOR_LOCATION_DOCUMENT, request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe();
  };

  const getConfirmation = (item: VendorDocumentModel): void => {
    ModalStore.open(
      <CustomDialog
        title="Remove this Document"
        message={'Are you sure you want to remove this Document?'}
        yesButton="Remove this Document"
        noButton="Cancel"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => upsertLocationDocument(item, 4)}
      />
    );
  };

  const upsertLocationDocument = (item, statusId): void => {
    vendorDocumentStore.documentRemove = false;
    UIStore.setPageLoader(true);
    const request = new VendorDocumentModel({ ...item });
    vendorDocumentStore
      ?.upsertLocationDocument(
        request.serialize(
          item.documentUri,
          AuthStore?.vendorProfile?.id,
          RootDataStore.locationData.locationId,
          statusId
        )
      )
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorDocumentModel) => {
          vendorDocumentStore.documentUpdated = false;
          vendorDocumentStore.documentRemove = true;
          vendorDocumentStore.documentUri = null;
          vendorDocumentStore.file = null;
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, request.id.toString());
            return;
          }
          BaseStore.showAlert(error.message, request.id);
        },
      });
  };

  const downloadFile = (data: VendorDocumentModel) => {
    UIStore.setPageLoader(true);
    vendorDocumentStore
      ?.downloadLocationDocumentFile(data.documentUri, data.id, RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: IAPIDownloadDocumentFile) => {
          const link = document.createElement('a');
          link.href = response.documentUri;
          link.target = '_blank';
          if (data.otherName !== null) {
            link.download = data.otherName;
          } else {
            link.download = data.name.name;
          }
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: error => {
          BaseStore.showAlert(`Error Downloading ${error.message}`, 0);
        },
      });
  };

  const hasNonNullFields = obj => {
    return Object.values(obj).some(value => value !== null);
  };

  const hasNullFields = obj => {
    const requiredFields = [
      'pickUpLocationCrew',
      'pickUpLocationPax',
      'addressLine1',
      'zipCode',
      'hqAddressCountry',
      'hqAddressCity',
    ];
    return requiredFields.some(field => obj[field] === null || obj[field] === undefined);
  };

  const setDropDownLabels = () => {
    useUpsertArrivalLandside.getField('pickUpLocationCrew').set('label', 'Driver Pick up location Type - Crew*');
    useUpsertArrivalLandside.getField('pickUpLocationPax').set('label', 'Driver Pick up location Type - Pax*');

    useUpsertArrivalAirside.getField('pickUpLocationCrew').set('label', 'Driver Pick up location Type - Crew*');
    useUpsertArrivalAirside.getField('pickUpLocationPax').set('label', 'Driver Pick up location Type - Pax*');

    useUpsertDepartureLandside.getField('pickUpLocationCrew').set('label', 'Driver Drop off location Type - Crew*');
    useUpsertDepartureLandside.getField('pickUpLocationPax').set('label', 'Driver Drop off location Type - Pax*');

    useUpsertDepartureAirside.getField('pickUpLocationCrew').set('label', 'Driver Drop off location Type - Crew*');
    useUpsertDepartureAirside.getField('pickUpLocationPax').set('label', 'Driver Drop off location Type - Pax*');
  };

  useEffect(() => {
    const errorsExist =
      hasNonNullFields(useUpsertArrivalLandside.form.errors()) ||
      hasNonNullFields(useUpsertArrivalAirside.form.errors()) ||
      hasNonNullFields(useUpsertDepartureLandside.form.errors()) ||
      hasNonNullFields(useUpsertDepartureAirside.form.errors());
    const formsNotChanged =
      !useUpsertArrivalLandside.form.changed ||
      !useUpsertArrivalAirside.form.changed ||
      !useUpsertDepartureLandside.form.changed ||
      !useUpsertDepartureAirside.form.changed ||
      !passengerLogisticsStore.arrivalAdditionalInfo ||
      !passengerLogisticsStore.departureAdditionalInfo;

    passengerLogisticsStore.isValueChanged =
      useUpsertArrivalLandside.form.changed ||
      useUpsertArrivalAirside.form.changed ||
      useUpsertDepartureLandside.form.changed ||
      useUpsertDepartureAirside.form.changed;

    updateIsBlocker(passengerLogisticsStore.isValueChanged);

    onNextButtonDisable(
      errorsExist ||
        !formsNotChanged ||
        passengerLogisticsStore.disableSaveButton ||
        arrivalErrorMessage ||
        departureErrorMessage
    );
  }, [
    hasNonNullFields(useUpsertArrivalLandside.form.errors()),
    hasNonNullFields(useUpsertArrivalAirside.form.errors()),
    hasNonNullFields(useUpsertDepartureLandside.form.errors()),
    hasNonNullFields(useUpsertDepartureAirside.form.errors()),
    passengerLogisticsStore.isArrivalAirsidePickupData,
    passengerLogisticsStore.isArrivalSameLandisidePickupData,
    passengerLogisticsStore.isDepartureSameAsArrival,
    passengerLogisticsStore.isDepartureAirsidePickupData,
    passengerLogisticsStore.isDepartureSameLandisidePickupData,
    passengerLogisticsStore.disableSaveButton,
    arrivalErrorMessage,
    departureErrorMessage,
    passengerLogisticsStore.arrivalAdditionalInfo,
    passengerLogisticsStore.departureAdditionalInfo,
    passengerLogisticsStore.isValueChanged,
  ]);

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  useEffect(() => {
    if (hasNullFields(useUpsertArrivalLandside.form.values())) {
      passengerLogisticsStore.disableSaveButton = true;
      return;
    }
    if (passengerLogisticsStore.isArrivalSameLandisidePickupData === 4) {
      if (hasNullFields(useUpsertArrivalAirside.form.values())) {
        passengerLogisticsStore.disableSaveButton = true;
        return;
      }
    }
    if (passengerLogisticsStore.isDepartureSameAsArrival === 6) {
      if (hasNullFields(useUpsertDepartureLandside.form.values())) {
        passengerLogisticsStore.disableSaveButton = true;
        return;
      }
    }
    if (passengerLogisticsStore.isDepartureSameLandisidePickupData === 44) {
      if (hasNullFields(useUpsertDepartureAirside.form.values())) {
        passengerLogisticsStore.disableSaveButton = true;
        return;
      }
    } else if (hasNonNullFields(useUpsertDepartureLandside.form.values())) {
      passengerLogisticsStore.disableSaveButton = false;
    }
  }, [
    passengerLogisticsStore.isArrivalAirsidePickupData,
    passengerLogisticsStore.isArrivalSameLandisidePickupData,
    passengerLogisticsStore.isDepartureSameAsArrival,
    passengerLogisticsStore.isDepartureAirsidePickupData,
    passengerLogisticsStore.isDepartureSameLandisidePickupData,
  ]);

  const setFormValues = passengerTransportData => {
    const arrivalItems = [];
    const departureItems = [];
    if (passengerTransportData && passengerTransportData.length > 0) {
      passengerTransportData?.forEach(item => {
        if (item.passengerTransportType === 1) {
          passengerLogisticsStore.isArrivalAirsidePickupData = item.isArrangeAirSideDropoff ? 1 : 2;
          passengerLogisticsStore.isArrivalSameLandisidePickupData = item.isSameAsDeparture ? 3 : 4;
          passengerLogisticsStore.arrivalAdditionalInfo = item.additionalInfo;
          passengerLogisticsStore.isDepartureSameAsArrival = item.isSameAsArrival ? 5 : 6;
          arrivalItems.push(item);
        } else if (item.passengerTransportType === 2) {
          passengerLogisticsStore.isDepartureAirsidePickupData = item.isArrangeAirSideDropoff ? 11 : 22;
          passengerLogisticsStore.isDepartureSameLandisidePickupData = item.isSameAsDeparture ? 33 : 44;
          passengerLogisticsStore.departureAdditionalInfo = item.additionalInfo;
          departureItems.push(item);
        }
      });
    }
    passengerLogisticsStore.arrivalData = arrivalItems ? arrivalItems[0] : new DropOffAddressModel();
    passengerLogisticsStore.departureData = departureItems ? departureItems[0] : new DropOffAddressModel();
    passengerLogisticsStore.disableSaveButton = true;
    passengerLogisticsStore.isValueChanged = false;
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      .getVendorLocationById(RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          if (response.operationalInsight?.vendorLocationPassengerTransport) {
            setFormValues(response.operationalInsight?.vendorLocationPassengerTransport);
          } else {
            passengerLogisticsStore.arrivalData = new DropOffAddressModel();
            passengerLogisticsStore.departureData = new DropOffAddressModel();
          }
        },
        error: error => {
          BaseStore.showAlert(error.message.toString(), 0);
        },
      });
  };

  const isArrivalAirsidePickupGroupData = [
    { id: 1, value: 'yes', label: 'Yes' },
    { id: 2, value: 'no', label: 'No' },
  ];

  const isArrivalSameLandsidePickupGroupData = [
    { id: 3, value: 'yes', label: 'Yes' },
    { id: 4, value: 'no', label: 'No' },
  ];

  const isDepartureAirsidePickupGroupData = [
    { id: 11, value: 'yes', label: 'Yes' },
    { id: 22, value: 'no', label: 'No' },
  ];

  const isDepartureSameLandsidePickupGroupData = [
    { id: 33, value: 'yes', label: 'Yes' },
    { id: 44, value: 'no', label: 'No' },
  ];

  const isDepartureSameAsArrivalGroupData = [
    { id: 5, value: 'yes', label: 'Yes' },
    { id: 6, value: 'no', label: 'No' },
  ];

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => BaseStore.showAlert(errorMessage[0], id));
  };

  const saveData = () => {
    const arrivalRequest = new PassengerTransportModel({
      id: passengerLogisticsStore.arrivalData?.id || 0,
      passengerTransportType: 1,
      isArrangeAirSideDropoff: passengerLogisticsStore.isArrivalAirsidePickupData === 1 ? true : false,
      isSameAsDeparture:
        passengerLogisticsStore.isArrivalAirsidePickupData === 2
          ? false
          : passengerLogisticsStore.isArrivalSameLandisidePickupData === 3
            ? true
            : false,
      isSameAsArrival: passengerLogisticsStore.isDepartureSameAsArrival === 5 ? true : false,
      landsideDropOffAddress: new DropOffAddressModel().serialize(useUpsertArrivalLandside.form.values()),
      airsideDropOff:
        passengerLogisticsStore.isArrivalAirsidePickupData === 2
          ? null
          : passengerLogisticsStore.isArrivalSameLandisidePickupData === 4
            ? new DropOffAddressModel().serialize(useUpsertArrivalAirside.form.values())
            : null,
      additionalInfo: passengerLogisticsStore.arrivalAdditionalInfo,
    });
    const departureRequest = new PassengerTransportModel({
      id: passengerLogisticsStore.departureData?.id || 0,
      passengerTransportType: 2,
      isArrangeAirSideDropoff: passengerLogisticsStore.isDepartureAirsidePickupData === 11 ? true : false,
      isSameAsDeparture:
        passengerLogisticsStore.isDepartureAirsidePickupData === 22
          ? false
          : passengerLogisticsStore.isDepartureSameLandisidePickupData === 33
            ? true
            : false,
      isSameAsArrival: false,
      landsideDropOffAddress: new DropOffAddressModel().serialize(useUpsertDepartureLandside.form.values()),
      airsideDropOff:
        passengerLogisticsStore.isDepartureAirsidePickupData === 22
          ? null
          : passengerLogisticsStore.isDepartureSameLandisidePickupData === 44
            ? new DropOffAddressModel().serialize(useUpsertDepartureAirside.form.values())
            : null,
      additionalInfo: passengerLogisticsStore.departureAdditionalInfo,
    });
    const requestList = [];
    requestList.push(arrivalRequest);
    if (passengerLogisticsStore.isDepartureSameAsArrival === 5) {
      const clonedDepartureRequestt = new PassengerTransportModel({ ...arrivalRequest });
      clonedDepartureRequestt.id = passengerLogisticsStore.departureData?.id || 0;
      clonedDepartureRequestt.passengerTransportType = 2;
      requestList.push(clonedDepartureRequestt);
    } else {
      requestList.push(departureRequest);
    }
    UIStore.setPageLoader(true);
    passengerLogisticsStore
      ?.upsertPassengerTransport(arrivalRequest.serializelist(requestList))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: PassengerTransportModel) => {
          setFormValues(response);
        },
        error: error => {
          if (error?.response?.data?.errors) {
            errorHandler(error.response?.data?.errors, arrivalRequest.id.toString());
            return;
          }
          if (error?.message) {
            BaseStore.showAlert(error?.message, arrivalRequest.id);
            return;
          }
        },
      });
  };

  const uploadDocumentFile = (): void => {
    UIStore.setPageLoader(true);
    settingsStore.getSettings(SETTING_ID.SETTING_DOCUMENT_NAME).subscribe(response => {
      const documentNameId = response?.results?.find(item => item.name.toLocaleLowerCase() === 'other')?.id;
      progressLoader.setLoadingState(true);

      const existingNumbers = vendorDocumentStore.vendorDocuments
        .map(doc => {
          const match = doc.otherName?.toLocaleLowerCase().match(/^transportation (\d+)$/);
          return match ? parseInt(match[1], 10) : null;
        })
        .filter(num => num !== null)
        .sort((a, b) => a - b);

      let nextNumber = 1;

      for (const num of existingNumbers) {
        if (num === nextNumber) {
          nextNumber++;
        } else {
          break;
        }
      }

      vendorDocumentStore
        ?.importDocumentFile(
          COLLECTION_NAMES.VENDOR_LOCATION_DOCUMENT,
          vendorDocumentStore.file[0],
          RootDataStore.locationData.locationId
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => {
            UIStore.setPageLoader(false);
            progressLoader.setLoadingState(false);
          })
        )
        .subscribe({
          next: (response: IAPIDocumentFile) => {
            if (response) {
              const { vendorProfile, user } = AuthStore;
              const { locationId } = RootDataStore.locationData;
              const newOtherName = `Transportation ${nextNumber}`;

              const request = new VendorDocumentModel({
                userId: '',
                id: 0,
                documentUri: response.results || null,
                statusId: 1,
                documentNameId,
                vendorId: vendorProfile?.id || null,
                otherName: newOtherName,
                startDate: new Date(),
                endDate: null,
                comment: null,
                lastUpdated: user?.name || '',
                vendorLocationId: locationId || null,
              });

              upsertLocationDocument(request, 1);
              vendorDocumentStore.documentUpdated = true;
              vendorDocumentStore.documentUri = response.results;
              BaseStore.showAlert('Document File imported successfully', 0);
              ModalStore.close();
              vendorDocumentStore.documentRemove = false;
            }
          },
          error: error => {
            if (error.response.data.errors) {
              errorHandler(error.response.data.errors, '0');
              return;
            }
            BaseStore.showAlert(`Records imported with errors ${error.message}`, 0);
          },
        });
    });
  };

  const onRequestImportDocument = (): void => {
    ModalStore.open(
      <UploadDocumentOrMap
        fileType=".doc, .pdf, .jpg, .jpeg, .png"
        title="Attachments (Only .PDF, .DOC, .JPEG, .PNG & .JPG FILE UP TO 10MB)"
        uploadDocumentFile={() => uploadDocumentFile()}
        loader={progressLoader}
      />
    );
  };

  return (
    <div className={classes.mainTerminalOnlyWrapper}>
      <Box>
        <CustomAccordion
          panelName="gatOperationalHours"
          panelHeading="Landside Pick up"
          panelContent={
            <Landside
              key={'arrivalLandsideAddress'}
              data={passengerLogisticsStore.arrivalData?.landsideDropOffAddress}
              useUpsert={useUpsertArrivalLandside}
            />
          }
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="gatOperationalHours"
          panelHeading="Air side passenger pickup"
          panelBodyHeading="Is it possible to arrange airside pickup?"
          panelContent={
            <Box className={classes.pickupAddress}>
              <AccordionRadioGroups
                radioValue={passengerLogisticsStore.isArrivalAirsidePickupData}
                onRadioChange={value => {
                  passengerLogisticsStore.isArrivalAirsidePickupData = value;
                  passengerLogisticsStore.disableSaveButton = false;
                }}
                radioGroupData={isArrivalAirsidePickupGroupData}
              />
              {passengerLogisticsStore.isArrivalAirsidePickupData === 1 && (
                <Box>
                  <AccordionRadioGroups
                    radioValue={passengerLogisticsStore.isArrivalSameLandisidePickupData}
                    onRadioChange={value => {
                      passengerLogisticsStore.isArrivalSameLandisidePickupData = value;
                      passengerLogisticsStore.disableSaveButton = false;
                    }}
                    radioGroupData={isArrivalSameLandsidePickupGroupData}
                    label="Is this address is the same as for land side pick up?"
                  />
                  {passengerLogisticsStore.isArrivalSameLandisidePickupData === 4 && (
                    <Landside
                      key={'arrivalAirsideAddress'}
                      data={passengerLogisticsStore.arrivalData?.airsideDropOff}
                      useUpsert={useUpsertArrivalAirside}
                    />
                  )}
                </Box>
              )}
            </Box>
          }
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="gatOperationalHours"
          panelHeading="Is there any additional information required for passenger transportation pickup?"
          panelBodyHeading="Additional Instructions (directions, gate/terminal etc.)"
          panelContent={
            <Box>
              <FormControl variant="outlined">
                <OutlinedInput
                  className="inputTextField"
                  value={passengerLogisticsStore.arrivalAdditionalInfo}
                  onChange={e => {
                    passengerLogisticsStore.arrivalAdditionalInfo = e.target.value;
                    if (e.target.value.length > 100) {
                      setArrivalErrorMessage('This field must be between 1 and 100 characters.');
                      passengerLogisticsStore.disableSaveButton = true;
                    } else {
                      setArrivalErrorMessage('');
                      passengerLogisticsStore.disableSaveButton = false;
                    }
                  }}
                  labelWidth={0}
                  style={{
                    borderColor: arrivalErrorMessage ? 'red' : 'initial',
                    borderWidth: '1px',
                    borderStyle: arrivalErrorMessage ? 'solid' : 'none',
                  }}
                  placeholder="Enter here"
                />
                {arrivalErrorMessage && <FormHelperText style={{ color: 'red' }}>{arrivalErrorMessage}</FormHelperText>}
              </FormControl>
              <Box>
                <Typography className="radioLabel">Upload Document(s) or Map(s)</Typography>
                <div className={`${classes.primaryButton} ${classes.customButton}`}>
                  <PrimaryButton
                    variant="outlined"
                    color="primary"
                    className={classes.primaryButton}
                    startIcon={<UploadIcon />}
                    onClick={onRequestImportDocument}
                  >
                    Upload
                  </PrimaryButton>
                </div>
                <Box className={classes.uploadedFileBox}>
                  {vendorDocumentStore.vendorDocuments
                    ?.filter(document => document?.otherName?.toLowerCase().includes('transportation'))
                    .sort((a, b) => {
                      const getNumber = (doc: any) => {
                        const match = doc?.otherName?.match(/(\d+)$/);
                        return match ? parseInt(match[1], 10) : Infinity;
                      };

                      return getNumber(a) - getNumber(b);
                    })
                    .map((document, index) => (
                      <Box key={document.id || index} className={classes.fileBox}>
                        <Box className={classes.innerFileBox}>
                          <PrimaryButton variant="text" onClick={() => downloadFile(document)}>
                            <DownloadIcon />
                          </PrimaryButton>
                          <Typography className={classes.fileName}>
                            {document?.otherName || document?.name?.name || 'Unknown Document'}
                          </Typography>
                        </Box>
                        <Box className={classes.fileSizeBox}>
                          <IconButton
                            onClick={() => {
                              getConfirmation(document);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          }
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="gatOperationalHours"
          panelHeading="Passenger departure transportation process"
          panelContent={
            <Box className={classes.pickupAddress}>
              <AccordionRadioGroups
                radioValue={passengerLogisticsStore.isDepartureSameAsArrival}
                onRadioChange={value => (passengerLogisticsStore.isDepartureSameAsArrival = value)}
                radioGroupData={isDepartureSameAsArrivalGroupData}
                label="Are the departure processes and addresses the same as for arrival?"
              />
              {passengerLogisticsStore.isDepartureSameAsArrival === 6 && (
                <Box>
                  <Landside
                    key={'departureLandsideAddress'}
                    data={passengerLogisticsStore.departureData?.landsideDropOffAddress}
                    useUpsert={useUpsertDepartureLandside}
                  />
                  <AccordionRadioGroups
                    radioValue={passengerLogisticsStore.isDepartureAirsidePickupData}
                    onRadioChange={value => (passengerLogisticsStore.isDepartureAirsidePickupData = value)}
                    radioGroupData={isDepartureAirsidePickupGroupData}
                    label="Is it possible to arrange airside drop off?"
                  />
                  {passengerLogisticsStore.isDepartureAirsidePickupData === 11 && (
                    <Box>
                      <AccordionRadioGroups
                        radioValue={passengerLogisticsStore.isDepartureSameLandisidePickupData}
                        onRadioChange={value => (passengerLogisticsStore.isDepartureSameLandisidePickupData = value)}
                        radioGroupData={isDepartureSameLandsidePickupGroupData}
                        label="Is this address is the same as for land side pick up?"
                      />
                      {passengerLogisticsStore.isDepartureSameLandisidePickupData === 44 && (
                        <Landside
                          key={'DepartureAirsideAddress'}
                          data={passengerLogisticsStore.departureData?.airsideDropOff}
                          useUpsert={useUpsertDepartureAirside}
                        />
                      )}
                    </Box>
                  )}
                  <FormControl variant="outlined">
                    <Typography className="radioLabel">
                      {'Additional Instructions (directions, gate/terminal etc.)'}
                    </Typography>
                    <OutlinedInput
                      className="inputTextField"
                      value={passengerLogisticsStore.departureAdditionalInfo}
                      onChange={e => {
                        passengerLogisticsStore.departureAdditionalInfo = e.target.value;
                        if (e.target.value.length > 100) {
                          setDepartureErrorMessage('This field must be between 1 and 100 characters.');
                          passengerLogisticsStore.disableSaveButton = true;
                        } else {
                          setDepartureErrorMessage('');
                          passengerLogisticsStore.disableSaveButton = false;
                        }
                      }}
                      labelWidth={0}
                      style={{
                        borderColor: departureErrorMessage ? 'red' : 'initial',
                        borderWidth: '1px',
                        borderStyle: departureErrorMessage ? 'solid' : 'none',
                      }}
                      placeholder="Enter here"
                    />
                    {departureErrorMessage && (
                      <FormHelperText style={{ color: 'red' }}>{departureErrorMessage}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              )}
            </Box>
          }
        />
      </Box>
    </div>
  );
};

export default inject(
  'settingsStore',
  'vendorLocationStore',
  'passengerLogisticsStore',
  'vendorDocumentStore'
)(withStyles(styles)(observer(PassengerTransport)));

import React, { FC, ReactNode, useEffect, useState } from 'react';
import { HealthRequirementStore, SettingsStore, VendorManagementStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { CountryModel, NO_SQL_COLLECTIONS, useBaseUpsertComponent } from '@uplink/shared';
import {
  UIStore,
  IAPISearchFiltersDictionary,
  IOptionValue,
  IClasses,
  IAPIGridRequest,
  SEARCH_ENTITY_TYPE,
  IAPIPageResponse,
} from '@wings-shared/core';
import { forkJoin } from 'rxjs';
import {
  SettingBaseModel,
  ViewInputControls,
  SidebarMenuOperationalInfo,
  HealthRequirementModel,
  COLLECTION_NAMES,
  CustomDialog,
} from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { ConfirmNavigate, DetailsEditorWrapper, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { Box, Button, Switch, Typography, withStyles } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { styles } from './HealthRequirements.styles';
import { fields, nonRequiredfields } from './Fields';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { ModalStore } from '@uvgo-shared/modal-keeper';

interface Props {
  classes?: IClasses;
  settingsStore: SettingsStore;
  healthRequirementStore: HealthRequirementStore;
  vendorManagementStore: VendorManagementStore;
  searchFilters: IAPISearchFiltersDictionary;
}

const HealthRequirements: FC<Props> = ({
  settingsStore,
  healthRequirementStore,
  vendorManagementStore,
  classes,
  searchFilters,
}) => {
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();
  const [ isDisinsection, setIsDisinsection ] = useState(false);
  const useUpsert = useBaseUpsertComponent<HealthRequirementModel>(
    {},
    isDisinsection ? fields : nonRequiredfields,
    searchFilters
  );
  const formRef = useUpsert.form;
  const [ countryList, setCountryList ] = useState<CountryModel[]>([]);
  const gridState = useGridState();

  useEffect(() => {
    SidebarStore.setNavLinks(
      SidebarMenuOperationalInfo(),
      'vendor',
      'Operational Information',
      '/vendor/locations',
      'panel2'
    );
    loadDropDownData();
    healthRequirementStore.formValueChanged = false;
  }, []);

  const loadDropDownData = () => {
    UIStore.setPageLoader(true);
    forkJoin([
      settingsStore.getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.DISINSECTION_TYPE),
      settingsStore.getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.DISINSECTION_CHEMICAL),
      vendorManagementStore.getVmsCountryCode(),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: [SettingBaseModel[], SettingBaseModel[], IAPIPageResponse<CountryModel>]) => {
        loadInitialData();
        setCountryList(response[2].results);
      });
  };

  const setFormValues = (data: HealthRequirementModel) => {
    healthRequirementStore.formValueChanged = false;
    setIsDisinsection(data?.isDisinsection);
    useUpsert.setFormValues(data);
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
        },
      ]),
    };
    healthRequirementStore
      .getHealthRequirementData(COLLECTION_NAMES.HEALTH_REQUIREMENT, request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: HealthRequirementModel[]) => {
        if (response.results.length > 0) {
          const data = response.results[0];
          setFormValues(HealthRequirementModel.deserialize(data));
        }
      });
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    healthRequirementStore.formValueChanged = true;
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'appliedDisinsectionCountries':
        setCountryList(vendorManagementStore.countries);
        break;
      default:
        break;
    }
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'appliedDisinsectionCountries':
        const filteredList = vendorManagementStore.countries.filter(country => {
          return (
            country.commonName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            country.isO2Code?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setCountryList(filteredList);
        break;
      default:
        break;
    }
    return;
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'Aircraft Logistics:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'vendorLocation',
            type: EDITOR_TYPES.DROPDOWN,
            isHidden: true,
          },
          {
            fieldKey: 'appliedDisinsectionCountries',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: healthRequirementStore.getHealthRequirementSettingOptions<CountryModel>(
              countryList,
              'countryReference'
            ),
            multiple: true,
            isFullFlex: true,
            disableCloseOnSelect: true,
            searchEntityType: SEARCH_ENTITY_TYPE.COUNTRY,
          },
          {
            fieldKey: 'appliedDisinsectionTypes',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: healthRequirementStore.getHealthRequirementSettingOptions<SettingBaseModel>(
              settingsStore.reffDataDisinsectionRequiredTypeList,
              'subDisinsectionType'
            ),
            multiple: true,
            isFullFlex: true,
            disableCloseOnSelect: true,
          },
          {
            fieldKey: 'appliedDisinsectionChemicals',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: healthRequirementStore.getHealthRequirementSettingOptions<SettingBaseModel>(
              settingsStore.reffDataDisinsectionChemicalsList,
              'subDisinsectionChemical'
            ),
            multiple: true,
            isFullFlex: true,
            disableCloseOnSelect: true,
          },
        ],
      },
    ];
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'appliedDisinsectionCountries':
        setCountryList(vendorManagementStore.countries);
        break;
      default:
        break;
    }
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const isInValid = (): boolean => {
    if (isDisinsection) {
      return !formRef.isValid || formRef.hasError || !healthRequirementStore.formValueChanged;
    } else {
      return !healthRequirementStore.formValueChanged;
    }
  };

  const getConfirmation = (): void => {
    ModalStore.open(
      <CustomDialog
        title="Confirm changes"
        message={'Canceling will lost your changes. Are you sure you want to cancel?'}
        yesButton="Confirm"
        noButton="Cancel"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          navigate('/vendor/locations');
        }}
      />
    );
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">Aircraft Spray Requirements</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => getConfirmation()}
              disabled={!healthRequirementStore.formValueChanged}
              size="large"
            >
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={isInValid()}
              onClick={() => upsertHealthRequirements()}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const upsertHealthRequirements = (): void => {
    const request = new HealthRequirementModel({ ...useUpsert.form.values(), isDisinsection: isDisinsection });
    UIStore.setPageLoader(true);
    healthRequirementStore
      ?.upsertHealthRequirement(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: HealthRequirementModel) => {
          useUpsert.form.reset();
          setFormValues(HealthRequirementModel.deserialize(response));
        },
        error: error => {
          if (error?.response?.data?.errors) {
            errorHandler(error?.response?.data?.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error?.message, request.id.toString());
        },
      });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    healthRequirementStore.formValueChanged = true;
    setIsDisinsection(Boolean(event.target.checked));
  };

  return (
    <>
      <DetailsEditorWrapper
        headerActions={headerActions()}
        isEditMode={true}
        classes={{ headerActions: classes.headerActions }}
      >
        <div className={classes.editorWrapperContainer}>
          <Typography className={classes.heading}>Disinsection required</Typography>
          <div className={classes.toggle}>
            <Typography>No</Typography>
            <Switch checked={isDisinsection} onChange={handleSwitchChange} color="primary" name="switch" />
            <Typography>Yes</Typography>
          </div>
          {isDisinsection && (
            <ViewInputControls
              isEditable={true}
              groupInputControls={groupInputControls()}
              onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
              onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
              field={fieldKey => useUpsert.getField(fieldKey)}
              onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
              onFocus={fieldKey => onFocus(fieldKey)}
            />
          )}
        </div>
      </DetailsEditorWrapper>
    </>
  );
};

export default inject(
  'settingsStore',
  'healthRequirementStore',
  'vendorManagementStore'
)(withStyles(styles)(observer(HealthRequirements)));

import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  editorWrapperContainerPPR: {
    boxShadow: '0px 2px 3px rgba(0,0,0,.2)',
    borderRadius: '4px',
    '& .MuiButtonBase-root.Mui-disabled':{
      color: '#7C7C7C',
      borderBottom: '1px solid #D3D3D3'
    }
  },
}));

import { AuthStore } from '@uplink-shared/security';
import { UIStore } from '@wings-shared/core';
import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { VendorLocationStore, VendorManagementStore } from '../../Stores';
import { inject, observer } from 'mobx-react';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
import { VendorLocationModel, VendorManagmentModel } from '../Shared';
import { RootDataStore } from '@uplink-shared/layout';

type Props = {
  vendorManagementStore: VendorManagementStore;
  vendorLocationStore: VendorLocationStore;
};

const Redirection: FC<Props> = observer(({ vendorManagementStore, vendorLocationStore }) => {
  const navigate = useNavigate();
  const params = useParams();
  const unsubscribe = useUnsubscribe();

  const decodeBase64 = (encodedString: string) => {
    const decoded = atob(encodedString);
    return decoded.split('|');
  };

  const extractedIds = decodeBase64(params?.ids || '');

  useEffect(() => {
    loadInitialData();
  }, [ params?.ids ]);

  const loadInitialData = () => {
    const vendorId = extractedIds[0];
    UIStore.setPageLoader(true);
    vendorManagementStore
      ?.getVendorById(vendorId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorManagmentModel) => {
        AuthStore.setVendor(true, response?.id, response?.name, response?.code);
        loadLocationData();
      });
  };

  const loadLocationData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(extractedIds[1])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        RootDataStore.setLocation(
          true,
          response.id,
          response.name,
          response.code,
          response?.airportReference || null,
          '',
          response?.airportDataManagement,
          response?.countryDataManagement,
          response?.permitDataManagement,
          RootDataStore.locationData.hasMultipleVendor
        );
        navigate('/vendor/location/operational-essential');
      });
  };

  return <div>Please wait while we load your vendor's operational essentials.</div>;
});

export default inject('vendorManagementStore', 'vendorLocationStore')(Redirection);

import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { DataExpiryStore, VendorUserStore } from '../../Stores';
import { inject, observer } from 'mobx-react';
import { UIStore } from '@wings-shared/core';
import { AuthStore } from '@uplink-shared/security';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import DataExpiryStepper from './Shared/DataExpiryStepper/DataExpiryStepper';
import { useNavigate } from 'react-router';

interface Props {
  dataExpiryStore?: DataExpiryStore;
  vendorUserStore?: VendorUserStore;
}

const DataExpirationWorkflow: React.FC<Props> = ({ dataExpiryStore, vendorUserStore }) => {
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();

  useEffect(() => {
    AuthStore.setVendor(false, 0, '', '');
    RootDataStore.setLocation(false, 0, '', '', null, '', false, false, false, true);
    SidebarStore.setNavLinks([], '/vendor');
    if (AuthStore?.user?.sub) {
      loadInitialData();
    }
  }, [ AuthStore?.user?.sub, dataExpiryStore?.isExpiryDataUpdated ]);

  const loadExpirationDataByVendorId = vendorIds => {
    UIStore.setPageLoader(true);
    dataExpiryStore
      ?.getDataExpirationByVendorId(vendorIds)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          if (response?.results?.length === 0) {
            navigate('/vendor/general-info');
          }
        },
        error: error => {
          navigate('/vendor/general-info');
        },
      });
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    vendorUserStore
      .getVendorUserByVendorId(AuthStore.user.sub,AuthStore?.user?.preferred_username)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe(() => {
        const vendorIds = vendorUserStore?.vendorUser?.map(item => item.id);
        if (vendorUserStore?.vendorUser?.length === 1) {
          AuthStore.setVendor(
            true,
            vendorUserStore?.vendorUser[0]?.id,
            vendorUserStore?.vendorUser[0].name,
            vendorUserStore?.vendorUser[0]?.code
          );
          RootDataStore.setLocation(false, 0, '', '', null, '', false, false, false, false);
        } else {
          AuthStore.setVendor(false, 0, '', '');
          RootDataStore.setLocation(false, 0, '', '', null, '', false, false, false, true);
        }
        loadExpirationDataByVendorId(vendorIds);
      });
  };

  return (
    <Box>
      {dataExpiryStore?.dataExpiration && dataExpiryStore?.dataExpiration.length > 0 && (
        <DataExpiryStepper dataExpirationData={dataExpiryStore?.dataExpiration} />
      )}
    </Box>
  );
};

export default inject('dataExpiryStore', 'vendorUserStore')(observer(DataExpirationWorkflow));

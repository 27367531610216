import React, { FC, ReactNode } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStyles } from './CustomDialog.style';
import Close from '@material-ui/icons/Close';

type Props = {
  title: string;
  message: ReactNode;
  className?: string;
};

const SimpleMessageDialog: React.FC<Props> = ({
  title,
  message,
  className,
}) => {
  const classes = useStyles();

  const [ open, setOpen ] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={true} className={classes.globalMuiPaperRoot}>
      <div className={`${className}`}>
        <DialogTitle className={classes.title}>
          <strong>{title}</strong>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>{message}</DialogContent>
      </div>
    </Dialog>
  );
};

export default observer(SimpleMessageDialog);

import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { VendorLocationModel } from './VendorLocation.model';
import { SettingBaseModel } from './SettingBase.model';
import { PassengerLogisticsOptionModel } from './PassengerLogisticsOptionModel.model';
import { RootDataStore } from '@uplink-shared/layout';

@modelProtection
export class PassengerLogisticsModel extends CoreModel implements ISelectOption {
  id: number = 0;
  userId: string = '';
  agentFeesApply: SettingBaseModel = new SettingBaseModel();
  vendorLocationId: number = 0;
  vendorLocation: VendorLocationModel = new VendorLocationModel();
  aircraftHandlingLocationLatitude: number = 0;
  aircraftHandlingLocationLongitude: number = 0;
  aircraftParkingDistanceFBO: SettingBaseModel = new SettingBaseModel();
  aircraftParkingField: string = '';
  aircraftParkingOptionLatitude: number = 0;
  aircraftParkingOptionLongitude: number = 0;
  appliedAircraftParkingOptions: PassengerLogisticsOptionModel[] = [];
  appliedAircraftSpotAccommodation: PassengerLogisticsOptionModel[] = [];
  appliedAmenities: PassengerLogisticsOptionModel[] = [];
  appliedAvailableFacilities: PassengerLogisticsOptionModel[] = [];
  appliedCrewLocationType: PassengerLogisticsOptionModel[] = [];
  appliedDisabilityAccommodation: PassengerLogisticsOptionModel[] = [];
  appliedPaxLocationType: PassengerLogisticsOptionModel[] = [];
  appliedTowbarScenarios: PassengerLogisticsOptionModel[] = [];
  disabilityAccommodations: PassengerLogisticsOptionModel[] = [];
  appliedInternationalArrivalProcedures: PassengerLogisticsOptionModel[] = [];
  appliedInternationalDepartureProcedures: PassengerLogisticsOptionModel[] = [];
  arrivalCrewPaxPassportHandling: SettingBaseModel = new SettingBaseModel();
  arrivalMeetingPoint: string = '';
  crewLatitude: number = 0;
  crewLongitude: number = 0;
  customsClearanceFBO: boolean = false;
  customsClearanceTiming: number | null = null;
  domesticArrivalProcedures: string = '';
  domesticDepartureProcedures: string = '';
  earlyCrewArrival: number | null = null;
  earlyPaxArrival: number | null = null;
  hangarAvailable: SettingBaseModel = new SettingBaseModel();
  hangarAvailableSpace: number | null = null;
  hangerAvailableUom: SettingBaseModel = new SettingBaseModel();
  luggageHandling: SettingBaseModel = new SettingBaseModel();
  paxLatitude: number = 0;
  paxLongitude: number = 0;
  towbarRequired: SettingBaseModel = new SettingBaseModel();
  towbarRequirement: string = '';
  crewLatitudeLongitude: string = '';
  paxLatitudeLongitude: string = '';
  aircraftParkingOptionLatitudeLongitude: string = '';
  aircraftHandlingLocationLatitudeLongitude: string = '';
  otherValue: string = '';
  isOvertimeAvailable: boolean = false;
  leadTimeForOvertime: number = 0;

  constructor(data?: Partial<PassengerLogisticsModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: PassengerLogisticsModel): PassengerLogisticsModel {
    if (!apiData) {
      return new PassengerLogisticsModel();
    }
    const yesNoSometimesList = this.getYesNoSometimes();
    const towbarSetting = yesNoSometimesList.find(
      setting => setting.name.toLocaleLowerCase() === apiData.towbarRequired?.toString().toLocaleLowerCase()
    );
    const data: Partial<PassengerLogisticsModel> = {
      ...apiData,
      agentFeesApply: apiData.agentFeesApply,
      aircraftParkingDistanceFBO: SettingBaseModel.deserialize(apiData.aircraftParkingDistanceFBO),
      appliedAircraftParkingOptions: PassengerLogisticsOptionModel.deserializeList(
        apiData.appliedAircraftParkingOptions
      ),
      appliedAircraftSpotAccommodation: PassengerLogisticsOptionModel.deserializeList(
        apiData?.appliedAircraftSpotAccommodation
      ),
      appliedAmenities: PassengerLogisticsOptionModel.deserializeList(apiData?.appliedAmenities),
      appliedAvailableFacilities: PassengerLogisticsOptionModel.deserializeList(apiData?.appliedAvailableFacilities),
      appliedCrewLocationType: PassengerLogisticsOptionModel.deserializeList(apiData?.appliedCrewLocationType),
      appliedDisabilityAccommodation: PassengerLogisticsOptionModel.deserializeList(
        apiData?.appliedDisabilityAccommodation
      ),
      appliedPaxLocationType: PassengerLogisticsOptionModel.deserializeList(apiData?.appliedPaxLocationType),
      appliedTowbarScenarios: PassengerLogisticsOptionModel.deserializeList(apiData.appliedTowbarScenarios),
      disabilityAccommodations: PassengerLogisticsOptionModel.deserializeList(apiData.disabilityAccommodations),
      appliedInternationalArrivalProcedures: PassengerLogisticsOptionModel.deserializeList(
        apiData.appliedInternationalArrivalProcedures
      ),
      appliedInternationalDepartureProcedures: PassengerLogisticsOptionModel.deserializeList(
        apiData.appliedInternationalDepartureProcedures
      ),
      arrivalCrewPaxPassportHandling: SettingBaseModel.deserialize(apiData.arrivalCrewPaxPassportHandling),
      hangerAvailableUom: SettingBaseModel.deserialize(apiData.hangerAvailableUom),
      luggageHandling: SettingBaseModel.deserialize(apiData.luggageHandling),
      crewLatitudeLongitude: `${apiData.crewLatitude}, ${apiData.crewLongitude}`,
      paxLatitudeLongitude: `${apiData.paxLatitude}, ${apiData.paxLongitude}`,
      aircraftParkingOptionLatitudeLongitude:
        apiData.aircraftParkingOptionLatitude && apiData.aircraftParkingOptionLongitude
          ? `${apiData.aircraftParkingOptionLatitude}, ${apiData.aircraftParkingOptionLongitude}`
          : null,
      aircraftHandlingLocationLatitudeLongitude:
        apiData.aircraftHandlingLocationLatitude && apiData.aircraftHandlingLocationLongitude
          ? `${apiData.aircraftHandlingLocationLatitude}, ${apiData.aircraftHandlingLocationLongitude}`
          : null,
      towbarRequired: SettingBaseModel.deserialize(towbarSetting),
    };
    return new PassengerLogisticsModel(data);
  }

  static deserializeList(apiDataList: PassengerLogisticsModel[]): PassengerLogisticsModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => PassengerLogisticsModel.deserialize(apiData)) : [];
  }

  public static getYesNo() {
    return SettingBaseModel.deserializeList([
      {
        id: 1,
        name: 'Yes',
      },
      {
        id: 2,
        name: 'No',
      },
    ]);
  }

  public static getYesNoSometimes() {
    return SettingBaseModel.deserializeList([
      {
        id: 1,
        name: 'Yes',
      },
      {
        id: 2,
        name: 'No',
      },
      {
        id: 3,
        name: 'Sometimes',
      },
    ]);
  }

  public serialize() {
    return {
      id: this.id || 0,
      userId: this.userId,
      vendorLocationId: RootDataStore.locationData.locationId,
      agentFeesApply: this.agentFeesApply?.id === 1 ? true : this.agentFeesApply?.id === 2 ? false : null,
      aircraftHandlingLocationLatitude: this.aircraftHandlingLocationLatitude,
      aircraftHandlingLocationLongitude: this.aircraftHandlingLocationLongitude,
      aircraftParkingField: this.aircraftParkingField,
      aircraftParkingOptionLatitude: this.aircraftParkingOptionLatitude,
      aircraftParkingOptionLongitude: this.aircraftParkingOptionLongitude,
      arrivalMeetingPoint: this.arrivalMeetingPoint,
      crewLatitude: this.crewLatitude,
      crewLongitude: this.crewLongitude,
      customsClearanceFBO: this.customsClearanceFBO,
      customsClearanceTiming: this.customsClearanceTiming?.toString()?.replace(/^\s*\s*$/, '') || null,
      domesticArrivalProcedures: this.domesticArrivalProcedures?.toString()?.replace(/\s+/gi, ' ') || null,
      domesticDepartureProcedures: this.domesticDepartureProcedures?.toString()?.replace(/\s+/gi, ' ') || null,
      earlyCrewArrival: this.earlyCrewArrival?.toString()?.replace(/^\s*\s*$/, '') || null,
      earlyPaxArrival: this.earlyPaxArrival?.toString()?.replace(/^\s*\s*$/, '') || null,
      hangarAvailable: this.hangarAvailable?.id === 1 ? true : this.hangarAvailable?.id === 2 ? false : null,
      hangarAvailableSpace: this.hangarAvailableSpace?.toString()?.replace(/^\s*\s*$/, '') || null,
      hangerAvailableUom: this.hangerAvailableUom,
      paxLatitude: this.paxLatitude,
      paxLongitude: this.paxLongitude,
      towbarRequired: this.towbarRequired?.name || null,
      towbarRequirement: this.towbarRequirement?.toString()?.replace(/\s+/gi, ' ') || null,
      aircraftParkingDistanceFBOId: this.aircraftParkingDistanceFBO?.id || null,
      hangerAvailableUomId: this.hangerAvailableUom?.id || null,
      arrivalCrewPaxPassportHandlingId: this.arrivalCrewPaxPassportHandling?.id || null,
      luggageHandlingId: this.luggageHandling?.id || null,
      isOvertimeAvailable: this.isOvertimeAvailable,
      leadTimeForOvertime: this.leadTimeForOvertime,
      appliedCrewLocationType: this.appliedCrewLocationType
        ? this.appliedCrewLocationType.map(data => ({
          userId: data.userId || '',
          id: data?.id || 0,
          pickUpLocationId: data?.crewLocationType?.id || 0,
        }))
        : [],

      appliedPaxLocationType: this.appliedPaxLocationType
        ? this.appliedPaxLocationType.map(data => ({
          userId: data.userId || '',
          id: data?.id || 0,
          pickUpLocationId: data?.paxLocationType?.id || 0,
        }))
        : [],

      appliedAmenities: this.appliedAmenities
        ? this.appliedAmenities.map(data => ({
          userId: data.userId || '',
          id: data?.id || 0,
          amenitiesId: data?.amenities?.id || 0,
        }))
        : [],

      appliedAircraftParkingOptions: this.appliedAircraftParkingOptions
        ? this.appliedAircraftParkingOptions.map(data => ({
          userId: data.userId || '',
          id: data?.id || 0,
          aircraftParkingOptionsId: data.aircraftParkingOptions?.id || 0,
        }))
        : [],

      appliedAircraftSpotAccommodation: this.appliedAircraftSpotAccommodation
        ? this.appliedAircraftSpotAccommodation.map(data => ({
          userId: data.userId || '',
          id: data?.id || 0,
          aircraftSpotAccommodationId: data?.aircraftSpotAccommodation?.id || 0,
        }))
        : [],

      appliedTowbarScenarios: this.appliedTowbarScenarios
        ? this.appliedTowbarScenarios.map(data => ({
          userId: data.userId || '',
          id: data?.id || 0,
          towbarScenariosId: data?.towbarScenarios?.id || 0,
        }))
        : [],

      appliedAvailableFacilities: this.appliedAvailableFacilities
        ? this.appliedAvailableFacilities.map(data => ({
          userId: data.userId || '',
          id: data?.id || 0,
          availableFacilitiesId: data?.availableFacilities?.id || 0,
        }))
        : [],

      appliedDisabilityAccommodation: this.appliedDisabilityAccommodation
        ? this.appliedDisabilityAccommodation.map(data => ({
          userId: data.userId || '',
          id: data?.id || 0,
          disabilityAccommodationsId: data?.disabilityAccommodations?.id || 0,
        }))
        : [],

      appliedInternationalArrivalProcedures: this.appliedInternationalArrivalProcedures
        ? this.appliedInternationalArrivalProcedures.map(data => ({
          userId: data.userId || '',
          id: data?.id || 0,
          arrivalProceduresId: data?.arrivalProcedures?.id || 0,
        }))
        : [],
      appliedInternationalDepartureProcedures: this.appliedInternationalDepartureProcedures
        ? this.appliedInternationalDepartureProcedures.map(data => ({
          userId: data.userId || '',
          id: data?.id || 0,
          internationalDepartureProceduresId: data?.internationalDepartureProcedures?.id || 0,
          otherValue:
              data?.internationalDepartureProcedures?.id === 5 ||
              data?.internationalDepartureProcedures?.id === 1 ||
              data?.internationalDepartureProcedures?.id === 2
                ? this.otherValue?.toString()?.replace(/\s+/gi, ' ') || data?.otherValue || ''
                : null,
        }))
        : [],
    };
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}

import React, { FC } from 'react';
import { IClasses } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { Box, Tooltip, Typography, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { LocationHoursModel, OperatingHoursAddData } from '../../../../../../Shared';
import { styles } from '../MainTerminalOnly.style';

interface Props {
  classes?: IClasses;
  locationHoursList: LocationHoursModel[];
  handleSave: () => void;
}

const MainOperationalHours: FC<Props> = ({ classes, locationHoursList, handleSave }) => {
  return (
    <div className="mainOperationalHoursWrapper">
      <Box>
        <Typography component={'h3'} className={classes.secondaryHeading}>
          Operating Hours
          <Tooltip
            title="Add the operating hours of each day by clicking on Add Operating Hours"
            placement="top-start"
            classes={{ tooltip: classes.customTooltip }}
          >
            <InfoIcon className={classes?.imageIcon} />
          </Tooltip>
        </Typography>
        <OperatingHoursAddData locationHoursList={locationHoursList} handleSave={handleSave} />
      </Box>
    </div>
  );
};

export default withStyles(styles)(observer(MainOperationalHours));

import { observable, action } from 'mobx';
import { IAPIGridRequest, IAPIPageResponse, Utilities } from '@wings-shared/core';
import { Observable } from 'rxjs';
import { baseApiPath, HttpClient } from '@uplink/shared';
import { vendorManagementHeaders } from './Base.store';
import { apiUrls } from './API.url';
import { Logger } from '@uplink-shared/security';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { ParkingSeasonalityModel } from '../Modules/Shared/Models/ParkingSeasonality.model';
import { ParkingSeasonalityOptionModel } from '../Modules/Shared';

export class ParkingSeasonalityStore {
  @observable public radioValue: number = 2;
  @observable public selectedParkingSeasonality: ParkingSeasonalityModel = new ParkingSeasonalityModel();

  @action
  public getParkingSeasonality(pageRequest?: IAPIGridRequest): Observable<IAPIPageResponse<ParkingSeasonalityModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'ParkingSeasonality',
      ...pageRequest,
    });

    return http.get<IAPIPageResponse<ParkingSeasonalityModel>>(`/${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,

      map(response => {
        return {
          ...response,
          results: ParkingSeasonalityModel.deserializeList(response.results),
        };
      })
    );
  }

  @action
  public getParkingSeasonalitySettingOptions<T>(models: T[], settingName: string) {
    const settingOptions: ParkingSeasonalityOptionModel[] = [];
    models.forEach(model => {
      const settingOption: ParkingSeasonalityOptionModel = new ParkingSeasonalityOptionModel();
      settingOption.id = 0;
      settingOption[settingName] = model;
      settingOptions.push(settingOption);
    });
    return settingOptions;
  }

  @action
  public upsertParkingSeasonality(payload: ParkingSeasonalityModel): Observable<ParkingSeasonalityModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !Boolean(payload.id);
    const upsertRequest: Observable<ParkingSeasonalityModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.parkingSeasonality}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.parkingSeasonality}/${payload.id}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => {
        AlertStore.info('Data saved successfully!');
      }),
      map(response => ParkingSeasonalityModel.deserialize(response))
    );
  }
}

import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { SettingBaseModel } from './SettingBase.model';
import { VendorManagmentModel } from './VendorManagment.model';
import { StatusBaseModel } from './StatusBase.model';
import { VendorLocationModel } from './VendorLocation.model';
import { IAPIResponseVendorDocument } from '../Interfaces/Response/API-Response-VendorDocument';
import { IAPIRequestVendorDocument } from '../Interfaces/Request/API-Request-VendorDocument';
@modelProtection
export class VendorLocationPhoto extends CoreModel {
  id: number = 0;
  photoUri: string = '';
  status: StatusBaseModel = new StatusBaseModel();
  statusId: number;
  fileName: string = '';  
  vendorLocationId?: number;
  vendorLocation?: VendorLocationModel = new VendorLocationModel();

  constructor(data?: Partial<VendorLocationPhoto>) {
    super(data);
    Object.assign(this, data);
  }


  public get value(): string | number {
    return this.id;
  }

  public serialize(
    photoUri: string,
    vendorLocationId: number,
  ): VendorLocationPhoto {
    return {
      userId: '',
      id: this.id || 0,
      photoUri: photoUri ? photoUri : this.photoUri,
      fileName: this.fileName||'',
      statusId:  this.status?.id || 1,
      vendorLocationId: vendorLocationId ? vendorLocationId: this.vendorLocationId,
    };
  }


  static deserialize(apiData: VendorLocationPhoto): VendorLocationPhoto {
    if (!apiData) {
      return new VendorLocationPhoto();
    }
    const data: Partial<VendorLocationPhoto> = {
      ...apiData,
      vendorLocation: VendorLocationModel.deserialize(apiData?.vendorLocation),
      status: StatusBaseModel.deserialize(apiData.status),
      photoUri: apiData.photoUri,
    };
    return new VendorLocationPhoto(data);
  }

  static deserializeList(apiDataList: VendorLocationPhoto[]): VendorLocationPhoto[] {
    return apiDataList ? apiDataList.map(apiData => VendorLocationPhoto.deserialize(apiData)) : [];
  }
}

import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  OutlinedInput,
  Switch,
  TextField,
  withStyles,
} from '@material-ui/core';
import { styles } from './AirportHoursData.styles';
import { IClasses } from '@wings-shared/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { LocationHoursModel } from '../../Models/LocationHours.model';
import { VendorLocationStore } from '../../../../Stores/VendorLocation.store';
import { inject } from 'mobx-react';
import { RootDataStore } from '@uplink-shared/layout';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { SettingsStore, VendorLocationHoursStore } from '../../../../Stores';
import { Autocomplete } from '@material-ui/lab';
import { SettingBaseModel } from '../../Models';
import { Close } from '@material-ui/icons';
import { SETTING_ID } from '../../Enums';

interface AirportHoursCondition {
  airportHoursConditionTypeId: number;
  airportHoursConditionalOperatorId: number;
}

interface TimeRange {
  id: string;
  hoursId: number;
  scheduleId: number;
  patternedRecurrenceId: number;
  startTime: string;
  endTime: string;
  is24Hours: boolean;
  isNew?: boolean;
  sequence?: number;
  hoursTypeId?: number;
  hoursSubTypeId?: number;
  statusId?: number;
  accessLevelId?: number;
  startDate?: Date;
  endDate?: Date;
  includeHoliday?: boolean;
  dayOfWeekId?: number;
  patternedRecurrenceDaysofWeekId?: number;
  active?: boolean;
  operatingHoursTypeId?: number;
  overtimeFee?: number;
  currency?: string;
  hoursCondition?: AirportHoursCondition[];
}

interface HoursDataProps {
  classes: IClasses;
  timeData: { [key: string]: TimeRange[] };
  locationHoursList: LocationHoursModel[];
  vendorLocationStore: VendorLocationStore;
  settingsStore: SettingsStore;
  vendorLocationHoursStore: VendorLocationHoursStore;
}

const dayNames = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];

const AirportHoursData: FC<HoursDataProps> = ({
  classes,
  timeData,
  locationHoursList,
  vendorLocationStore,
  vendorLocationHoursStore,
  settingsStore,
}) => {
  const [ duplicationMappings, setDuplicationMappings ] = useState<{ [sourceDay: string]: string[] }>({});

  const dayOfWeekIds: { [key: string]: number } = {
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
    Saturday: 7,
  };

  const addDefaultTimeRanges = hoursData => {
    const daysOfWeek = Object.keys(dayOfWeekIds);

    daysOfWeek.forEach(day => {
      if (!hoursData[day] || hoursData[day].length === 0) {
        const defaultObject = {
          id: `default-${day}-00:00-00:00`,
          hoursId: 0,
          scheduleId: 0,
          patternedRecurrenceId: 0,
          startTime: '00:00',
          endTime: '00:00',
          is24Hours: false,
          isNew: true,
          sequence: Math.floor(Math.random() * 999 + 1),
          hoursTypeId: 1,
          hoursSubTypeId: 1,
          statusId: 1,
          accessLevelId: 1,
          startDate: null,
          endDate: null,
          includeHoliday: false,
          dayOfWeekId: dayOfWeekIds[day],
          patternedRecurrenceDaysofWeekId: 0,
          active: true,
          operatingHoursTypeId: 2,
          hoursCondition: []
        };

        hoursData[day] = [ defaultObject ];
      } else {
        hoursData[day].forEach(timeRange => {
          if (timeRange.is24Hours && timeRange.startTime === '00:01' && timeRange.endTime === '23:59') {
            timeRange.operatingHoursTypeId = 1;
          }
        });
      }
    });

    return hoursData;
  };

  const [ editableTimeData, setEditableTimeData ] = useState(addDefaultTimeRanges(timeData));

  useEffect(() => {
    settingsStore?.getSettings(SETTING_ID.SETTINGS_CURRENCY).subscribe();
    if (locationHoursList.length > 0) return;

    const updatedTimeData: { [key: string]: TimeRange[] } = dayNames.reduce((acc, day) => {
      acc[day] = [];
      return acc;
    }, {} as { [key: string]: TimeRange[] });

    if (locationHoursList.length === 0) {
      dayNames.forEach(day => {
        updatedTimeData[day].push({
          id: `default-${day}-00:00-00:00`,
          hoursId: 0,
          scheduleId: 0,
          patternedRecurrenceId: 0,
          startTime: '00:00',
          endTime: '00:00',
          is24Hours: false,
          isNew: true,
          sequence: Math.floor(Math.random() * 999 + 1),
          hoursTypeId: 1,
          hoursSubTypeId: 1,
          statusId: 1,
          accessLevelId: 1,
          startDate: null,
          endDate: null,
          includeHoliday: false,
          dayOfWeekId: dayOfWeekIds[day],
          patternedRecurrenceDaysofWeekId: 0,
          active: true,
          operatingHoursTypeId: 2,
          hoursCondition: []
        });
      });
    }
    setEditableTimeData(updatedTimeData);
  }, []);

  const isValidTime = (time: string) => moment(time, 'HH:mm', true).isValid();

  const handleTimeChange = (
    day: string,
    index: number,
    field: 'startTime' | 'endTime' | 'overtimeFee',
    value: string
  ) => {
    vendorLocationStore.isTimeChanged = true;
    const sequenceList = Object.keys(editableTimeData).flatMap(dayKey =>
      editableTimeData[dayKey].map(timeRange => timeRange.sequence)
    );
    let uniqueSequence;
    do {
      uniqueSequence = Math.floor(Math.random() * 999 + 1);
    } while (sequenceList.includes(uniqueSequence));

    setEditableTimeData(prevData => {
      const updatedDayData = [ ...prevData[day] ];
      const currentRange = updatedDayData[index];
      const updatedRange = { ...currentRange, [field]: value };

      if (!isValidTime(updatedRange.startTime) || !isValidTime(updatedRange.endTime)) {
        return prevData;
      }

      if (updatedRange.startTime === '00:01' && updatedRange.endTime === '23:59') {
        updatedRange.is24Hours = true;
        const filteredData = updatedDayData.filter((_, i) => i === index);

        filteredData.unshift(filteredData.pop());
        if (locationHoursList.length) {
          const matchedItem = locationHoursList.find(item => {
            const { schedule } = item;
            return (
              moment(schedule.startTime)
                .utc()
                .format('HH:mm') === '00:01' &&
              moment(schedule.endTime)
                .utc()
                .format('HH:mm') === '23:59'
            );
          });

          if (matchedItem) {
            const { schedule, id: hoursId } = matchedItem;
            filteredData[0] = {
              ...filteredData[0],
              hoursId,
              scheduleId: schedule.id,
              patternedRecurrenceId: schedule.patternedRecurrence?.id || 0,
              hoursTypeId: matchedItem.hoursType?.id,
              sequence: matchedItem.sequence || Math.floor(Math.random() * 999 + 1),
              statusId: matchedItem.status?.id,
              accessLevelId: matchedItem.accessLevel?.id,
              startDate: schedule.startDate,
              endDate: schedule.endDate,
              startTime: '00:01',
              endTime: '23:59',
              is24Hours: true,
              includeHoliday: schedule.includeHoliday,
              dayOfWeekId: dayOfWeekIds[day],
              patternedRecurrenceDaysofWeekId: 0,
              active: true,
              operatingHoursTypeId: filteredData[0].operatingHoursTypeId,
              overtimeFee: filteredData[0].overtimeFee,
              currency: filteredData[0].currency,
            };
          } else {
            filteredData[0] = {
              ...filteredData[0],
              hoursId: 0,
              scheduleId: 0,
              patternedRecurrenceId: 0,
              hoursTypeId: 1,
              statusId: 1,
              accessLevelId: 1,
              startDate: null,
              endDate: null,
              startTime: '00:01',
              endTime: '23:59',
              is24Hours: true,
              includeHoliday: false,
              dayOfWeekId: dayOfWeekIds[day],
              sequence: uniqueSequence,
              patternedRecurrenceDaysofWeekId: 0,
              active: true,
              operatingHoursTypeId: filteredData[0].operatingHoursTypeId,
              overtimeFee: filteredData[0].overtimeFee,
              currency: filteredData[0].currency,
            };
          }
        }

        return {
          ...prevData,
          [day]: filteredData,
        };
      } else {
        updatedRange.is24Hours = false;
        updatedDayData[index] = updatedRange;

        return {
          ...prevData,
          [day]: updatedDayData,
        };
      }
    });
  };

  const handleCheckboxChange = (day: string, index: number, checked: Boolean) => {
    vendorLocationStore.isTimeChanged = true;
    const sequenceList = Object.keys(editableTimeData).flatMap(dayKey =>
      editableTimeData[dayKey].map(timeRange => timeRange.sequence)
    );
    let uniqueSequence;
    do {
      uniqueSequence = Math.floor(Math.random() * 999 + 1);
    } while (sequenceList.includes(uniqueSequence));
    if (checked) {
      setEditableTimeData(prevData => {
        const updatedDayData = [ ...prevData[day] ];
        updatedDayData[index] = {
          ...updatedDayData[index],
          is24Hours: true,
          operatingHoursTypeId: 1,
          startTime: '00:01',
          endTime: '23:59',
        };

        return { ...prevData, [day]: updatedDayData };
      });
    } else {
      setEditableTimeData(prevData => {
        const updatedDayData = [ ...prevData[day] ];
        updatedDayData[index] = {
          ...updatedDayData[index],
          is24Hours: false,
        };
        return {
          ...prevData,
          [day]: updatedDayData,
        };
      });
    }
  };

  const handleAddTimeRange = (day: string) => {
    vendorLocationStore.isTimeChanged = true;
    const sequenceList = Object.keys(editableTimeData).flatMap(dayKey =>
      editableTimeData[dayKey].map(timeRange => timeRange.sequence)
    );
    let uniqueSequence;
    do {
      uniqueSequence = Math.floor(Math.random() * 999 + 1);
    } while (sequenceList.includes(uniqueSequence));

    setEditableTimeData(prevData => {
      const newTimeRange: TimeRange = {
        id: `new-${day}-${Date.now()}`,
        hoursId: 0,
        scheduleId: 0,
        patternedRecurrenceId: 0,
        startTime: '00:00',
        endTime: '00:00',
        is24Hours: false,
        isNew: true,
        sequence: uniqueSequence,
        hoursTypeId: 1,
        statusId: 1,
        accessLevelId: 1,
        startDate: null,
        endDate: null,
        includeHoliday: false,
        dayOfWeekId: dayOfWeekIds[day],
        patternedRecurrenceDaysofWeekId: 0,
        active: true,
        operatingHoursTypeId: 2,
        overtimeFee: undefined,
        currency: '',
      };
      return { ...prevData, [day]: [ ...prevData[day], newTimeRange ] };
    });
  };

  const handleDeleteTimeRange = (day: string, index: number) => {
    vendorLocationStore.isTimeChanged = true;
    setEditableTimeData(prevData => {
      const updatedDayData = [ ...prevData[day] ];
      updatedDayData.splice(index, 1);
      return { ...prevData, [day]: updatedDayData };
    });
  };

  function convertToISOFormat(time: string) {
    if (moment(time, 'HH:mm', true).isValid()) {
      const [ hours, minutes ] = time.split(':');
      const date = new Date();
      date.setUTCHours(hours, minutes, 0, 0);
      return date.toISOString();
    }
  }

  const groupDaysByTimeRange = (editableTimeData: { [key: string]: TimeRange[] }) => {
    const groupedTimeData: { [key: string]: any } = {};
    for (const day in editableTimeData) {
      if (editableTimeData.hasOwnProperty(day)) {
        editableTimeData[day].forEach(
          ({
            hoursId,
            sequence,
            hoursTypeId,
            statusId,
            accessLevelId,
            startTime,
            endTime,
            patternedRecurrenceDaysofWeekId,
            startDate,
            endDate,
            is24Hours,
            includeHoliday,
            patternedRecurrenceId,
            scheduleId,
            operatingHoursTypeId,
          }) => {
            const timeKey = `${startTime}-${endTime}`;

            let matchingEntry = null;
            for (const entry of locationHoursList) {
              const entryStartTime = moment(entry.schedule.startTime)
                .utc()
                .format('HH:mm');
              const entryEndTime = moment(entry.schedule.endTime)
                .utc()
                .format('HH:mm');

              if (
                entryStartTime === startTime &&
                entryEndTime === endTime &&
                startTime !== '00:00' &&
                endTime !== '00:00'
              ) {
                matchingEntry = entry;
                break;
              }
            }

            if (groupedTimeData[timeKey]) {
              groupedTimeData[timeKey].id = matchingEntry ? hoursId : 0;
              groupedTimeData[timeKey].scheduleRequest.id = matchingEntry ? scheduleId : 0;
              groupedTimeData[timeKey].scheduleRequest.patternedRecurrenceRequest.id = matchingEntry
                ? patternedRecurrenceId
                : 0;
              groupedTimeData[
                timeKey
              ].scheduleRequest.patternedRecurrenceRequest.patternedRecurrenceDaysofWeekRequest.push({
                id: 0,
                dayOfWeekId: dayOfWeekIds[day],
              });
            } else {
              groupedTimeData[timeKey] = {
                id: 0,
                userId: '',
                vendorLocationId: RootDataStore.locationData.locationId,
                sequence,
                hoursTypeId,
                statusId,
                accessLevelId,
                operatingHoursTypeId,
                hoursScheduleTypeId: 1,
                scheduleRequest: {
                  id: 0,
                  startTime: convertToISOFormat(startTime),
                  endTime: convertToISOFormat(endTime),
                  startDate,
                  endDate,
                  is24Hours: Boolean(
                    moment(startTime)
                      .utc()
                      .format('HH:mm') === '00:01' &&
                      moment(endTime)
                        .utc()
                        .format('HH:mm') === '23:59'
                  ),
                  includeHoliday,
                  patternedRecurrenceRequest: {
                    id: 0,
                    patternedRecurrenceDaysofWeekRequest: [
                      {
                        id: 0,
                        dayOfWeekId: dayOfWeekIds[day],
                      },
                    ],
                  },
                },
              };
            }
          }
        );
      }
    }

    const newState = Object.values(groupedTimeData);
    return newState;
  };
    
  useEffect(() => {
    const filteredTimeData = groupDaysByTimeRange(editableTimeData).filter(item => {
      const startTime = moment(item.scheduleRequest.startTime)
        .utc()
        .format('HH:mm');
      const endTime = moment(item.scheduleRequest.endTime)
        .utc()
        .format('HH:mm');
      return !(startTime === '00:00' && endTime === '00:00');
    });
    vendorLocationHoursStore.updatedHoursData = LocationHoursModel.deserializeList(filteredTimeData);
  }, [ editableTimeData ]);

  const handleSwitchChange = (day: string, checked: boolean) => {
    vendorLocationStore.isTimeChanged = true;
    const sequenceList = Object.keys(editableTimeData).flatMap(dayKey =>
      editableTimeData[dayKey].map(timeRange => timeRange.sequence)
    );
    let uniqueSequence;
    do {
      uniqueSequence = Math.floor(Math.random() * 999 + 1);
    } while (sequenceList.includes(uniqueSequence));
    setEditableTimeData(prevSchedule => {
      const dayData = prevSchedule[day];

      const updatedObject = {
        ...dayData[0],
        active: checked,
        startTime: '00:00',
        endTime: '00:00',
        operatingHoursTypeId: dayData[0].operatingHoursTypeId,
      };

      const updateDayData = {
        ...prevSchedule,
        [day]: [ updatedObject ],
      };

      return updateDayData;
    });
  };

  const handleDuplicate = (sourceDay: string, targetDay: string, isChecked: boolean) => {
    setDuplicationMappings(prevMappings => {
      const updatedMappings = { ...prevMappings };

      if (isChecked) {
        Object.keys(updatedMappings).forEach(day => {
          if (day !== sourceDay && updatedMappings[day]?.includes(targetDay)) {
            updatedMappings[day] = updatedMappings[day].filter(d => d !== targetDay);
          }
        });
        if (!updatedMappings[sourceDay]) {
          updatedMappings[sourceDay] = [];
        }
        updatedMappings[sourceDay].push(targetDay);
      } else {
        if (updatedMappings[sourceDay]) {
          updatedMappings[sourceDay] = updatedMappings[sourceDay].filter(day => day !== targetDay);
        }
      }

      return updatedMappings;
    });

    if (isChecked) {
      setEditableTimeData(prevData => {
        const sourceData = prevData[sourceDay];
        const updatedData = { ...prevData };

        updatedData[targetDay] = sourceData.map(timeRange => ({
          ...timeRange,
          id: `duplicate-${targetDay}-${timeRange.id}`,
          dayOfWeekId: dayOfWeekIds[targetDay],
        }));

        return updatedData;
      });
    } else {
      setEditableTimeData(prevData => {
        const updatedData = { ...prevData };
        updatedData[targetDay] = [
          {
            id: `default-${targetDay}-00:00-00:00`,
            hoursId: 0,
            scheduleId: 0,
            patternedRecurrenceId: 0,
            startTime: '00:00',
            endTime: '00:00',
            is24Hours: false,
            isNew: true,
            sequence: Math.floor(Math.random() * 999 + 1),
            hoursTypeId: 1,
            statusId: 1,
            accessLevelId: 1,
            startDate: null,
            endDate: null,
            includeHoliday: false,
            dayOfWeekId: dayOfWeekIds[targetDay],
            patternedRecurrenceDaysofWeekId: 0,
            active: true,
            operatingHoursTypeId: 2,
          },
        ];

        return updatedData;
      });
    }
  };

  const operatingHoursType = SettingBaseModel.deserializeList([
    { id: 1, name: 'Open 24 hours' },
    { id: 2, name: 'Open' },
    { id: 3, name: 'Closed' },
    { id: 4, name: 'Overtime on request' },
    { id: 5, name: 'Closed to GA Traffic Private' },
    { id: 6, name: 'Closed to GA Traffic Charter' },
    { id: 7, name: 'Closed to GA Traffic Both' },
    { id: 8, name: 'Closed for Noise restrictions - Ch 2' },
    { id: 9, name: 'Closed for Noise restrictions - Ch 2 & 3' },
    { id: 10, name: 'Closed for Noise restrictions for Arrival - Ch 2' },
    { id: 11, name: 'Closed for Noise restrictions for Arrival - Ch 2 & 3' },
    { id: 12, name: 'Closed for Noise restrictions for Departure - Ch 2' },
    { id: 13, name: 'Closed for Noise restrictions for Departure - Ch 2 & 3' },
    { id: 14, name: 'Closed for Noise restrictions EPNDB' },
    { id: 15, name: 'Closed for Noise restrictions for arrival EPNDB' },
    { id: 16, name: 'Closed for Noise restrictions for departure EPNDB' },
    { id: 17, name: 'Closed for Noise restrictions Quota Count' },
    { id: 18, name: 'Peak traffic hours' },
    { id: 19, name: 'My hours are complicated, please contact me' },
  ]);

  function findMatchingObject(data, conditions) {
    return data.find(item => {
      const hoursTypeMatches = conditions.hoursTypeId === undefined || item.hoursType.id === conditions.hoursTypeId;

      const hoursSubTypeMatches =
        conditions.hoursSubTypeId === undefined || item.hoursSubType.id === conditions.hoursSubTypeId;

      const is24HoursMatches = conditions.is24Hours === undefined || item.is24Hours === conditions.is24Hours;

      const hoursConditionMatches =
        conditions.hoursCondition &&
        conditions.hoursCondition.every(condition => {
          return item.hoursCondition.some(hourCondition => {
            const conditionTypeMatches =
              condition.airportHoursConditionTypeId === undefined ||
              hourCondition.airportHoursConditionType.id === condition.airportHoursConditionTypeId;

            const conditionOperatorMatches =
              condition.airportHoursConditionalOperatorId === undefined ||
              hourCondition.airportHoursConditionalOperator.id === condition.airportHoursConditionalOperatorId;

            return conditionTypeMatches && conditionOperatorMatches;
          });
        });

      return hoursTypeMatches && hoursSubTypeMatches && is24HoursMatches && hoursConditionMatches;
    });
  }

  return (
    <Box>
      {Object.keys(editableTimeData)
        .sort((a, b) => dayOfWeekIds[a] - dayOfWeekIds[b])
        .map(day => {
          const timeRanges = editableTimeData[day];
          const isInActive = false;
          return (
            <Grid container spacing={3} xs={12} key={day} className={classes.columns}>
              <Grid
                item
                xs={2}
                style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <Box style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                  <label>{day}</label>
                </Box>
                <label style={{ marginBottom: '10px', fontSize: '12px', fontWeight: 'bold' }}>Duplicate to</label>
              </Grid>
              <Grid item xs={10} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {timeRanges.map((timeRange, index) => (
                  <Grid container spacing={3} xs={12} key={timeRange.id}>
                    <Grid item xs={6} className={classes.innerColumns}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardTimePicker
                          disabled={isInActive}
                          value={moment(timeRange.startTime, 'HH:mm')}
                          onChange={time => handleTimeChange(day, index, 'startTime', moment(time).format('HH:mm'))}
                          format="HH:mm"
                          ampm={false}
                          margin="dense"
                          variant="inline"
                          invalidDateMessage="Enter Valid Time"
                          keyboardIcon={<AccessTimeIcon />}
                        />
                        <Box style={{ padding: '10px', marginLeft: '10px' }}>
                          <label>to</label>
                        </Box>
                        <KeyboardTimePicker
                          disabled={isInActive}
                          value={moment(timeRange.endTime, 'HH:mm')}
                          onChange={time => handleTimeChange(day, index, 'endTime', moment(time).format('HH:mm'))}
                          format="HH:mm"
                          ampm={false}
                          margin="dense"
                          variant="inline"
                          style={{ marginLeft: 16 }}
                          invalidDateMessage="Enter Valid Time"
                          keyboardIcon={<AccessTimeIcon />}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        autoHighlight
                        getOptionSelected={(option, value) => option.id === value?.id}
                        value={operatingHoursType.find(type => type.id === timeRange.operatingHoursTypeId)}
                        options={operatingHoursType}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder="Select Operating Hour Type"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-disabled': {
                                  backgroundColor: '#D3D3D3',
                                  color: '#7C7C7C',
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#D3D3D3',
                                  },
                                },
                              },
                            }}
                          />
                        )}
                        getOptionLabel={option => option.label}
                        onChange={(event, newValue) => {
                          setEditableTimeData(prevData => {
                            const updatedDayData = [ ...prevData[day] ];
                            updatedDayData[index] = {
                              ...updatedDayData[index],
                              operatingHoursTypeId: newValue && newValue?.id,
                            };

                            return {
                              ...prevData,
                              [day]: updatedDayData,
                            };
                          });
                          handleCheckboxChange(day, index, newValue?.id === 1);
                        }}
                        className={classes.inputDropdown}
                        disabled={isInActive}
                      />
                      {index != 0 && (
                        <IconButton color="default" onClick={() => handleDeleteTimeRange(day, index)}>
                          <Close />
                        </IconButton>
                      )}
                    </Grid>
                    {timeRange.operatingHoursTypeId === 4 && (
                      <Grid
                        container
                        spacing={3}
                        xs={12}
                        alignItems="center"
                        style={{
                          marginLeft: '1px',
                        }}
                        className={classes.costFields}
                      >
                        <Grid item xs={2}>
                          <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Overtime Fee</label>
                        </Grid>
                        <Grid item xs={5}>
                          <OutlinedInput
                            className="inputTextField"
                            id="outlined-adornment-gat-cost"
                            value={timeRange.overtimeFee || ''}
                            onChange={e => handleTimeChange(day, index, 'overtimeFee', e.target.value)}
                            aria-describedby="outlined-terminal-cost-text"
                            inputProps={{
                              'aria-label': 'gat cost',
                            }}
                            labelWidth={0}
                            placeholder="Enter Cost"
                            disabled={isInActive}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <Autocomplete
                            autoHighlight
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={
                              settingsStore.vendorSettingsCurrency?.find(
                                currency => currency.id === timeRange.currency
                              ) || null
                            }
                            options={settingsStore.vendorSettingsCurrency}
                            renderInput={params => <TextField {...params} placeholder="Select Currency" />}
                            getOptionLabel={option => option.label}
                            onChange={(event, newValue) => {
                              handleTimeChange(day, index, 'currency', newValue ? newValue?.value : '');
                            }}
                            className={classes.currencyInputDropdown}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                ))}
                <div
                  className={classes.defaultButton}
                  onClick={() => handleAddTimeRange(day)}
                  style={{
                    pointerEvents: timeRanges.length === 7 || isInActive ? 'none' : 'auto',
                    opacity: timeRanges.length === 7 || isInActive ? 0.5 : 1,
                  }}
                >
                  <Button
                    variant="text"
                    color="primary"
                    disabled={timeRanges.length === 7 || isInActive}
                    startIcon={<AddBoxIcon />}
                    style={{ fontStyle: 'normal' }}
                    className={classes.timeRangeButton}
                  >
                    Add Time Range
                  </Button>
                </div>
                <FormGroup row>
                  {dayNames
                    .filter(d => d !== day)
                    .map(filteredDay => (
                      <FormControlLabel
                        key={filteredDay}
                        control={
                          <Checkbox
                            checked={duplicationMappings[day]?.includes(filteredDay) || false}
                            onChange={e => {
                              handleDuplicate(day, filteredDay, e.target.checked);
                            }}
                          />
                        }
                        label={filteredDay}
                      />
                    ))}
                </FormGroup>
              </Grid>
            </Grid>
          );
        })}
    </Box>
  );
};

export default inject(
  'vendorLocationStore',
  'vendorLocationHoursStore',
  'settingsStore'
)(withStyles(styles)(AirportHoursData));

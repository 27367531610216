import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  editorWrapperContainer: {
    overflow: 'auto',
    '& .MuiCheckbox-root + .MuiFormControlLabel-label':{
      fontSize: '20px !important',
      fontWeight: '400',
    }
  },
  container: {
    borderRadius: '16px',
    padding: '0',
    margin: 'auto',
  },
  headerText: {
    fontSize: '20px',
    fontWeight: 400,
    marginBottom: '32px',
  },
  answerText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    '& .MuiRadio-root + .MuiFormControlLabel-label':{
      fontSize: '20px',
      fontWeight: '400',
    }
  },
  radio: {
    color: '#007bff',
  },
}));

import React, { FC, useEffect, useState } from 'react';
import { IClasses, UIStore } from '@wings-shared/core';
import { observer, inject } from 'mobx-react';
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AirportHoursData from './AirportHoursData';
import { LocationHoursModel } from '../../Models/LocationHours.model';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { styles } from './AirportOperatingHoursAddData.style';
import { VendorLocationHoursStore, VendorLocationStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { EditIcon, EyeIcon } from '@uvgo-shared/icons';
import { Dialog } from '@uvgo-shared/dialog';
import { PrimaryButton } from '@uvgo-shared/buttons';
import moment from 'moment';
import { RootDataStore } from '@uplink-shared/layout';
import AirportInformationDialog from '../AirportInformationDialog/AirportInformationDialog';
import { Info } from '@material-ui/icons';

const dayNames = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];

interface AirportHoursCondition {
  airportHoursConditionTypeId: number;
  airportHoursConditionalOperatorId: number;
}

interface TimeRange {
  id: string;
  hoursId: number;
  scheduleId: number;
  patternedRecurrenceId: number;
  startTime: string;
  endTime: string;
  is24Hours: boolean;
  isNew?: boolean;
  sequence?: number;
  hoursTypeId?: number;
  hoursSubTypeId?: number;
  statusId?: number;
  accessLevelId?: number;
  startDate?: Date;
  endDate?: Date;
  includeHoliday?: boolean;
  dayOfWeekId?: number;
  patternedRecurrenceDaysofWeekId?: number;
  active?: boolean;
  airportHourId?: number;
  hoursCondition?: AirportHoursCondition[];
}

interface Props {
  classes?: IClasses;
  locationHoursList: LocationHoursModel[];
  vendorLocationStore: VendorLocationStore;
  vendorLocationHoursStore: VendorLocationHoursStore;
  handleSave: () => void;
}

const AirportOperatingHoursAddData: FC<Props> = ({
  classes,
  locationHoursList,
  vendorLocationHoursStore,
  handleSave,
}) => {
  const [ timeData, setTimeData ] = useState<{ [key: string]: TimeRange[] }>({});

  const unsubscribe = useUnsubscribe();
  const dayOfWeekIds: { [key: string]: number } = {
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
    Saturday: 7,
  };

  function convertToISOFormat(time: string) {
    if (moment(time, 'HH:mm', true).isValid()) {
      const [ hours, minutes ] = time.split(':');
      const date = new Date();
      date.setUTCHours(hours, minutes, 0, 0);
      return date.toISOString();
    }
  }

  const groupDaysByTimeRange = (editableTimeData: { [key: string]: TimeRange[] }) => {
    const groupedTimeData: { [key: string]: any } = {};

    for (const day in editableTimeData) {
      if (editableTimeData.hasOwnProperty(day)) {
        editableTimeData[day].forEach(
          ({
            hoursId,
            sequence,
            hoursTypeId,
            statusId,
            accessLevelId,
            startTime,
            endTime,
            patternedRecurrenceDaysofWeekId,
            startDate,
            endDate,
            is24Hours,
            includeHoliday,
            patternedRecurrenceId,
            scheduleId,
            airportHourId,
          }) => {
            const timeKey = `${startTime}-${endTime}`;

            if (groupedTimeData[timeKey]) {
              groupedTimeData[
                timeKey
              ].scheduleRequest.patternedRecurrenceRequest.patternedRecurrenceDaysofWeekRequest.push({
                id: patternedRecurrenceDaysofWeekId,
                dayOfWeekId: dayOfWeekIds[day],
              });
            } else {
              groupedTimeData[timeKey] = {
                id: hoursId,
                userId: '',
                vendorLocationId: RootDataStore.locationData.locationId,
                sequence,
                hoursTypeId,
                statusId,
                accessLevelId,
                airportHourId,
                hoursScheduleTypeId: 1,
                scheduleRequest: {
                  id: scheduleId,
                  startTime: convertToISOFormat(startTime),
                  endTime: convertToISOFormat(endTime),
                  startDate,
                  endDate,
                  is24Hours: Boolean(
                    moment(startTime)
                      .utc()
                      .format('HH:mm') === '00:01' &&
                      moment(endTime)
                        .utc()
                        .format('HH:mm') === '23:59'
                  ),
                  includeHoliday,
                  patternedRecurrenceRequest: {
                    id: patternedRecurrenceId,
                    patternedRecurrenceDaysofWeekRequest: [
                      {
                        id: 0,
                        dayOfWeekId: dayOfWeekIds[day],
                      },
                    ],
                  },
                },
              };
            }
          }
        );
      }
    }

    const newState = Object.values(groupedTimeData);
    return newState;
  };
  
  useEffect(() => {
    if (!locationHoursList) return;

    const updatedTimeData: { [key: string]: TimeRange[] } = dayNames.reduce((acc, day) => {
      acc[day] = [];
      return acc;
    }, {} as { [key: string]: TimeRange[] });

    locationHoursList.forEach(item => {
      const { schedule, id: hoursId } = item;
      const { patternedRecurrence } = schedule;
      patternedRecurrence.patternedRecurrenceDaysofWeek.forEach(day => {
        const dayName = day.dayOfWeek.name;
        if (updatedTimeData[dayName]) {
          const startTime = moment(schedule.startTime)
            .utc()
            .format('HH:mm');
          const endTime = moment(schedule.endTime)
            .utc()
            .format('HH:mm');
          updatedTimeData[dayName].push({
            id: `original-${dayName}-${startTime}-${endTime}`,
            startTime,
            endTime,
            is24Hours: Boolean(
              moment(schedule.startTime)
                .utc()
                .format('HH:mm') === '00:01' &&
                moment(schedule.endTime)
                  .utc()
                  .format('HH:mm') === '23:59'
            ),
            hoursId,
            scheduleId: schedule.id,
            hoursCondition: item.airportHoursCondition?.map(condition => ({
              airportHoursConditionTypeId: condition?.conditionType?.id,
              airportHoursConditionalOperatorId: condition?.conditionalOperator?.conditionalOperatorId,
            })),
            patternedRecurrenceId: patternedRecurrence.id,
            isNew: false,
            sequence: item.sequence,
            hoursTypeId: item.hoursType.id,
            hoursSubTypeId: item.airportHoursSubType?.id,
            statusId: item.status.id,
            accessLevelId: item.accessLevel.id,
            startDate: schedule.startDate,
            endDate: schedule.endDate,
            includeHoliday: schedule.includeHoliday,
            dayOfWeekId: dayOfWeekIds[dayName],
            patternedRecurrenceDaysofWeekId: day.id,
            active: true,
          });
        }
      });
    });

    setTimeData(updatedTimeData);
  }, [ locationHoursList ]);

  useEffect(() => {
    vendorLocationHoursStore.timeDataHoursData = groupDaysByTimeRange(timeData);
  }, [ timeData ]);

  const openPopupModel = () => {
    ModalStore.open(
      <AirportInformationDialog
        dialogWrapperWidth={classes.dialogMainContainer}
        title="Please advise daily operating hours in local time (24 hours)"
        message={<AirportHoursData timeData={timeData} locationHoursList={locationHoursList} />}
        isActionVisible={true}
        saveDataAction={() => handleSave()}
      />
    );
  };

  const viewTable = () => {
    return (
      <TableContainer component={Paper} className={classes.tableView}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell>Time Range</TableCell>
              <TableCell>Hour Type</TableCell>
              <TableCell>OT OR Fee (if applicable)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(timeData).map(day =>
              timeData[day].map((timeRange, index) => (
                <TableRow key={`${day}-${index}`}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      borderBottom: index === timeData[day].length - 1 ? '1px solid rgba(224, 224, 224, 1)' : 'none',
                    }}
                  >
                    {index === 0 && day}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      borderBottom: index === timeData[day].length - 1 ? '1px solid rgba(224, 224, 224, 1)' : 'none',
                    }}
                  >
                    {`${timeRange.startTime} - ${timeRange.endTime}`}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      borderBottom: index === timeData[day].length - 1 ? '1px solid rgba(224, 224, 224, 1)' : 'none',
                    }}
                  >
                    {timeRange.is24Hours ? 'Open 24 Hours' : ''}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      borderBottom: index === timeData[day].length - 1 ? '1px solid rgba(224, 224, 224, 1)' : 'none',
                    }}
                  ></TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  return (
    <Box className={classes.operatingHoursAddDataWraper}>
      <Box className={classes.defaultButton}>
        {locationHoursList.length === 0 ? (
          <PrimaryButton variant="outlined" color="primary" onClick={openPopupModel} startIcon={<AddBoxIcon />}>
            Add Operating Hours
          </PrimaryButton>
        ) : (
          <PrimaryButton variant="outlined" color="primary" onClick={openPopupModel} startIcon={<EditIcon />}>
            Edit Operating Hours
          </PrimaryButton>
        )}
      </Box>
      {locationHoursList.length > 0 && <Box className="hourChips">{viewTable()}</Box>}
    </Box>
  );
};

export default inject('vendorLocationHoursStore')(withStyles(styles)(observer(AirportOperatingHoursAddData)));
